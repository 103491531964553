import { User } from "firebase/auth"
import { UserData, UserSnippet } from "./types"

export function convertTimestampToSerializable(timestamp: any): {
  seconds: number
  nanoseconds: number
} {
  return { seconds: timestamp?.seconds, nanoseconds: timestamp?.nanoseconds }
}

export async function generateUserSnippetFromUser(
  user: User | null,
  status: UserSnippet["status"],
): Promise<UserSnippet | undefined> {
  return user
    ?.getIdTokenResult()
    .then((idTokenResult) => {
      return {
        uid: user.uid,
        status: status,
        admin:
          idTokenResult.claims.admin === "true" ||
          idTokenResult.claims.admin === true
            ? true
            : false,
        userData: {
          email: user.email,
          emailVerified: user.emailVerified,
          displayName: user.displayName,
          creationTime: user.metadata.creationTime,
          lastSignInTime: user.metadata.lastSignInTime,
        } as UserData,
      } as UserSnippet
    })
    .catch((error) => {
      console.error(error)
      throw new Error("Can't get user claims")
    })
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

// Has a possibility around 3/1000 to generate the same ID
export function createUniqueID(): string {
  return (
    (new Date().getTime() + Math.floor(Math.random() * 1000) / 1000) *
    1000
  ).toString(36)
}

function getFormattedNumber(number: number): string {
  return number < 10 ? `0${number}` : `${number}`
}

function formatDate(date: Date) {
  const suffixes = ["th", "st", "nd", "rd"]
  const day = date.getDate()
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()
  let suffix = suffixes[0]

  if (day % 100 < 11 || day % 100 > 13) {
    switch (day % 10) {
      case 1:
        suffix = suffixes[1]
        break
      case 2:
        suffix = suffixes[2]
        break
      case 3:
        suffix = suffixes[3]
        break
    }
  }

  return `${day}${suffix} of ${month} ${year}`
}

export function getFormattedDate(
  date: Date,
  time: boolean = true,
  showDate: boolean = true,
  longDate: boolean = false,
): string {
  return `${
    time
      ? `${getFormattedNumber(
          date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
        )}.${getFormattedNumber(date.getMinutes())}${
          date.getHours() > 12 ? " pm" : " am"
        }`
      : ""
  }${showDate && time ? ", " : ""}${
    showDate
      ? longDate
        ? formatDate(date)
        : `${getFormattedNumber(date.getDate())}.${getFormattedNumber(
            date.getMonth() + 1,
          )}.${date.getFullYear()}`
      : ""
  }`
}

export function getFormattedFileSize(size: number): string {
  if (size < 1024) {
    return `${size} B`
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(1)} KB`
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(1)} MB`
  } else {
    return `${(size / 1024 / 1024 / 1024).toFixed(1)} GB`
  }
}

export function createHashtagArray(text: string): string[] {
  return text
    .split(" ")
    .filter((word) => word.startsWith("#"))
    .map((word) => word.toLowerCase())
}

export function getMonthNameShort(date: Date) {
  return date.toLocaleString("default", { month: "short" }).toUpperCase()
}

export function structurePlayerName(
  firstName: string,
  lastName: string,
): string {
  return `${firstName[0]?.toUpperCase()} ${lastName}`
}

export function overSafeBall(ballInput: number): string {
  const ball = Math.round(ballInput * 10) / 10

  if (Number((ball - Math.floor(ball)).toFixed(1)) === 0.6) {
    return (Math.floor(ball) + 1).toFixed(1)
  }

  if (Number((ball - Math.floor(ball)).toFixed(1)) > 0.6) {
    return (Math.floor(ball) + 0.6 - (1 - (ball - Math.floor(ball)))).toFixed(1)
  }

  return ball.toFixed(1)
}

export function formatTimerTime(time: string) {
  const now = new Date().getTime()
  const distance = new Date(time).getTime() - now

  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return [
    `${minutes < 0 ? "00" : minutes < 10 ? "0" + minutes.toString() : minutes}`,
    `${seconds < 0 ? "00" : seconds < 10 ? "0" + seconds.toString() : seconds}`,
  ]
}

const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, "0")
// Get timezone offset in ISO format (+hh:mm or -hh:mm)
const getTimezoneOffset = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset()
  const diff = tzOffset >= 0 ? "+" : "-"
  return diff + pad(tzOffset / 60) + ":" + pad(tzOffset % 60)
}

export const toISOStringWithTimezone = (date: Date) => {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    getTimezoneOffset(date)
  )
}
