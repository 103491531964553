import { httpsCallable } from "firebase/functions"
import { deleteObject, ref, uploadBytes } from "firebase/storage"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore"

import { convertTimestampToSerializable } from "../../../app/functions"
import { db, functions, storage } from "../../../app/firebase"
import { toaster } from "../../../app/toaster"
import { News } from "../../../app/types"

import { firebaseSlice } from "../firebaseSlice"

export const extendedNewsSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query<News, void>({
      async queryFn(): Promise<any> {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, "news-prod"), orderBy("time", "desc")),
          )

          const news: News = []
          let errorFound: { found: boolean; message: string } = {
            found: false,
            message: "",
          }

          querySnapshot.forEach((doc) => {
            const data = doc.data()

            if (data.active === undefined) {
              errorFound = {
                found: true,
                message: `News active is missing in document ${doc.id}`,
              }
            }

            if (!data.title) {
              errorFound = {
                found: true,
                message: `News title is missing in document ${doc.id}`,
              }
            }

            if (!data.featureText) {
              errorFound = {
                found: true,
                message: `News featureText is missing in document ${doc.id}`,
              }
            }

            if (!data.image) {
              errorFound = {
                found: true,
                message: `News Image is missing in document ${doc.id}`,
              }
            }

            if (!data.articleMarkdown) {
              errorFound = {
                found: true,
                message: `News articleMarkdown is missing in document ${doc.id}`,
              }
            }

            if (!data.author) {
              errorFound = {
                found: true,
                message: `News author is missing in document ${doc.id}`,
              }
            }

            if (!data.time) {
              errorFound = {
                found: true,
                message: `News time is missing in document ${doc.id}`,
              }
            }

            if (!data.matchType) {
              errorFound = {
                found: true,
                message: `News matchType is missing in document ${doc.id}`,
              }
            }

            // TODO: Add a check for the matchType to be valid
            if (
              data.matchType !== "none" &&
              data.matchType !== "cricket" &&
              data.matchType !== "rugby" &&
              data.matchType !== "football" &&
              data.matchType !== "hockey" &&
              data.matchType !== "tennis" &&
              data.matchType !== "rowing" &&
              data.matchType !== "sailing" &&
              data.matchType !== "basketball" &&
              data.matchType !== "badminton" &&
              data.matchType !== "waterpolo"
            ) {
              errorFound = {
                found: true,
                message: `News matchType is invalid in document ${doc.id}`,
              }
            }

            news.push({
              id: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as News[0])
          })

          if (errorFound.found) {
            toaster.error("Firestore Error", errorFound.message)
            return { error: errorFound.message }
          } else {
            return { data: news }
          }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "News" as const, id: id })),
              "News",
            ]
          : ["News"],

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            query(collection(db, "news-prod"), orderBy("time", "desc")),
            (querySnapshot) => {
              const news: News = []

              let errorFound: { found: boolean; message: string } = {
                found: false,
                message: "",
              }

              querySnapshot.forEach((doc) => {
                const data = doc.data()

                if (data.active === undefined) {
                  errorFound = {
                    found: true,
                    message: `News active is missing in document ${doc.id}`,
                  }
                }

                if (!data.title) {
                  errorFound = {
                    found: true,
                    message: `News title is missing in document ${doc.id}`,
                  }
                }

                if (!data.featureText) {
                  errorFound = {
                    found: true,
                    message: `News featureText is missing in document ${doc.id}`,
                  }
                }

                if (!data.image && !data.pending) {
                  errorFound = {
                    found: true,
                    message: `News Image is missing in document ${doc.id}`,
                  }
                }

                if (!data.articleMarkdown && !data.pending) {
                  errorFound = {
                    found: true,
                    message: `News articleMarkdown is missing in document ${doc.id}`,
                  }
                }

                if (!data.author) {
                  errorFound = {
                    found: true,
                    message: `News author is missing in document ${doc.id}`,
                  }
                }

                if (!data.matchType) {
                  errorFound = {
                    found: true,
                    message: `News matchType is missing in document ${doc.id}`,
                  }
                }

                // TODO: Add a check for the matchType to be valid
                if (
                  data.matchType !== "none" &&
                  data.matchType !== "cricket" &&
                  data.matchType !== "rugby" &&
                  data.matchType !== "football" &&
                  data.matchType !== "hockey" &&
                  data.matchType !== "tennis" &&
                  data.matchType !== "rowing" &&
                  data.matchType !== "sailing" &&
                  data.matchType !== "basketball" &&
                  data.matchType !== "badminton" &&
                  data.matchType !== "waterpolo"
                ) {
                  errorFound = {
                    found: true,
                    message: `News matchType is invalid in document ${doc.id}`,
                  }
                }

                news.push({
                  id: doc.id,
                  ...data,
                  time: convertTimestampToSerializable(data.time),
                } as News[0])
              })

              if (errorFound.found) {
                toaster.error("Firestore Error", errorFound.message)
              } else {
                updateCachedData((draft) => {
                  Object.assign(draft, news)
                })
              }
            },
          )
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to get news")
        }

        await cacheEntryRemoved
      },
    }),

    addNews: builder.mutation({
      async queryFn({
        active,
        title,
        featureText,
        image,
        articleMarkdown,
        author,
        hashTags,
        matchType,
      }) {
        try {
          if (active === undefined) {
            toaster.error("Check all fields", "News active is missing")
            return { error: "News active is missing" }
          }

          if (!title) {
            toaster.error("Check all fields", "News title is missing")
            return { error: "News title is missing" }
          }

          if (!featureText) {
            toaster.error("Check all fields", "News featureText is missing")
            return { error: "News featureText is missing" }
          }

          if (image === null) {
            toaster.error("Check all fields", "News image is missing")
            return { error: "News image is missing" }
          }

          if (!articleMarkdown) {
            toaster.error("Check all fields", "News articleMarkdown is missing")
            return { error: "News articleMarkdown is missing" }
          }

          if (!author) {
            toaster.error("Check all fields", "News author is missing")
            return { error: "News author is missing" }
          }

          if (!matchType) {
            toaster.error("Check all fields", "News matchType is missing")
            return { error: "News matchType is missing" }
          }

          // TODO: Add a check for the matchType to be valid
          if (
            matchType !== "none" &&
            matchType !== "cricket" &&
            matchType !== "rugby" &&
            matchType !== "football" &&
            matchType !== "hockey" &&
            matchType !== "tennis" &&
            matchType !== "rowing" &&
            matchType !== "sailing" &&
            matchType !== "basketball" &&
            matchType !== "badminton" &&
            matchType !== "waterpolo"
          ) {
            toaster.error("Check all fields", "News matchType is invalid")
            return { error: "News matchType is invalid" }
          }

          const docRef = await addDoc(collection(db, "news-prod"), {
            active,
            title,
            featureText,
            author,
            time: serverTimestamp(),
            hashTags,
            matchType,
            pending: true,
          })

          const id = docRef.id

          const storageRef = ref(storage, `news-prod/${id}/${id}.png`)

          await uploadBytes(storageRef, image).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to upload news image",
            )
            throw new Error(error)
          })

          const markdownRef = ref(storage, `news-prod/${id}/${id}.md`)

          await uploadBytes(
            markdownRef,
            new Blob([articleMarkdown], {
              type: "text/markdown; charset=UTF-8",
            }),
          ).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to upload news markdown",
            )
            throw new Error(error)
          })

          const updateDocWithStorageObjects = httpsCallable(
            functions,
            "updateDocWithStorageObjects",
          )

          await updateDocWithStorageObjects({
            collection: "news-prod",
            doc: id,
            fields: [
              {
                fieldName: "image",
                storagePath: `news-prod/${id}/${id}`,
                image: true,
                imageSize: { width: 1200, height: 1200 },
              },
              {
                fieldName: "articleMarkdown",
                storagePath: `news-prod/${id}/${id}.md`,
                image: false,
              },
            ],
          }).catch((error) => {
            toaster.error(
              "Firebase Function Error",
              "Failed to call function [updateDocWithStorageObjects]",
            )
            throw new Error(error)
          })

          toaster.success(
            "News Added",
            `News ${title} had been added successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add news")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["News"],
    }),

    editNews: builder.mutation({
      async queryFn({
        id,
        active,
        title,
        featureText,
        image,
        articleMarkdown,
        hashTags,
        matchType,
      }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "News id is missing")
            return { error: "News id is missing" }
          }

          const updateObject = {}

          if (active !== undefined) {
            Object.assign(updateObject, { active })
          }

          if (title) {
            Object.assign(updateObject, { title })
          }

          if (featureText) {
            Object.assign(updateObject, { featureText })
          }

          if (hashTags) {
            Object.assign(updateObject, { hashTags })
          }

          if (matchType) {
            Object.assign(updateObject, { matchType })
          }

          const fieldArray = []

          if (image) {
            const storageRef = ref(storage, `news-prod/${id}/${id}.png`)
            await uploadBytes(storageRef, image).catch((error) => {
              toaster.error(
                "Firebase Storage Error",
                "Failed to upload news image",
              )
              throw new Error(error)
            })

            fieldArray.push({
              fieldName: "image",
              storagePath: `news-prod/${id}/${id}`,
              image: true,
              imageSize: { width: 1200, height: 800 },
            })
          }

          if (articleMarkdown) {
            const markdownRef = ref(storage, `news-prod/${id}/${id}.md`)
            await uploadBytes(
              markdownRef,
              new Blob([articleMarkdown], {
                type: "text/markdown; charset=UTF-8",
              }),
            ).catch((error) => {
              toaster.error(
                "Firebase Storage Error",
                "Failed to upload news markdown",
              )
              throw new Error(error)
            })

            fieldArray.push({
              fieldName: "articleMarkdown",
              storagePath: `news-prod/${id}/${id}.md`,
              image: false,
            })
          }

          if (Object.keys(updateObject).length > 0) {
            await updateDoc(doc(db, "news-prod", id), updateObject).catch(
              (error) => {
                toaster.error("Firestore Error", "Failed to update news")
                throw new Error(error)
              },
            )
          }

          if (fieldArray.length > 0) {
            const updateDocWithStorageObjects = httpsCallable(
              functions,
              "updateDocWithStorageObjects",
            )

            await updateDocWithStorageObjects({
              collection: "news-prod",
              doc: id,
              fields: fieldArray,
            }).catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [addDocWithImage]",
              )
              throw new Error(error)
            })
          }

          if (fieldArray.length + Object.keys(updateObject).length > 0) {
            toaster.success(
              "News Updated",
              `News ${id} had been updated successfully`,
            )
          }

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update news")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),

    deleteNews: builder.mutation({
      async queryFn({ id }) {
        try {
          const errorFound: { found: boolean; message: string } = {
            found: false,
            message: "",
          }

          await deleteDoc(doc(db, "news-prod", id)).catch((error) => {
            toaster.error("Firestore Error", "Failed to delete news")
            errorFound.found = true
            errorFound.message = error.message
            throw new Error(error)
          })

          if (errorFound.found) {
            return { error: errorFound.message }
          }

          const storageRef = ref(storage, `news-prod/${id}/${id}.webp`)

          await deleteObject(storageRef).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to delete news image",
            )
            throw new Error(error)
          })

          const markdownRef = ref(storage, `news-prod/${id}/${id}.md`)

          await deleteObject(markdownRef).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to delete news markdown",
            )
            throw new Error(error)
          })

          toaster.success(
            "News Deleted",
            `News ${id} had been deleted successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to delete news")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["News"],
    }),
  }),
})

export const {
  useGetNewsQuery,
  useAddNewsMutation,
  useEditNewsMutation,
  useDeleteNewsMutation,
} = extendedNewsSlice

export const selectNews = extendedNewsSlice.endpoints.getNews.select()
