import "./BlurContainer.css"

export default function BlurContainer({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="blur-container">
      <div className="blur-sphere-container">
        <div className="blur-sphere blur-sphere-1" />
        <div className="blur-sphere blur-sphere-2" />
        <div className="blur-sphere blur-sphere-3" />
        <div className="blur-sphere blur-sphere-4" />
        <div className="blur-sphere blur-sphere-5" />
      </div>

      <div className="blur-child-container">{children}</div>
    </div>
  )
}
