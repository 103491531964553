import { ReactNode } from "react"

import "./NewItemPageContainer.css"

export default function NewItemPageContainer({
  title,
  className,
  children,
}: {
  title: string
  className?: string
  children: ReactNode
}) {
  return (
    <div className={`card-bg new-item-page ${className}`}>
      <h3>{title}</h3>

      <div className="form">{children}</div>
    </div>
  )
}
