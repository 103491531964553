import { useNavigate, useParams } from "react-router-dom"
import { useGetMatchesQuery } from "../../../features/firebase/subSlices/matches/matchesSlice"
import { useEffect, useState } from "react"
import RugbyMatchDashboard from "./Rugby/RugbyMatchDashboard"

export default function MatchDashboard() {
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()

  const [matchType, setMatchType] = useState<string | null>(null)

  useEffect(() => {
    if (params.matchId) {
      setMatchType(
        matches.data?.find((match: any) => match.id === params.matchId)
          ?.matchType || null,
      )
    } else {
      navigate("/matches")
    }
  }, [params.matchId, matches.data, navigate])

  return matchType === "rugby" ? (
    <RugbyMatchDashboard />
  ) : (
    <div className="loader"></div>
  )
}
