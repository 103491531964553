import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { useAppDispatch } from "../../../app/hooks"
import { toaster } from "../../../app/toaster"
import { MatchTypes } from "../../../app/types"
import { createHashtagArray } from "../../../app/functions"

import { changeHeader } from "../../../features/client/pageHeader"
import { useAddNewsMutation } from "../../../features/firebase/subSlices/newsSlice"
import { selectUser } from "../../../features/userSlice"

import "./NewNews.css"

import DropDown from "../../../components/DropDown/DropDown"
import InputField from "../../../components/InputField/InputField"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import NewItemPageContainer from "../../../components/NewItemPageContainer/NewItemPageContainer"
import DropArea from "../../../components/DropArea/DropArea"

import Toggle from "../../../components/Toggle/Toggle"

export default function NewNews() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [addNews] = useAddNewsMutation()

  const user = useSelector(selectUser)

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Add News",
      }),
    )
  }, [dispatch])

  const [active, setActive] = useState(true)
  const [title, setTitle] = useState("")
  const [featureText, setFeatureText] = useState("")
  const [hashTags, setHashTags] = useState<string>("")
  const [matchType, setMatchType] = useState<"none" | MatchTypes>("none")

  const [file, setFile] = useState<File | null>(null)

  const [articleMarkdown, setArticleMarkdown] =
    useState(`### Example Markdown   

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum neque sed porttitor lacinia.

---

Example Table Here

|  RC  | STC |
| :---: | :---: |
|    1    |    0    |
|   5    |    2    |

Example List 

- Royal College
- St. Thomas' College
- Isipathana College

1. Lorem ipsum
2. Lorem ipsum 
3. Lorem ipsum

---

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur condimentum neque sed porttitor lacinia. Duis finibus, erat vel euismod laoreet, neque erat viverra mi, sed ultrices nisi tellus non nisl. Duis posuere ligula eget quam aliquet molestie. Curabitur et tristique metus. Morbi interdum consectetur erat eu pellentesque. Etiam aliquet mauris nec velit efficitur, nec tincidunt justo tempor. Proin at odio fermentum, dictum orci vel, venenatis turpis. Curabitur luctus tortor ipsum, pulvinar ullamcorper erat vulputate vel. Nam ante tellus, efficitur sit amet augue tristique, vehicula fermentum magna. Praesent pretium porta eros, ac convallis est fringilla vitae. Vestibulum faucibus facilisis ornare. Nulla ultricies nulla varius, aliquam mauris et, dignissim quam. In at auctor lorem.

Aenean congue nibh in volutpat pulvinar. Phasellus metus tortor, hendrerit a enim quis, sodales rutrum justo. Suspendisse ac risus efficitur, euismod nibh vitae, aliquet mauris. Fusce elementum, odio eu tincidunt consectetur, nibh orci placerat quam, sit amet fringilla enim justo ac ex. Nulla sit amet venenatis neque, nec imperdiet quam. Morbi tincidunt odio sit amet suscipit dignissim. Proin vitae finibus augue. Nam at dolor maximus tellus ultricies ultricies. Sed ac mauris mollis, tincidunt urna eget, dignissim nibh. Mauris eget ultrices quam. Donec faucibus finibus turpis. Donec pretium erat eu sapien convallis, in ultrices nunc rutrum.

Integer volutpat augue urna, quis consectetur mi porta ut. Nullam tristique massa nunc, ac blandit leo suscipit et. Vestibulum egestas malesuada venenatis. Aliquam erat volutpat. In et dui nec quam volutpat faucibus. Quisque commodo sem id ipsum vestibulum mattis. Praesent vel enim ex. Phasellus mollis dui sagittis turpis fermentum, vel rutrum ante pulvinar. `)

  const [loading, setLoading] = useState(false)

  return (
    <NewItemPageContainer className="add-news-page" title="Add a New News">
      <div className="input-container">
        <div className="title-container">
          <InputField
            title="Title"
            name="title"
            placeholder="Enter Title"
            value={title}
            setValue={setTitle}
          />

          <div className="active-c">
            <div>Active</div>
            <Toggle
              active={active}
              setActive={setActive}
              color="rgba(72, 77, 189, 0.7)"
            />
          </div>
        </div>

        <InputField
          title="Feature Text"
          name="featureText"
          placeholder="Enter Feature Text"
          type="textarea"
          value={featureText}
          setValue={setFeatureText}
          textareaCharLimit={200}
        />

        <div className="spacer" />

        <InputField
          title="Hash Tags"
          name="hashTags"
          placeholder="Enter Hash Tags (Start with #, Separate with Space)"
          value={hashTags}
          setValue={setHashTags}
        />

        <div className="hashtag-c">
          {createHashtagArray(hashTags).map((tag, index) => (
            <div key={index} className="hashtag">
              {tag}
            </div>
          ))}
        </div>

        <div>News Image</div>

        <DropArea value={file} setValue={setFile} />

        <div className="spacer" />

        <div className="match-type-label">Related Match Type</div>

        <DropDown
          values={[
            { label: "None", value: "none" },
            { label: "Cricket", value: "cricket" },
            { label: "Rugby", value: "rugby" },
          ]}
          value={matchType}
          setValue={setMatchType}
        />

        <div className="spacer" />

        <div>Article Markdown</div>

        <div className="markdown-container">
          <InputField
            name="articleMarkdown"
            placeholder="Enter Article Markdown"
            type="textarea"
            value={articleMarkdown}
            setValue={setArticleMarkdown}
            textareaHeight="30rem"
          />

          <div className="markdown-preview card-bg">
            <Markdown remarkPlugins={[remarkGfm]}>{articleMarkdown}</Markdown>
          </div>
        </div>

        <div className="btn-container">
          <DefaultButton
            onClick={() => {
              navigate("/news")
            }}
          >
            <span className="material-symbols-rounded">delete</span>
            <span className="text">Discard</span>
          </DefaultButton>

          <DefaultButton
            color="rgba(72, 77, 189, 0.7)"
            hoverColor="rgba(72, 77, 189, 0.5)"
            onClick={async () => {
              if (!loading) {
                if (title === "") {
                  toaster.error(
                    "Title is missing",
                    "Title is missing. Enter a title and retry.",
                  )
                  return { error: "Title is missing" }
                }

                if (featureText === "") {
                  toaster.error(
                    "Feature Text is missing",
                    "Feature Text is missing. Enter a feature text and retry.",
                  )
                  return { error: "Feature Text is missing" }
                }

                if (file === null) {
                  toaster.error(
                    "Upload an Image",
                    "Image is missing. Upload an image and retry.",
                  )
                  return { error: "Image is missing" }
                }

                if (articleMarkdown === "") {
                  toaster.error(
                    "Article Markdown is missing",
                    "Article Markdown is missing. Enter article markdown and retry.",
                  )
                  return { error: "Article Markdown is missing" }
                }

                setLoading(true)

                await addNews({
                  active,
                  title,
                  featureText,
                  image: file,
                  articleMarkdown,
                  author: user.userData?.displayName || "RCCS",
                  hashTags: createHashtagArray(hashTags),
                  matchType,
                })
                if (title && featureText && file && articleMarkdown)
                  navigate("/news")
              }
            }}
          >
            {loading ? (
              <span className="loader" />
            ) : (
              <>
                <span className="material-symbols-rounded">news</span>
                <span className="text">Add New News</span>
              </>
            )}
          </DefaultButton>
        </div>
      </div>
    </NewItemPageContainer>
  )
}
