import "./PointCounter.css"

export default function PointCounter({
  label,
  value,
  onIncrement,
  onDecrement,
}: {
  label: string
  value: number
  onIncrement: () => void
  onDecrement: () => void
}) {
  return (
    <div className="point-counter">
      <div className="header">{label}</div>

      <button onClick={onIncrement}>+</button>
      <div className="value">{value}</div>
      <button onClick={onDecrement}>-</button>
    </div>
  )
}
