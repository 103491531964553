import "./StatusCard.css"

export default function StatusCard({
  matchStatus,
  homeSchool,
  guestSchool,
  colors,
}: {
  matchStatus: string
  homeSchool: string
  guestSchool: string
  colors: {
    home: string
    guest: string
  }
}) {
  return (
    <div
      className="status-chip"
      style={{
        backgroundColor:
          matchStatus === "upcoming" ||
          matchStatus === "stumps" ||
          matchStatus === "draw"
            ? "#000"
            : matchStatus === "live"
            ? "#ff0000"
            : matchStatus === "homeWon"
            ? "#" + colors.home
            : "#" + colors.guest,
      }}
    >
      {matchStatus === "upcoming" ? (
        "UPCOMING"
      ) : matchStatus === "stumps" ? (
        "STUMPS"
      ) : matchStatus === "draw" ? (
        "DRAW"
      ) : matchStatus === "live" ? (
        <div className="live-c">
          <span className="live-dot">●</span> LIVE
        </div>
      ) : matchStatus === "homeWon" ? (
        `${homeSchool.toUpperCase()} WON`
      ) : matchStatus === "guestWon" ? (
        `${guestSchool.toUpperCase()} WON`
      ) : (
        ""
      )}
    </div>
  )
}
