import { httpsCallable } from "firebase/functions"
import { deleteObject, ref, uploadBytes } from "firebase/storage"
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore"

import { db, functions, storage } from "../../../app/firebase"
import { toaster } from "../../../app/toaster"
import { Schools } from "../../../app/types"

import { firebaseSlice } from "../firebaseSlice"

export const extendedSchoolsSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query<Schools, void>({
      async queryFn(): Promise<any> {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, "schools"), orderBy("name")),
          )

          const schools: Schools = []
          let errorFound: { found: boolean; message: string } = {
            found: false,
            message: "",
          }

          querySnapshot.forEach((doc) => {
            const data = doc.data()

            if (!data.name) {
              errorFound = {
                found: true,
                message: `School name is missing in document ${doc.id}`,
              }
            }

            if (!data.city) {
              errorFound = {
                found: true,
                message: `School city is missing in document ${doc.id}`,
              }
            }

            if (!data.color) {
              errorFound = {
                found: true,
                message: `School color is missing in document ${doc.id}`,
              }
            } else if (parseInt(data.color, 16) < 0) {
              errorFound = {
                found: true,
                message: `School color is invalid in document ${doc.id}`,
              }
            } else if (parseInt(data.color, 16) > 16777215) {
              errorFound = {
                found: true,
                message: `School color is invalid in document ${doc.id}`,
              }
            }

            if (!data.image && !data.pending) {
              errorFound = {
                found: true,
                message: `School crest is missing in document ${doc.id}`,
              }
            }

            schools.push({
              id: doc.id,
              ...data,
            } as Schools[0])
          })

          if (errorFound.found) {
            toaster.error("Firestore Error", errorFound.message)
            return { error: errorFound.message }
          } else {
            return { data: schools }
          }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Schools" as const, id: id })),
              "Schools",
            ]
          : ["Schools"],

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            query(collection(db, "schools"), orderBy("name")),
            (querySnapshot) => {
              const schools: Schools = []
              let errorFound: { found: boolean; message: string } = {
                found: false,
                message: "",
              }

              querySnapshot.forEach((doc) => {
                const data = doc.data()

                if (!data.name) {
                  errorFound = {
                    found: true,
                    message: `School name is missing in document ${doc.id}`,
                  }
                }

                if (!data.city) {
                  errorFound = {
                    found: true,
                    message: `School city is missing in document ${doc.id}`,
                  }
                }

                if (!data.color) {
                  errorFound = {
                    found: true,
                    message: `School color is missing in document ${doc.id}`,
                  }
                } else if (parseInt(data.color, 16) < 0) {
                  errorFound = {
                    found: true,
                    message: `School color is invalid in document ${doc.id}`,
                  }
                } else if (parseInt(data.color, 16) > 16777215) {
                  errorFound = {
                    found: true,
                    message: `School color is invalid in document ${doc.id}`,
                  }
                }

                if (!data.image && !data.pending) {
                  errorFound = {
                    found: true,
                    message: `School crest is missing in document ${doc.id}`,
                  }
                }

                schools.push({
                  id: doc.id,
                  ...data,
                } as Schools[0])
              })

              if (errorFound.found) {
                toaster.error("Firestore Error", errorFound.message)
              } else {
                updateCachedData((draft) => {
                  Object.assign(draft, schools)
                })
              }
            },
          )
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to get schools")
        }

        await cacheEntryRemoved
      },
    }),

    addSchool: builder.mutation({
      async queryFn({ id, name, city, color, image }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "School ID is missing")
            return { error: "School ID is missing" }
          }

          if (!name) {
            toaster.error("Check all fields", "School name is missing")
            return { error: "School name is missing" }
          }

          if (!city) {
            toaster.error("Check all fields", "School city is missing")
            return { error: "School city is missing" }
          }

          if (!color) {
            toaster.error("Check all fields", "School color is missing")
            return { error: "School color is missing" }
          } else if (parseInt(color, 16) < 0) {
            toaster.error("Check all fields", "School color is invalid")
            return { error: "School color is invalid" }
          } else if (parseInt(color, 16) > 16777215) {
            toaster.error("Check all fields", "School color is invalid")
            return { error: "School color is invalid" }
          }

          if (image === null) {
            toaster.error(
              "Upload a School Crest",
              "School crest is missing. Upload a school crest and retry.",
            )
            return { error: "School crest is missing" }
          }

          await setDoc(doc(db, "schools", id), {
            name,
            city,
            color,
            pending: true,
          })

          const storageRef = ref(storage, `schools/${id}.png`)

          await uploadBytes(storageRef, image).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to upload school crest",
            )
            throw new Error(error)
          })

          const updateDocWithStorageObjects = httpsCallable(
            functions,
            "updateDocWithStorageObjects",
          )

          await updateDocWithStorageObjects({
            collection: "schools",
            doc: id,
            fields: [
              {
                fieldName: "image",
                storagePath: `schools/${id}`,
                image: true,
                imageSize: { width: 256, height: 256 },
              },
            ],
          }).catch((error) => {
            toaster.error(
              "Firebase Function Error",
              "Failed to call function [updateDocWithStorageObjects]",
            )
            throw new Error(error)
          })

          toaster.success("School added", `School ${name} added successfully`)

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add school")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["Schools"],
    }),

    editSchool: builder.mutation({
      async queryFn({ id, name, city, color, image }) {
        try {
          if (!id) {
            toaster.error("Check all fields on update", "School ID is missing")
            return { error: "School ID is missing" }
          }

          if (color) {
            if (parseInt(color, 16) < 0) {
              toaster.error("Check all fields", "School color is invalid")
              return { error: "School color is invalid" }
            } else if (parseInt(color, 16) > 16777215) {
              toaster.error("Check all fields", "School color is invalid")
              return { error: "School color is invalid" }
            }
          }

          const updateObject = {}

          if (name) {
            Object.assign(updateObject, { name })
          }

          if (city) {
            Object.assign(updateObject, { city })
          }

          if (color) {
            Object.assign(updateObject, { color })
          }

          if (image) {
            const storageRef = ref(storage, `schools/${id}.png`)
            await uploadBytes(storageRef, image).catch((error) => {
              toaster.error(
                "Firebase Storage Error",
                "Failed to upload school crest",
              )
              throw new Error(error)
            })
            const updateDocWithStorageObjects = httpsCallable(
              functions,
              "updateDocWithStorageObjects",
            )
            await updateDocWithStorageObjects({
              collection: "schools",
              doc: id,
              fields: [
                {
                  fieldName: "image",
                  storagePath: `schools/${id}`,
                  image: true,
                  imageSize: { width: 256, height: 256 },
                },
              ],
            }).catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [addDocWithImage]",
              )
              throw new Error(error)
            })
          } else {
            await updateDoc(doc(db, "schools", id), updateObject).catch(
              (error) => {
                toaster.error("Firestore Error", "Failed to update school data")
                throw new Error(error)
              },
            )
          }

          toaster.success(
            "School Updated",
            `School ${id} had been updated successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add school")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Schools", id: arg.id },
      ],
    }),

    deleteSchool: builder.mutation({
      async queryFn({ id }) {
        try {
          await deleteDoc(doc(db, "schools", id)).catch((error) => {
            toaster.error("Firestore Error", "Failed to delete school")
            throw new Error(error)
          })

          const storageRef = ref(storage, `schools/${id}.webp`)

          await deleteObject(storageRef).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to delete school crest",
            )
            throw new Error(error)
          })

          toaster.success(
            "School Deleted",
            `School with id ${id} had been deleted successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to delete school")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["Schools"],
    }),
  }),
})

export const {
  useGetSchoolsQuery,
  useAddSchoolMutation,
  useEditSchoolMutation,
  useDeleteSchoolMutation,
} = extendedSchoolsSlice

export const selectSchools = extendedSchoolsSlice.endpoints.getSchools.select()
