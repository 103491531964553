import { useRef, useState } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"

import { ToastListType } from "../../app/types"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { removeToast } from "../../features/client/toastSlice"

import "./Toast.css"

function ToastItem({ toast, ...rest }: { toast: ToastListType }) {
  const dispatch = useAppDispatch()

  const nodeRef = useRef(null)

  const [expanded, setExpanded] = useState(toast.type === "long")

  return (
    <CSSTransition
      {...rest}
      nodeRef={nodeRef}
      key={toast.id + "css"}
      timeout={300}
      classNames="toast-item"
    >
      <div className={`toast ${!expanded && "expandable"}`} ref={nodeRef}>
        <div className="heading">
          <div>
            <span
              className="material-symbols-rounded"
              style={{
                color:
                  toast.type === "default"
                    ? "#fff"
                    : toast.type === "success"
                    ? "#4caf50"
                    : toast.type === "error"
                    ? "#f44336"
                    : toast.type === "warning"
                    ? "#ff9800"
                    : "",
              }}
            >
              {toast.type === "default"
                ? ""
                : toast.type === "success"
                ? "check_circle"
                : toast.type === "error"
                ? "error"
                : toast.type === "warning"
                ? "warning"
                : toast.type === "wait"
                ? "hourglass_empty"
                : ""}
            </span>
            {toast.toast.title}
          </div>
          <div>
            {toast.type !== "long" && (
              <button
                className="expand-btn"
                onClick={() => {
                  setExpanded((preVal) => !preVal)
                }}
              >
                <span className="material-symbols-rounded">
                  {expanded ? "expand_less" : "expand_more"}
                </span>
              </button>
            )}
            <button
              className="close-btn"
              onClick={() => dispatch(removeToast(toast.id))}
            >
              <span className="material-symbols-rounded">close</span>
            </button>
          </div>
        </div>
        <p>{toast.toast.message}</p>
      </div>
    </CSSTransition>
  )
}

export default function Toast() {
  const toastList = useAppSelector((state) => state.toast)

  return (
    <TransitionGroup className="toast-container">
      {toastList.toastList.map((toast) => {
        return <ToastItem key={toast.id} toast={toast} />
      })}
    </TransitionGroup>
  )
}
