import { CSSProperties, ReactNode } from "react"

import "./ColorButton.css"

export default function ColorButton({
  children,
  color,
  hoverColor,
  disabled = false,
  onClick,
}: {
  children: ReactNode
  color?: string
  hoverColor?: string
  disabled?: boolean
  onClick: () => void
}) {
  return (
    <button
      onClick={() => {
        onClick()
      }}
      className="color-button"
      style={
        {
          "--color": color,
          "--hover-color": hoverColor,
          filter: disabled ? "grayscale(100%)" : "none",
          opacity: disabled ? 0.3 : 1,
        } as CSSProperties
      }
      disabled={disabled}
    >
      {children}
    </button>
  )
}
