import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { ToastListType } from "../../app/types"

const initialState: {
  toastList: ToastListType[]
} = {
  toastList: [],
}

export const toastSlice = createSlice({
  name: "toastList",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastListType>) => {
      if (state.toastList.length > 3) {
        state.toastList.shift()
      }
      state.toastList.push(action.payload)
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toastList = state.toastList.filter(
        (toast) => toast.id !== action.payload,
      )
    },
  },
})

export const { showToast, removeToast } = toastSlice.actions

export default toastSlice.reducer
