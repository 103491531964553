import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getFormattedDate } from "../../app/functions"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { logout, selectUser } from "../../features/userSlice"

import "./PageHeader.css"

import profilePhoto from "../../assets/RCCS_PP.png"

import DefaultButton from "../Buttons/DefaultButton/DefaultButton"
import MenuButton from "../Buttons/MenuButton/MenuButton"

export default function PageHeader() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const pageHeaderButtonFunctions = {
    redirect: (path: string) => {
      navigate(path)
    },
  }

  const headerData = useAppSelector((state) => state.pageHeader)

  const user = useSelector(selectUser)

  const [expanded, setExpanded] = useState(false)

  return (
    <div className="page-header">
      {headerData.backButton && (
        <button
          className="material-symbols-rounded back-btn-page-header"
          onClick={() => {
            navigate(-1)
          }}
        >
          arrow_back_ios_new
        </button>
      )}

      <h2>{headerData.title}</h2>

      <div className="spacer-div" />

      {headerData.buttons?.map((button, index) => (
        <DefaultButton
          key={index}
          onClick={() => {
            if (button.functionName) {
              pageHeaderButtonFunctions[
                button.functionName as keyof typeof pageHeaderButtonFunctions
              ](button.data)
            }
          }}
        >
          {button.icon && (
            <span className="material-symbols-rounded">{button.icon}</span>
          )}

          <span className="btn-title">{button.title}</span>
        </DefaultButton>
      ))}

      <div>
        <div
          className="user-profile-card card-bg"
          onClick={() => setExpanded((preValue) => !preValue)}
        >
          <img src={profilePhoto} alt="" />
          <span className="hello-msg">
            Hello,{" "}
            {user.userData?.displayName &&
              user.userData?.displayName.length > 0 &&
              user.userData?.displayName.split(" ")[0]}
          </span>
          <span className="material-symbols-rounded">
            {expanded ? "expand_less" : "expand_more"}
          </span>
        </div>

        <div className="user-chip-container">
          <div
            className="card-bg user-data-card"
            onMouseLeave={() => setExpanded(false)}
            style={{
              maxHeight: expanded ? "10rem" : "0rem",
              opacity: expanded ? 1 : 0,
            }}
          >
            <div className="user-data-name-role">
              <span className="name">{user.userData?.displayName}</span>
              <span className="role">
                {user.admin ? "Administrator" : "Editor"}
              </span>
            </div>
            <span className="email">{user.userData?.email}</span>

            <DefaultButton
              onClick={() => {
                dispatch(logout())
                navigate("/")
                navigate(0)
              }}
            >
              <span className="material-symbols-rounded">logout</span>
              <span className="log-out-text">Logout</span>
            </DefaultButton>
            <span className="last-sign">
              Last Sign in at{" "}
              {user.userData &&
                getFormattedDate(new Date(user.userData.lastSignInTime))}
            </span>
          </div>
        </div>
      </div>

      <MenuButton />
    </div>
  )
}
