import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

import { useAppDispatch } from "../../app/hooks"
import { toaster } from "../../app/toaster"

import { changeHeader } from "../../features/client/pageHeader"
import { useAddSchoolMutation } from "../../features/firebase/subSlices/schoolsSlice"

import "./NewSchool.css"

import InputField from "../../components/InputField/InputField"
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton"
import NewItemPageContainer from "../../components/NewItemPageContainer/NewItemPageContainer"
import DropArea from "../../components/DropArea/DropArea"

export default function NewSchool() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [addSchool] = useAddSchoolMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "New School",
      }),
    )
  }, [dispatch])

  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [city, setCity] = useState("")
  const [color, setColor] = useState("#000000")

  const [file, setFile] = useState<File | null>(null)

  const [loading, setLoading] = useState(false)

  return (
    <NewItemPageContainer className="add-school-page" title="Add a New School">
      <div className="input-container">
        <InputField
          title="ID"
          name="id"
          placeholder="Enter ID"
          value={id}
          setValue={setId}
        />

        <InputField
          title="Name"
          name="name"
          placeholder="Enter Name"
          value={name}
          setValue={setName}
        />

        <InputField
          title="City"
          name="city"
          placeholder="Enter City"
          value={city}
          setValue={setCity}
        />

        <div>Crest</div>

        <DropArea value={file} setValue={setFile} />
      </div>

      <div className="btn-container">
        <InputField
          title="School Color"
          name="color"
          type="color"
          value={color}
          setValue={setColor}
        />

        <DefaultButton
          onClick={() => {
            navigate("/dashboard")
          }}
        >
          <span className="material-symbols-rounded">delete</span>
          <span className="text">Discard</span>
        </DefaultButton>

        <DefaultButton
          color="rgba(14, 67, 131, 0.7)"
          hoverColor="rgba(14, 67, 131, 0.5)"
          onClick={async () => {
            if (!loading) {
              if (!id) {
                toaster.error("Check all fields", "School ID is missing")
                return { error: "School ID is missing" }
              }

              if (!name) {
                toaster.error("Check all fields", "School name is missing")
                return { error: "School name is missing" }
              }

              if (!city) {
                toaster.error("Check all fields", "School city is missing")
                return { error: "School city is missing" }
              }

              if (!color) {
                toaster.error("Check all fields", "School color is missing")
                return { error: "School color is missing" }
              } else if (parseInt(color, 16) < 0) {
                toaster.error("Check all fields", "School color is invalid")
                return { error: "School color is invalid" }
              } else if (parseInt(color, 16) > 16777215) {
                toaster.error("Check all fields", "School color is invalid")
                return { error: "School color is invalid" }
              }

              if (file === null) {
                toaster.error(
                  "Upload a School Crest",
                  "School crest is missing. Upload a school crest and retry.",
                )
                return { error: "School crest is missing" }
              }

              setLoading(true)

              await addSchool({
                id,
                name,
                city,
                image: file,
                color: color.replaceAll("#", ""),
              })

              if (file && name && city && color && id) navigate("/dashboard")
            }
          }}
        >
          {loading ? (
            <span className="loader" />
          ) : (
            <>
              <span className="material-symbols-rounded">sports_kabaddi</span>
              <span className="text">Add New School</span>
            </>
          )}
        </DefaultButton>
      </div>
    </NewItemPageContainer>
  )
}
