import { store } from "./store"

import { removeToast, showToast } from "../features/client/toastSlice"
import { createUniqueID } from "./functions"
import { ToastTypes } from "./types"

function createToast(title: string, message: string, type: ToastTypes) {
  const id = createUniqueID()
  store.dispatch(showToast({ type: type, id: id, toast: { title, message } }))
  setTimeout(() => {
    if (store.getState().toast.toastList.some((toast) => toast.id === id)) {
      store.dispatch(removeToast(id))
    }
  }, 8000)
}

export const toaster = {
  default: (title: string, message: string) =>
    createToast(title, message, "default"),

  success: (title: string, message: string) =>
    createToast(title, message, "success"),

  error: (title: string, message: string) =>
    createToast(title, message, "error"),

  warning: (title: string, message: string) =>
    createToast(title, message, "warning"),

  long: (title: string, message: string) => createToast(title, message, "long"),

  // promise: (toast: ToastContent["promise"]) => {
  //   store.dispatch(showToast({ type: "promise", toast }))
  // },

  wait: (title: string, message: string) => createToast(title, message, "wait"),
}
