import { Dispatch, SetStateAction, useRef, useState } from "react"

import { getFormattedFileSize } from "../../app/functions"

import "./DropArea.css"

export default function DropArea({
  value,
  setValue,
}: {
  value: File | null
  setValue: Dispatch<SetStateAction<File | null>>
}) {
  const labelRef = useRef<HTMLLabelElement>(null)

  const [dragged, setDragged] = useState(false)

  return (
    <div
      className={`drop-area card-bg ${dragged ? "dragged" : ""}`}
      onClick={() => {
        labelRef.current?.click()
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()

        setDragged(true)
      }}
      onDragOver={(e) => {
        e.preventDefault()
        e.stopPropagation()

        setDragged(true)
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        e.stopPropagation()

        setDragged(false)
      }}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()

        setDragged(false)

        const file = e.dataTransfer.files[0]

        setValue(file)
      }}
    >
      {value ? (
        <>
          <div className="material-symbols-rounded">download_done</div>

          <div className="text-container">
            <h4>Uploaded 1 File</h4>
            <p>
              {value?.name} ({getFormattedFileSize(value?.size || 0)})
            </p>
          </div>

          <div
            className="bg"
            style={{
              backgroundImage: `url(${URL.createObjectURL(value)})`,
            }}
          />
        </>
      ) : (
        <>
          <div className="material-symbols-rounded">publish</div>

          <div className="text-container">
            <h4>Upload from your Device</h4>
            <p>Or Drag and Drop here</p>
          </div>
          <label ref={labelRef} htmlFor="file" />
          <input
            id="file"
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files) {
                setValue(e.target.files[0])
              }
            }}
          />
        </>
      )}
    </div>
  )
}
