import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore"

import { db } from "../../../../../app/firebase"
import { toaster } from "../../../../../app/toaster"
import { MatchInnings, MatchPlayers } from "../../../../../app/types"

import { firebaseSlice } from "../../../firebaseSlice"
import { overSafeBall } from "../../../../../app/functions"

export const extendedCricketMatchInningsSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInnings: builder.query<MatchInnings, string>({
      queryFn: async (args) => {
        try {
          const matchId = args

          const querySnapshotHome = await getDocs(
            query(
              collection(db, "matches-prod", matchId, "innings"),
              orderBy("index"),
            ),
          )

          const innings: MatchInnings = []

          querySnapshotHome.forEach((doc) => {
            const data = doc.data()

            if (!data.name) {
              toaster.error("Firestore Error", "Innings name is missing")
              return { error: `Innings name is missing document ${doc.id}` }
            }

            if (data.index === undefined) {
              toaster.error("Firestore Error", "Innings index is missing")
              return { error: `Innings index is missing document ${doc.id}` }
            }

            if (data.homeBatting === undefined) {
              toaster.error("Firestore Error", "Innings homeBatting is missing")
              return {
                error: `Innings homeBatting is missing document ${doc.id}`,
              }
            }

            if (data.homeScores === undefined) {
              toaster.error("Firestore Error", "Innings homeScores is missing")
              return {
                error: `Innings homeScores is missing document ${doc.id}`,
              }
            } else {
              if (data.homeScores.runs === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings homeScores runs is missing",
                )
                return {
                  error: `Innings homeScores runs is missing document ${doc.id}`,
                }
              }
              if (data.homeScores.wickets === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings homeScores wickets is missing",
                )
                return {
                  error: `Innings homeScores wickets is missing document ${doc.id}`,
                }
              }
              if (data.homeScores.overs === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings homeScores overs is missing",
                )
                return {
                  error: `Innings homeScores overs is missing document ${doc.id}`,
                }
              }
              if (data.homeScores.extras === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings homeScores extras is missing",
                )
                return {
                  error: `Innings homeScores extras is missing document ${doc.id}`,
                }
              } else {
                if (data.homeScores.extras.noBalls === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores extras noBalls is missing",
                  )
                  return {
                    error: `Innings homeScores extras noBalls is missing document ${doc.id}`,
                  }
                }
                if (data.homeScores.extras.wide === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores extras wide is missing",
                  )
                  return {
                    error: `Innings homeScores extras wide is missing document ${doc.id}`,
                  }
                }
                if (data.homeScores.extras.legBye === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores extras legBye is missing",
                  )
                  return {
                    error: `Innings homeScores extras legBye is missing document ${doc.id}`,
                  }
                }
                if (data.homeScores.extras.bye === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores extras bye is missing",
                  )
                  return {
                    error: `Innings homeScores extras bye is missing document ${doc.id}`,
                  }
                }

                if (data.homeScores.activeBatsman === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores activeBatsman is missing",
                  )
                  return {
                    error: `Innings homeScores activeBatsman is missing document ${doc.id}`,
                  }
                } else {
                  if (data.homeScores.activeBatsman.striker === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores activeBatsman striker is missing",
                    )
                    return {
                      error: `Innings homeScores activeBatsman striker is missing document ${doc.id}`,
                    }
                  }
                  if (data.homeScores.activeBatsman.nonStriker === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores activeBatsman nonStriker is missing",
                    )
                    return {
                      error: `Innings homeScores activeBatsman nonStriker is missing document ${doc.id}`,
                    }
                  }
                }
              }
            }

            if (data.guestScores === undefined) {
              toaster.error("Firestore Error", "Innings guestScores is missing")
              return {
                error: `Innings guestScores is missing document ${doc.id}`,
              }
            } else {
              if (data.guestScores.runs === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings guestScores runs is missing",
                )
                return {
                  error: `Innings guestScores runs is missing document ${doc.id}`,
                }
              }
              if (data.guestScores.wickets === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings guestScores wickets is missing",
                )
                return {
                  error: `Innings guestScores wickets is missing document ${doc.id}`,
                }
              }
              if (data.guestScores.overs === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings guestScores overs is missing",
                )
                return {
                  error: `Innings guestScores overs is missing document ${doc.id}`,
                }
              }
              if (data.guestScores.extras === undefined) {
                toaster.error(
                  "Firestore Error",
                  "Innings guestScores extras is missing",
                )
                return {
                  error: `Innings guestScores extras is missing document ${doc.id}`,
                }
              } else {
                if (data.guestScores.extras.noBalls === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores extras noBalls is missing",
                  )
                  return {
                    error: `Innings guestScores extras noBalls is missing document ${doc.id}`,
                  }
                }
                if (data.guestScores.extras.wide === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores extras wide is missing",
                  )
                  return {
                    error: `Innings guestScores extras wide is missing document ${doc.id}`,
                  }
                }
                if (data.guestScores.extras.legBye === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores extras legBye is missing",
                  )
                  return {
                    error: `Innings guestScores extras legBye is missing document ${doc.id}`,
                  }
                }
                if (data.guestScores.extras.bye === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores extras bye is missing",
                  )
                  return {
                    error: `Innings guestScores extras bye is missing document ${doc.id}`,
                  }
                }

                if (data.guestScores.activeBatsman === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores activeBatsman is missing",
                  )
                  return {
                    error: `Innings guestScores activeBatsman is missing document ${doc.id}`,
                  }
                } else {
                  if (data.guestScores.activeBatsman.striker === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores activeBatsman striker is missing",
                    )
                    return {
                      error: `Innings guestScores activeBatsman striker is missing document ${doc.id}`,
                    }
                  }
                  if (data.guestScores.activeBatsman.nonStriker === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores activeBatsman nonStriker is missing",
                    )
                    return {
                      error: `Innings guestScores activeBatsman nonStriker is missing document ${doc.id}`,
                    }
                  }
                }
              }
            }

            innings.push({
              id: doc.id,
              ...data,
            } as MatchInnings[0])
          })

          return { data: innings }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "MatchInnings" as const,
                id: arg + id,
              })),
              "MatchInnings",
            ]
          : ["MatchInnings"],

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            query(
              collection(db, "matches-prod", arg, "innings"),
              orderBy("index"),
            ),
            (querySnapshot) => {
              const innings: MatchInnings = []

              querySnapshot.forEach((doc) => {
                const data = doc.data()
                if (!data.name) {
                  toaster.error("Firestore Error", "Innings name is missing")
                  return { error: `Innings name is missing document ${doc.id}` }
                }

                if (data.index === undefined) {
                  toaster.error("Firestore Error", "Innings index is missing")
                  return {
                    error: `Innings index is missing document ${doc.id}`,
                  }
                }

                if (data.homeBatting === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeBatting is missing",
                  )
                  return {
                    error: `Innings homeBatting is missing document ${doc.id}`,
                  }
                }

                if (data.homeScores === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings homeScores is missing",
                  )
                  return {
                    error: `Innings homeScores is missing document ${doc.id}`,
                  }
                } else {
                  if (data.homeScores.runs === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores runs is missing",
                    )
                    return {
                      error: `Innings homeScores runs is missing document ${doc.id}`,
                    }
                  }
                  if (data.homeScores.wickets === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores wickets is missing",
                    )
                    return {
                      error: `Innings homeScores wickets is missing document ${doc.id}`,
                    }
                  }
                  if (data.homeScores.overs === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores overs is missing",
                    )
                    return {
                      error: `Innings homeScores overs is missing document ${doc.id}`,
                    }
                  }
                  if (data.homeScores.extras === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings homeScores extras is missing",
                    )
                    return {
                      error: `Innings homeScores extras is missing document ${doc.id}`,
                    }
                  } else {
                    if (data.homeScores.extras.noBalls === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings homeScores extras noBalls is missing",
                      )
                      return {
                        error: `Innings homeScores extras noBalls is missing document ${doc.id}`,
                      }
                    }
                    if (data.homeScores.extras.wide === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings homeScores extras wide is missing",
                      )
                      return {
                        error: `Innings homeScores extras wide is missing document ${doc.id}`,
                      }
                    }
                    if (data.homeScores.extras.legBye === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings homeScores extras legBye is missing",
                      )
                      return {
                        error: `Innings homeScores extras legBye is missing document ${doc.id}`,
                      }
                    }
                    if (data.homeScores.extras.bye === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings homeScores extras bye is missing",
                      )
                      return {
                        error: `Innings homeScores extras bye is missing document ${doc.id}`,
                      }
                    }

                    if (data.homeScores.activeBatsman === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings homeScores activeBatsman is missing",
                      )
                      return {
                        error: `Innings homeScores activeBatsman is missing document ${doc.id}`,
                      }
                    } else {
                      if (data.homeScores.activeBatsman.striker === undefined) {
                        toaster.error(
                          "Firestore Error",
                          "Innings homeScores activeBatsman striker is missing",
                        )
                        return {
                          error: `Innings homeScores activeBatsman striker is missing document ${doc.id}`,
                        }
                      }
                      if (
                        data.homeScores.activeBatsman.nonStriker === undefined
                      ) {
                        toaster.error(
                          "Firestore Error",
                          "Innings homeScores activeBatsman nonStriker is missing",
                        )
                        return {
                          error: `Innings homeScores activeBatsman nonStriker is missing document ${doc.id}`,
                        }
                      }
                    }
                  }
                }

                if (data.guestScores === undefined) {
                  toaster.error(
                    "Firestore Error",
                    "Innings guestScores is missing",
                  )
                  return {
                    error: `Innings guestScores is missing document ${doc.id}`,
                  }
                } else {
                  if (data.guestScores.runs === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores runs is missing",
                    )
                    return {
                      error: `Innings guestScores runs is missing document ${doc.id}`,
                    }
                  }
                  if (data.guestScores.wickets === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores wickets is missing",
                    )
                    return {
                      error: `Innings guestScores wickets is missing document ${doc.id}`,
                    }
                  }
                  if (data.guestScores.overs === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores overs is missing",
                    )
                    return {
                      error: `Innings guestScores overs is missing document ${doc.id}`,
                    }
                  }
                  if (data.guestScores.extras === undefined) {
                    toaster.error(
                      "Firestore Error",
                      "Innings guestScores extras is missing",
                    )
                    return {
                      error: `Innings guestScores extras is missing document ${doc.id}`,
                    }
                  } else {
                    if (data.guestScores.extras.noBalls === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings guestScores extras noBalls is missing",
                      )
                      return {
                        error: `Innings guestScores extras noBalls is missing document ${doc.id}`,
                      }
                    }
                    if (data.guestScores.extras.wide === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings guestScores extras wide is missing",
                      )
                      return {
                        error: `Innings guestScores extras wide is missing document ${doc.id}`,
                      }
                    }
                    if (data.guestScores.extras.legBye === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings guestScores extras legBye is missing",
                      )
                      return {
                        error: `Innings guestScores extras legBye is missing document ${doc.id}`,
                      }
                    }
                    if (data.guestScores.extras.bye === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings guestScores extras bye is missing",
                      )
                      return {
                        error: `Innings guestScores extras bye is missing document ${doc.id}`,
                      }
                    }

                    if (data.guestScores.activeBatsman === undefined) {
                      toaster.error(
                        "Firestore Error",
                        "Innings guestScores activeBatsman is missing",
                      )
                      return {
                        error: `Innings guestScores activeBatsman is missing document ${doc.id}`,
                      }
                    } else {
                      if (
                        data.guestScores.activeBatsman.striker === undefined
                      ) {
                        toaster.error(
                          "Firestore Error",
                          "Innings guestScores activeBatsman striker is missing",
                        )
                        return {
                          error: `Innings guestScores activeBatsman striker is missing document ${doc.id}`,
                        }
                      }
                      if (
                        data.guestScores.activeBatsman.nonStriker === undefined
                      ) {
                        toaster.error(
                          "Firestore Error",
                          "Innings guestScores activeBatsman nonStriker is missing",
                        )
                        return {
                          error: `Innings guestScores activeBatsman nonStriker is missing document ${doc.id}`,
                        }
                      }
                    }
                  }
                }

                innings.push({
                  id: doc.id,
                  ...data,
                } as MatchInnings[0])
              })

              updateCachedData((draft) => {
                Object.assign(draft, innings)
              })
            },
          )
        } catch (error: any) {
          toaster.error(
            "Firestore Error",
            "Failed to listen to innings collection",
          )
        }

        await cacheEntryRemoved
      },
    }),

    addInnings: builder.mutation({
      async queryFn({ matchId, name, index, homeBatting, players }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!name) {
            toaster.error("Check all fields", "Innings name is missing")
            return { error: "Innings name is missing" }
          }

          if (index === undefined) {
            toaster.error("Check all fields", "Innings index is missing")
            return { error: "Innings index is missing" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          if (players === undefined || players === null) {
            toaster.error("Check all fields", "Players are missing")
            return { error: "Players are missing" }
          } else if (!players.home || players.home?.length === 0) {
            toaster.error("Check all fields", "Home players are missing")
            return { error: "Home players are missing" }
          } else if (!players.guest || players.guest?.length === 0) {
            toaster.error("Check all fields", "Guest players are missing")
            return { error: "Guest players are missing" }
          }

          const docRef = await addDoc(
            collection(db, "matches-prod", matchId, "innings"),
            {
              name,
              index,
              homeBatting,
              homeScores: {
                runs: 0,
                wickets: 0,
                overs: 0,
                extras: {
                  noBalls: 0,
                  wide: 0,
                  legBye: 0,
                  bye: 0,
                },
                activeBatsman: {
                  striker: "",
                  nonStriker: "",
                },
              },
              guestScores: {
                runs: 0,
                wickets: 0,
                overs: 0,
                extras: {
                  noBalls: 0,
                  wide: 0,
                  legBye: 0,
                  bye: 0,
                },
                activeBatsman: {
                  striker: "",
                  nonStriker: "",
                },
              },
              inningsScoresInitialized: false,
            },
          )

          await updateDoc(doc(db, "matches-prod", matchId), {
            currentInnings: docRef.id,
          })

          const batch = writeBatch(db)

          players.home.forEach((player: MatchPlayers["home"][0]) => {
            batch.set(
              doc(
                db,
                "matches-prod",
                matchId,
                "innings",
                docRef.id,
                "homeBattingScores",
                player.id,
              ),
              {
                runs: 0,
                balls: 0,
                status: "Yet to Bat",
                time: serverTimestamp(),
              },
            )

            batch.set(
              doc(
                db,
                "matches-prod",
                matchId,
                "innings",
                docRef.id,
                "homeBowlingScores",
                player.id,
              ),
              {
                runs: 0,
                wickets: 0,
                overs: 0,
                time: serverTimestamp(),
              },
            )
          })

          players.guest.forEach((player: MatchPlayers["guest"][0]) => {
            batch.set(
              doc(
                db,
                "matches-prod",
                matchId,
                "innings",
                docRef.id,
                "guestBattingScores",
                player.id,
              ),
              {
                runs: 0,
                balls: 0,
                status: "Yet to Bat",
                time: serverTimestamp(),
              },
            )

            batch.set(
              doc(
                db,
                "matches-prod",
                matchId,
                "innings",
                docRef.id,
                "guestBowlingScores",
                player.id,
              ),
              {
                runs: 0,
                wickets: 0,
                overs: 0,
                time: serverTimestamp(),
              },
            )
          })

          await batch.commit()

          toaster.success("Innings Added", `Innings ${name} had been added`)

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add innings")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["MatchInnings"],
    }),

    changeInningsBatting: builder.mutation({
      async queryFn({ matchId, inningsId, homeBatting }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!inningsId) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          await updateDoc(
            doc(db, "matches-prod", matchId, "innings", inningsId),
            {
              homeBatting,
            },
          )

          toaster.success(
            "Innings Updated",
            `Innings ${inningsId} batting had been updated`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update innings")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "MatchInnings", id: arg.matchId + arg.inningsId },
      ],
    }),

    changeActiveBatsman: builder.mutation({
      async queryFn({ matchId, inningsId, homeBatting, isStriker, playerId }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!inningsId) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          if (isStriker === undefined || isStriker === null) {
            toaster.error("Check all fields", "Is striker is missing")
            return { error: "Is striker is missing" }
          }

          if (!playerId) {
            toaster.error("Check all fields", "Player id is missing")
            return { error: "Player id is missing" }
          }

          await updateDoc(
            doc(db, "matches-prod", matchId, "innings", inningsId),
            {
              [`${homeBatting ? "homeScores" : "guestScores"}.activeBatsman.${
                isStriker ? "striker" : "nonStriker"
              }`]: playerId,
            },
          )

          toaster.success(
            `${isStriker ? "Striker" : "Non Striker"} Updated`,
            `Innings ${inningsId} active batsman had been updated`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update innings")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "MatchInnings", id: arg.matchId + arg.inningsId },
      ],
    }),

    switchBatsman: builder.mutation({
      async queryFn({ matchId, inningsId }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!inningsId) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          // Switch

          const docRef = doc(db, "matches-prod", matchId, "innings", inningsId)

          await runTransaction(db, async (transaction) => {
            const docRes = await transaction.get(docRef)

            if (!docRes.exists()) {
              toaster.error("Firestore Error", "Innings does not exist")
              return { error: "Innings does not exist" }
            }

            const data = docRes.data()

            const playerData =
              data[`${data.homeBatting ? "homeScores" : "guestScores"}`]

            transaction.update(docRef, {
              [`${
                data.homeBatting ? "homeScores" : "guestScores"
              }.activeBatsman`]: {
                striker: playerData.activeBatsman.nonStriker,
                nonStriker: playerData.activeBatsman.striker,
              },
            })
          })

          // toaster.success(
          //   "Batsman Switched",
          //   `Innings ${inningsId} active batsman had been updated`,
          // )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update innings")
          console.error(error)
          return { error: error.message }
        }
      },

      invalidatesTags: (result, error, arg) => [
        { type: "MatchInnings", id: arg.matchId + arg.inningsId },
      ],
    }),

    updateScores: builder.mutation({
      async queryFn({ matchId, inningsId, homeBatting, scores, extras }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!inningsId) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          if (!scores) {
            toaster.error("Check all fields", "Scores are missing")
            return { error: "Scores are missing" }
          } else {
            if (scores.runs === undefined) {
              toaster.error("Check all fields", "Scores runs are missing")
              return { error: "Scores runs are missing" }
            }
            if (scores.wickets === undefined) {
              toaster.error("Check all fields", "Scores wickets are missing")
              return { error: "Scores wickets are missing" }
            }
            if (scores.overs === undefined) {
              toaster.error("Check all fields", "Scores overs are missing")
              return { error: "Scores overs are missing" }
            }
          }

          if (!extras) {
            toaster.error("Check all fields", "Extras are missing")
            return { error: "Extras are missing" }
          } else {
            if (extras.noBalls === undefined) {
              toaster.error("Check all fields", "Extras noBalls are missing")
              return { error: "Extras noBalls are missing" }
            }
            if (extras.wide === undefined) {
              toaster.error("Check all fields", "Extras wide are missing")
              return { error: "Extras wide are missing" }
            }
            if (extras.legBye === undefined) {
              toaster.error("Check all fields", "Extras legBye are missing")
              return { error: "Extras legBye are missing" }
            }
            if (extras.bye === undefined) {
              toaster.error("Check all fields", "Extras bye are missing")
              return { error: "Extras bye are missing" }
            }
          }

          const docRef = doc(db, "matches-prod", matchId, "innings", inningsId)

          await runTransaction(db, async (transaction) => {
            const docRes = await transaction.get(docRef)

            if (!docRes.exists()) {
              toaster.error("Firestore Error", "Innings does not exist")
              return { error: "Innings does not exist" }
            }

            const data = docRes.data()

            const playerData =
              data[`${homeBatting ? "homeScores" : "guestScores"}`]

            transaction.update(docRef, {
              [`${homeBatting ? "homeScores" : "guestScores"}`]: {
                ...playerData,
                runs: playerData.runs + scores.runs,
                wickets: playerData.wickets + scores.wickets,
                overs: Number(overSafeBall(playerData.overs + scores.overs)),

                extras: {
                  noBalls: playerData.extras.noBalls + extras.noBalls,
                  wide: playerData.extras.wide + extras.wide,
                  legBye: playerData.extras.legBye + extras.legBye,
                  bye: playerData.extras.bye + extras.bye,
                },
              },
            })
          })

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update innings")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "MatchInnings", id: arg.matchId + arg.inningsId },
      ],
    }),
  }),
})

export const {
  useGetInningsQuery,
  useAddInningsMutation,
  useChangeInningsBattingMutation,
  useChangeActiveBatsmanMutation,
  useSwitchBatsmanMutation,
  useUpdateScoresMutation,
} = extendedCricketMatchInningsSlice

export const selectPlayers =
  extendedCricketMatchInningsSlice.endpoints.getInnings.select
