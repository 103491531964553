import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { SidePanel } from "../../app/types"

const initialState: SidePanel = {
  extended: false,
  open: true,
}

export const sidePanelSlice = createSlice({
  name: "sidePanel",
  initialState,
  reducers: {
    setExtended: (state, action: PayloadAction<boolean>) => {
      state.extended = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
  },
})

export const { setExtended, setOpen } = sidePanelSlice.actions

export default sidePanelSlice.reducer
