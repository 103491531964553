import { httpsCallable } from "firebase/functions"

import { functions } from "../../../app/firebase"
import { UserList } from "../../../app/types"
import { toaster } from "../../../app/toaster"
import { store } from "../../../app/store"

import { firebaseSlice } from "../firebaseSlice"

import {
  addToLoadingAdminStateChangeUIDs,
  addToLoadingStateChangeUIDs,
  changeDisableAllLoadingState,
  removeFromLoadingAdminStateChangeUIDs,
  removeFromLoadingStateChangeUIDs,
} from "../../client/userListLoad"

export const extendedUserListSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query<UserList, void>({
      async queryFn(): Promise<any> {
        try {
          const listUsers = httpsCallable(functions, "listUsers")

          const list: UserList = await listUsers()
            .then((result) => {
              const data: any = result.data

              return { admin: data.admin, editors: data.editors }
            })
            .catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [listUsers]",
              )
              throw new Error(error)
            })

          return {
            data: list,
          }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: ["UserList"],
    }),

    updateUserList: builder.mutation({
      async queryFn() {
        return { data: null }
      },
      invalidatesTags: ["UserList"],
    }),

    disableEditor: builder.mutation({
      async queryFn({ uid, state }) {
        try {
          store.dispatch(addToLoadingStateChangeUIDs(uid))

          const editorDisableState = httpsCallable(
            functions,
            "editorDisableState",
          )

          await editorDisableState({
            uid: uid,
            state: state,
          })
            .then(() => {
              store.dispatch(removeFromLoadingStateChangeUIDs(uid))

              // if (state) {
              //   toaster.success(
              //     "Editor Disabled",
              //     "User with editor will not be able to access the admin panel until they get access again.",
              //   )
              // } else {
              //   toaster.success(
              //     "Editor Enabled",
              //     "User with editor will be able to access the admin panel.",
              //   )
              // }
            })
            .catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [editorDisableState]",
              )
              console.error("Error: ", error)
              console.error("Can't disable user: ", uid)

              throw new Error(error)
            })

          return { data: null }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["UserList"],
    }),

    disableAllEditors: builder.mutation({
      async queryFn() {
        try {
          store.dispatch(changeDisableAllLoadingState(true))

          const disableAllEditorsState = httpsCallable(
            functions,
            "disableAllEditors",
          )

          await disableAllEditorsState()
            .then(() => {
              store.dispatch(changeDisableAllLoadingState(false))

              toaster.success(
                "Disabled all the editors",
                "No user with editor will not be able to access the admin panel until they get access again.",
              )
            })
            .catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [disableAllEditors]",
              )
              console.error("Error: ", error)
              console.error("Can't disable all the editors: ")

              throw new Error(error)
            })

          return { data: null }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["UserList"],
    }),

    changeEditorToAdmin: builder.mutation({
      async queryFn({ uid }) {
        try {
          store.dispatch(addToLoadingAdminStateChangeUIDs(uid))

          const changeEditorToAdmin = httpsCallable(
            functions,
            "changeEditorToAdmin",
          )

          await changeEditorToAdmin({ uid: uid })
            .then(() => {
              store.dispatch(removeFromLoadingAdminStateChangeUIDs(uid))

              toaster.success(
                "Editor changed to admin",
                "User will now be able to access the admin panel with admin privileges.",
              )
            })
            .catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [changeEditorToAdmin]",
              )
              console.error("Error: ", error)
              console.error("Can't change user: ", uid, " to admin")

              throw new Error(error)
            })

          return { data: null }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["UserList"],
    }),

    newUser: builder.mutation({
      async queryFn({ name, email, password }) {
        try {
          const newUser = httpsCallable(functions, "newUser")

          await newUser({ name: name, email: email, password: password })
            .then(() => {
              toaster.success(
                "New user created",
                "A new user with editor role has been created.",
              )
            })
            .catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [newUser]",
              )
              console.error("Error: ", error)
              console.error("Can't create new user")

              throw new Error(error)
            })

          return { data: null }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["UserList"],
    }),
  }),
})

export const {
  useGetUserListQuery,
  useUpdateUserListMutation,
  useDisableEditorMutation,
  useDisableAllEditorsMutation,
  useChangeEditorToAdminMutation,
  useNewUserMutation,
} = extendedUserListSlice

export const selectUserList =
  extendedUserListSlice.endpoints.getUserList.select()
