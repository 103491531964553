import { useEffect, useState } from "react"
import { User, sendEmailVerification } from "firebase/auth"

import { useAppDispatch } from "../../app/hooks"
import { toaster } from "../../app/toaster"
import { auth } from "../../app/firebase"

import { changeHeader } from "../../features/client/pageHeader"

import "./VerifyEmail.css"

export default function VerifyEmail() {
  const dispatch = useAppDispatch()

  const [state, setState] = useState<"none" | "sent" | "loading" | "error">(
    "none",
  )

  useEffect(() => {
    dispatch(changeHeader({ title: "", backButton: false }))
  }, [dispatch])

  return (
    <div className="verify-page">
      <div>
        <h1>Verify Your Email</h1>
        <p>
          Access the Admin Panel by verifying your email address. If you don't
          see it in your inbox, take a quick peek in your spam folder
        </p>
      </div>

      <button
        className={state === "sent" ? "disabled" : ""}
        style={{
          cursor:
            state === "loading"
              ? "wait"
              : state === "sent"
              ? "not-allowed"
              : "pointer",
        }}
        onClick={async () => {
          if (state !== "loading" && state !== "sent") {
            setState("loading")

            await sendEmailVerification(auth.currentUser as User)
              .then(() => {
                setState("sent")
                toaster.success(
                  "Verification email sent!",
                  "Check your inbox for a verification email",
                )
              })
              .catch((error) => {
                setState("error")
                toaster.error(
                  "Error sending verification email",
                  "Try again. If this error persists, refresh the page and try again.",
                )
                throw new Error(error)
              })
          }
        }}
      >
        {state === "loading" ? (
          <span className="loader" />
        ) : (
          "Send Verification Email"
        )}
      </button>
    </div>
  )
}
