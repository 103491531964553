import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { toaster } from "../../app/toaster"

import { selectUser } from "../../features/userSlice"
import { setExtended, setOpen } from "../../features/client/sidePanelSlice"

import "./Root.css"

import BlurContainer from "../../components/BlurContainer/BlurContainer"
import SidePanel from "../../components/SidePanel/SidePanel"
import PageHeader from "../../components/PageHeader/PageHeader"
import Popup from "../../components/Popup/Popup"

export default function Root() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const user = useSelector(selectUser)

  const sidePanelExtended = useAppSelector((state) => state.sidePanel.extended)
  const openSidePanel = useAppSelector((state) => state.sidePanel.open)

  const [loading, setLoading] = useState(true)
  const [loaderTimer, setLoaderTimer] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaderTimer(true)
    }, 1500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setOpen(false))
    }, 1000)
  }, [dispatch])

  useEffect(() => {
    if (user.uid && loaderTimer) {
      if (user.userData?.emailVerified) {
        dispatch(setExtended(true))
        if (pathname === "/" || pathname === "/verify-email") {
          navigate("/dashboard")
        }
      } else {
        navigate("/verify-email")
      }
    }
  }, [
    loaderTimer,
    user.uid,
    user.userData?.emailVerified,
    pathname,
    navigate,
    dispatch,
  ])

  useEffect(() => {
    if (user.status !== "loading" && loaderTimer) {
      setLoading(false)
    }
    if (user.status !== "loading" && user.status !== "success" && loaderTimer) {
      navigate("/")
    }
  }, [user.status, loaderTimer, navigate])

  useEffect(() => {
    if (user.status === "auth/network-request-failed") {
      toaster.error(
        "Can't connect to Firebase.",
        "Please check your internet connection.",
      )
    }
  }, [user.status])

  return (
    <>
      <Popup />

      <BlurContainer>
        <div className="app-container">
          <div
            className="side-panel-container"
            style={{
              transform: openSidePanel ? "translateX(0%)" : "translateX(-100%)",
              backgroundColor: openSidePanel ? "#060606" : "transparent",
              width: loading
                ? "100%"
                : sidePanelExtended
                ? "var(--extended-width)"
                : "var(--normal-width)",
            }}
          >
            <SidePanel
              extend={sidePanelExtended}
              loading={!(loaderTimer && !loading)}
            />

            <div
              className="gradient-side-p"
              style={{
                opacity: openSidePanel ? 1 : 0,
              }}
            />
          </div>

          <div
            className="outlet-container"
            style={{
              background: sidePanelExtended ? "#14141400" : "#141414cc",
            }}
          >
            <div
              className="outlet-animation-container"
              style={{ opacity: loaderTimer && !loading ? 1 : 0 }}
            >
              {pathname !== "/" && <div className="header-blur" />}

              <div
                className="outlet-padding-container"
                style={{
                  height: pathname === "/" ? "100vh" : "auto",
                  padding:
                    pathname === "/"
                      ? "var(--padding)"
                      : "calc(var(--outlet-padding) + 3.5rem + var(--padding) / 3) var(--outlet-padding) var(--outlet-padding)",
                }}
              >
                {pathname !== "/" && <PageHeader />}
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </BlurContainer>
    </>
  )
}
