import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { toaster } from "../../../../../app/toaster"
import { CricketMatch, MatchInnings, Schools } from "../../../../../app/types"
import { useAppDispatch } from "../../../../../app/hooks"

import { changeHeader } from "../../../../../features/client/pageHeader"
import {
  useAddBallEventMutation,
  useDeleteCommentaryEventMutation,
  useGetEventsQuery,
} from "../../../../../features/firebase/subSlices/matches/cricket/eventSlice"
import {
  useGetScoresQuery,
  useUpdatePlayerDataMutation,
} from "../../../../../features/firebase/subSlices/matches/cricket/scoresSlice"
import {
  useChangeActiveBatsmanMutation,
  useChangeInningsBattingMutation,
  useGetInningsQuery,
  useSwitchBatsmanMutation,
  useUpdateScoresMutation,
} from "../../../../../features/firebase/subSlices/matches/cricket/inningsSlice"
import { useGetSchoolsQuery } from "../../../../../features/firebase/subSlices/schoolsSlice"
import { useGetPlayersQuery } from "../../../../../features/firebase/subSlices/matches/playersSlice"
import { useGetMatchesQuery } from "../../../../../features/firebase/subSlices/matches/matchesSlice"

import "./InningsDashboard.css"

import StatusCard from "../../../../../components/MatchCard/StatusCard/StatusCard"
import InfoChip from "../../../../../components/MatchCard/InfoChip/InfoChip"
import DefaultButton from "../../../../../components/Buttons/DefaultButton/DefaultButton"
import DropDown from "../../../../../components/DropDown/DropDown"
import ColorButton from "../../../../../components/Buttons/ColorButton/ColorButton"
import EventCard from "../../../../../components/EventCard/EventCard"
import { ScoreTableIndividual } from "./ScoreTable/InningsScoreTable"
import { overSafeBall } from "../../../../../app/functions"

export default function InningsDashboard() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()
  const schools = useGetSchoolsQuery()

  const [changeInningsBatting] = useChangeInningsBattingMutation()
  const [addBallEvent] = useAddBallEventMutation()
  const [updatePlayerData] = useUpdatePlayerDataMutation()
  const [changeActiveBatsman] = useChangeActiveBatsmanMutation()
  const [switchBatsman] = useSwitchBatsmanMutation()
  const [updateScores] = useUpdateScoresMutation()
  const [deleteCommentaryEvent] = useDeleteCommentaryEventMutation()

  const matchPlayers =
    params.matchId !== undefined ? useGetPlayersQuery(params.matchId) : null

  const innings =
    params.matchId === undefined ? null : useGetInningsQuery(params.matchId)

  const events =
    params.matchId === undefined ? null : useGetEventsQuery(params.matchId)

  const scores =
    params.matchId === undefined || params.inningsId === undefined
      ? null
      : useGetScoresQuery({
          matchId: params.matchId,
          inningsId: params.inningsId,
        })

  const [match, setMatch] = useState<null | CricketMatch>(null)
  const [inningsData, setInningsData] = useState<null | MatchInnings[0]>(null)

  const [homeSchool, setHomeSchool] = useState<null | Schools[0]>(null)
  const [guestSchool, setGuestSchool] = useState<null | Schools[0]>(null)

  const [changeBattingsLoading, setChangeBattingsLoading] = useState(false)

  const [bowler, setBowler] = useState<string>("")
  const [runValue, setRunValue] = useState<number | null>(null)
  const [ballType, setBallType] = useState<
    "nb" | "wd" | "b" | "lb" | "w" | null
  >(null)
  const [dismissal, setDismissal] = useState<string>("")
  const [fielder, setFielder] = useState<string>("")

  const [striker, setStriker] = useState<string>("")
  const [nonStriker, setNonStriker] = useState<string>("")

  const [changeBatsmanLoading, setChangeBatsmanLoading] = useState(false)

  const [tableSchoolHome, setTableSchoolHome] = useState(true)
  const [tableBatting, setTableBatting] = useState(true)

  const [strikerName, setStrikerName] = useState("")
  const [bowlerName, setBowlerName] = useState("")
  const [fielderName, setFielderName] = useState("")

  const [ballLoading, setBallLoading] = useState(false)
  const [strikerChangeLoading, setStrikerChangeLoading] = useState(false)

  const [selectedBallInEvents, setSelectedBallInEvents] = useState<{
    action: "edit" | "delete" | "comment" | "add"
    id: string
  } | null>(null)

  useEffect(() => {
    if (selectedBallInEvents !== null) {
      const data =
        events?.data?.find(
          (event: any) => event.id === selectedBallInEvents.id,
        ) || null

      if (selectedBallInEvents.action === "delete") {
        // Deduct scores from innings of the school

        if (data && "runs" in data && inningsData) {
          const runs = data.runs
          const homeBatting = data.homeBatting
          const innings = data.innings

          updateScores({
            matchId: params.matchId,
            inningsId: innings,
            homeBatting: homeBatting,
            scores: {
              runs:
                -1 *
                (runs +
                  (data.relatedDataChanges.extras?.noBalls || 0) +
                  (data.relatedDataChanges.extras?.wide || 0)),
              wickets: data.relatedDataChanges.wickets ? -1 : 0,
              overs:
                inningsData[
                  inningsData?.homeBatting ? "homeScores" : "guestScores"
                ].overs === data.ball &&
                !data.relatedDataChanges.extras?.noBalls &&
                !data.relatedDataChanges.extras?.wide
                  ? -0.1
                  : 0,
            },
            extras: {
              noBalls: -1 * (data.relatedDataChanges.extras?.noBalls || 0),
              wide: -1 * (data.relatedDataChanges.extras?.wide || 0),
              legBye: -1 * (data.relatedDataChanges.extras?.legBye || 0),
              bye: -1 * (data.relatedDataChanges.extras?.bye || 0),
            },
          })
        }

        // Deduct scores from player data

        if (data && "relatedDataChanges" in data) {
          const batting = data.relatedDataChanges.batting
          const bowling = data.relatedDataChanges.bowling

          if (batting) {
            updatePlayerData({
              matchId: params.matchId,
              inningsId: data.innings,
              playerId: batting.playerId,
              playerSchool: data.homeBatting ? "home" : "guest",
              homeBatting: data.homeBatting,
              incrementData: {
                runs:
                  data.relatedDataChanges.extras?.bye ||
                  data.relatedDataChanges.extras?.legBye ||
                  data.relatedDataChanges.extras?.wide
                    ? 0
                    : -1 * data.runs,
                balls:
                  data.relatedDataChanges.extras?.wide ||
                  data.relatedDataChanges.extras?.legBye ||
                  data.relatedDataChanges.extras?.bye
                    ? 0
                    : -1,
                status: data.relatedDataChanges.batting.status || "Not Out",
              },
            })
          }

          if (bowling) {
            updatePlayerData({
              matchId: params.matchId,
              inningsId: data.innings,
              playerId: bowling.playerId,
              playerSchool: !data.homeBatting ? "home" : "guest",
              homeBatting: data.homeBatting,
              incrementData: {
                runs:
                  data.relatedDataChanges.extras?.wide ||
                  data.relatedDataChanges.extras?.bye ||
                  data.relatedDataChanges.extras?.legBye
                    ? 0
                    : -1 * data.runs,
                overs:
                  data.relatedDataChanges.extras?.wide ||
                  data.relatedDataChanges.extras?.legBye ||
                  data.relatedDataChanges.extras?.bye
                    ? 0
                    : -0.1,
                wickets:
                  data.relatedDataChanges.wickets &&
                  !data.relatedDataChanges.notBowlerWickets
                    ? -1
                    : 0,
              },
            })
          }
        }

        // Delete ball event

        deleteCommentaryEvent({
          matchId: params.matchId,
          eventId: selectedBallInEvents.id,
        })

        setSelectedBallInEvents(null)
      }

      if (selectedBallInEvents.action === "add") {
        setBowler("")
      }
    }
  }, [
    selectedBallInEvents,
    inningsData,
    events?.data,
    updateScores,
    updatePlayerData,
    deleteCommentaryEvent,
    params.matchId,
  ])

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Innings",
        buttons: [
          {
            title: "Score Table",
            icon: "table_chart",
            functionName: "redirect",
            data: `/matches/${params.matchId}/innings/${params.inningsId}/score-table`,
          },
        ],
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (params.matchId) {
      setMatch(
        matches.data?.find((match: any) => match.id === params.matchId) || null,
      )
    } else {
      navigate("/matches")
    }
  }, [params.matchId, matches.data, navigate])

  useEffect(() => {
    if (innings?.data) {
      setInningsData(
        innings?.data.find((inn: any) => inn.id === params.inningsId) || null,
      )
    }
  }, [innings?.data, params.inningsId])

  useEffect(() => {
    setBowler("")
  }, [inningsData?.homeBatting])

  useEffect(() => {
    if (
      inningsData?.homeScores.activeBatsman &&
      inningsData?.guestScores.activeBatsman
    ) {
      setStriker(
        inningsData?.homeBatting
          ? inningsData?.homeScores.activeBatsman.striker
          : inningsData?.guestScores.activeBatsman.striker,
      )
      setNonStriker(
        inningsData?.homeBatting
          ? inningsData?.homeScores.activeBatsman.nonStriker
          : inningsData?.guestScores.activeBatsman.nonStriker,
      )
    }
  }, [inningsData])

  useEffect(() => {
    if (schools.data) {
      setHomeSchool(
        schools.data.find((school: any) => school.id === match?.homeSchool) ||
          null,
      )
      setGuestSchool(
        schools.data.find((school: any) => school.id === match?.guestSchool) ||
          null,
      )
    }
  }, [schools.data, match?.homeSchool, match?.guestSchool])

  useEffect(() => {
    if (matchPlayers?.data && striker) {
      const player = matchPlayers?.data[
        inningsData?.homeBatting ? "home" : "guest"
      ].find((player: { id: string }) => player.id === striker)

      if (player) {
        setStrikerName(
          player.firstName[0].toUpperCase() + " " + player.lastName,
        )
      } else {
        const player = matchPlayers?.data?.guest.find(
          (player: { id: string }) => player.id === striker,
        )

        if (player) {
          setStrikerName(
            player.firstName[0].toUpperCase() + " " + player.lastName,
          )
        }
      }
    }
  }, [matchPlayers?.data, striker, inningsData])

  useEffect(() => {
    if (matchPlayers?.data && bowler) {
      const player = matchPlayers?.data[
        inningsData?.homeBatting ? "guest" : "home"
      ].find((player) => player.id === bowler)

      if (player) {
        setBowlerName(player.firstName[0].toUpperCase() + " " + player.lastName)
      } else {
        const player = matchPlayers?.data?.home.find(
          (player) => player.id === bowler,
        )

        if (player) {
          setBowlerName(
            player.firstName[0].toUpperCase() + " " + player.lastName,
          )
        }
      }
    }
  }, [matchPlayers?.data, bowler, inningsData])

  useEffect(() => {
    if (matchPlayers?.data && fielder) {
      const player = matchPlayers?.data[
        inningsData?.homeBatting ? "guest" : "home"
      ].find((player) => player.id === fielder)

      if (player) {
        setFielderName(
          player.firstName[0].toUpperCase() + " " + player.lastName,
        )
      }
    }
  }, [matchPlayers?.data, fielder, inningsData])

  function buttonDisableState(): boolean {
    if (ballLoading) {
      return true
    }

    if (runValue !== null && ballType === null) {
      return false
    }

    if (ballType !== null && runValue !== null) {
      return false
    }

    if (ballType === "w" && dismissal !== "") {
      if (
        dismissal === "caught" ||
        dismissal === "stumped" ||
        dismissal === "run-out"
      ) {
        if (fielder === "") {
          return true
        }
      }
      return false
    }

    return true
  }

  return (
    <div className="innings-dashboard-page">
      <div className="top-c card-bg">
        <div className="top-c-c">
          <div>
            <div className="title">
              {match?.title} ({inningsData?.name})
            </div>
            <div className="sub-title">{match?.subText}</div>
          </div>

          <div className="chips">
            <StatusCard
              matchStatus={match?.matchStatus || "upcoming"}
              homeSchool={match?.homeSchool || ""}
              guestSchool={match?.guestSchool || ""}
              colors={{
                home: homeSchool?.color || "",
                guest: guestSchool?.color || "",
              }}
            />

            <InfoChip
              active={match?.infoChip.active || false}
              text={match?.infoChip.text || ""}
              icon={match?.infoChip.icon || ""}
            />
          </div>
        </div>

        <div className="schools-c">
          <div className="school">
            <img src={homeSchool?.image} />

            <div className="data-c">
              <div className="status">
                {inningsData?.homeBatting ? "Batting" : "Bowling"}
              </div>

              <div className="main-data-c">
                <div className="main-data">
                  {String(inningsData?.homeScores.runs)}/
                  {String(inningsData?.homeScores.wickets)}
                </div>

                <div className="minor-data">
                  ({overSafeBall(inningsData?.homeScores.overs || 0)})
                </div>
              </div>
            </div>
          </div>

          <DefaultButton
            onClick={async () => {
              if (!changeBattingsLoading) {
                setChangeBattingsLoading(true)

                await changeInningsBatting({
                  matchId: params.matchId,
                  inningsId: params.inningsId,
                  homeBatting: !inningsData?.homeBatting,
                })

                setChangeBattingsLoading(false)
              }
            }}
          >
            {changeBattingsLoading ? (
              <span className="loader" />
            ) : (
              <div className="material-symbols-rounded">sync_alt</div>
            )}
          </DefaultButton>

          <div className="school guest">
            <div className="data-c">
              <div className="status">
                {inningsData?.homeBatting ? "Bowling" : "Batting"}
              </div>

              <div className="main-data-c">
                <div className="main-data">
                  {String(inningsData?.guestScores.runs)}/
                  {String(inningsData?.guestScores.wickets)}
                </div>

                <div className="minor-data">
                  ({overSafeBall(inningsData?.guestScores.overs || 0)})
                </div>
              </div>
            </div>

            <img src={guestSchool?.image} />
          </div>
        </div>
      </div>

      <div className="btm-c">
        <div className="add-ball-event-card card-bg">
          <div className="ball">
            Ball
            <div
              className="ball-pill"
              style={{
                backgroundColor:
                  selectedBallInEvents?.action === "add"
                    ? "#ff000099"
                    : "#ffffff35",
              }}
            >
              {selectedBallInEvents?.action === "add"
                ? selectedBallInEvents.id
                : inningsData?.homeBatting
                ? overSafeBall(inningsData?.homeScores.overs + 0.1)
                : overSafeBall(
                    inningsData?.guestScores.overs !== undefined
                      ? inningsData?.guestScores.overs + 0.1
                      : 0,
                  )}
            </div>
          </div>

          <div className="dropdown-row">
            <div className="title">Bowler</div>

            <DropDown
              value={bowler}
              setValue={setBowler}
              placeHolder="Select Bowler"
              values={
                inningsData?.homeBatting
                  ? matchPlayers?.data?.guest.map((player) => {
                      return {
                        label:
                          player.number +
                          " | " +
                          player.firstName[0].toUpperCase() +
                          " " +
                          player.lastName +
                          " (Guest)",
                        value: player.id,
                      }
                    }) || []
                  : matchPlayers?.data?.home.map((player) => {
                      return {
                        label:
                          player.number +
                          " | " +
                          player.firstName[0].toUpperCase() +
                          " " +
                          player.lastName +
                          " (Home)",
                        value: player.id,
                      }
                    }) || []
              }
            />
          </div>

          <div className="num-btn-c">
            <DefaultButton
              onClick={() => {
                setRunValue(0)
              }}
              disabled={
                (runValue !== null && runValue !== 0) ||
                ballType === "w" ||
                // ballType === "nb" ||
                // ballType === "wd" ||
                ballType === "b" ||
                ballType === "lb"
              }
            >
              0
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setRunValue(1)
              }}
              disabled={
                (runValue !== null && runValue !== 1) || ballType === "w"
                // || ballType === "nb" ||
                // ballType === "wd" ||
                // ballType === "b" ||
                // ballType === "lb"
              }
            >
              1
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setRunValue(2)
              }}
              disabled={
                (runValue !== null && runValue !== 2) || ballType === "w"
              }
              // || ballType === "nb" ||
              // ballType === "wd" ||
              // ballType === "b" ||
              // ballType === "lb"
            >
              2
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setRunValue(3)
              }}
              disabled={
                (runValue !== null && runValue !== 3) || ballType === "w"
                // || ballType === "nb" ||
                // ballType === "wd" ||
                // ballType === "b" ||
                // ballType === "lb"
              }
            >
              3
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setRunValue(4)
              }}
              disabled={
                (runValue !== null && runValue !== 4) || ballType === "w"
                // || ballType === "nb" ||
                // ballType === "wd" ||
                // ballType === "b" ||
                // ballType === "lb"
              }
            >
              4
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setRunValue(6)
              }}
              disabled={
                (runValue !== null && runValue !== 6) ||
                ballType === "w" ||
                // ballType === "nb" ||
                ballType === "wd" ||
                ballType === "b" ||
                ballType === "lb"
              }
            >
              6
            </DefaultButton>
          </div>

          <div className="ball-type-btn-c">
            <DefaultButton
              onClick={() => {
                setBallType("nb")
              }}
              disabled={
                (ballType === null && runValue !== null) ||
                (ballType !== null && ballType !== "nb")
              }
            >
              No Ball
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setBallType("wd")
              }}
              disabled={
                (ballType === null && runValue !== null) ||
                (ballType !== null && ballType !== "wd")
              }
            >
              Wide
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setBallType("b")
              }}
              disabled={
                (ballType === null && runValue !== null) ||
                (ballType !== null && ballType !== "b")
              }
            >
              Bye
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setBallType("lb")
              }}
              disabled={
                (ballType === null && runValue !== null) ||
                (ballType !== null && ballType !== "lb")
              }
            >
              Leg Bye
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setBallType("w")
              }}
              disabled={
                (ballType === null && runValue !== null) ||
                (ballType !== null && ballType !== "w")
              }
            >
              Wicket
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                setBallType(null)
                setRunValue(null)
                setDismissal("")
                setFielder("")
              }}
            >
              Clear
            </DefaultButton>
          </div>

          <div className="dropdown-row">
            <div
              className="title"
              style={{
                opacity: ballType !== "w" ? 0.3 : 1,
              }}
            >
              Dismissal
            </div>

            <DropDown
              value={dismissal}
              setValue={setDismissal}
              placeHolder="Select Dismissal"
              values={[
                { label: "Bowled", value: "bowled" },
                { label: "Caught", value: "caught" },
                { label: "Caught and Bowled", value: "caught-and-bowled" },
                { label: "Handled the Ball", value: "handled" },
                { label: "Hit Twice", value: "hit-twice" },
                { label: "Hit Wicket", value: "hit-wicket" },
                { label: "Leg Before Wicket (LBW)", value: "lbw" },
                { label: "Obstructing the Field", value: "obstructing" },
                { label: "Retired Out", value: "retired-out" },
                { label: "Run Out", value: "run-out" },
                { label: "Stumped", value: "stumped" },
                { label: "Timed Out", value: "timed-out" },
              ]}
              disabled={ballType !== "w"}
              onChange={() => {
                setFielder("")
              }}
            />
          </div>

          <div className="dropdown-row">
            <div
              className="title"
              style={{
                opacity: ballType !== "w" ? 0.3 : 1,
              }}
            >
              Fielder
            </div>

            <DropDown
              value={fielder}
              setValue={setFielder}
              placeHolder="Select Fielder"
              values={
                inningsData?.homeBatting
                  ? matchPlayers?.data?.guest.map((player) => {
                      return {
                        label:
                          player.firstName[0].toUpperCase() +
                          " " +
                          player.lastName +
                          " (Guest)",
                        value: player.id,
                      }
                    }) || []
                  : matchPlayers?.data?.home.map((player) => {
                      return {
                        label:
                          player.firstName[0].toUpperCase() +
                          " " +
                          player.lastName +
                          " (Home)",
                        value: player.id,
                      }
                    }) || []
              }
              disabled={
                ballType === "w"
                  ? dismissal === ""
                    ? true
                    : dismissal === "bowled" ||
                      dismissal === "lbw" ||
                      dismissal === "caught-and-bowled" ||
                      dismissal === "hit-wicket" ||
                      dismissal === "handled" ||
                      dismissal === "retired-out" ||
                      dismissal === "obstructing" ||
                      dismissal === "timed-out" ||
                      dismissal === "hit-twice"
                  : true
              }
            />
          </div>

          <ColorButton
            disabled={buttonDisableState()}
            onClick={async () => {
              if (!ballLoading) {
                if (bowler === "") {
                  toaster.error("Bowler Missing", "Please select a bowler")
                  return
                }

                if (striker === "") {
                  toaster.error("Striker Missing", "Please select a striker")
                  return
                }

                if (nonStriker === "") {
                  toaster.error(
                    "Non-Striker Missing",
                    "Please select a non-striker",
                  )
                  return
                }

                // console.log({
                //   striker,
                //   nonStriker,
                //   bowler,
                //   runValue,
                //   ballType,
                //   dismissal,
                //   fielder,
                // })

                setBallLoading(true)

                // If just a run

                if (runValue !== null && ballType === null && inningsData) {
                  // Update innings data

                  await updateScores({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    scores: {
                      runs: runValue,
                      wickets: 0,
                      overs: selectedBallInEvents?.action === "add" ? 0 : 0.1,
                    },
                    extras: {
                      noBalls: 0,
                      wide: 0,
                      legBye: 0,
                      bye: 0,
                    },
                  })

                  // Update player data

                  // Non-striker

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: nonStriker,
                    playerSchool: inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      status: "Not Out",
                    },
                  })

                  // Striker

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: striker,
                    playerSchool: inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      runs: runValue,
                      balls: 1,
                      status: "Not Out",
                    },
                  })

                  // Bowler

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: bowler,
                    playerSchool: !inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      runs: runValue,
                      overs: 0.1,
                    },
                  })

                  // Add Ball event

                  await addBallEvent({
                    matchId: params.matchId,
                    innings: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    ball:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData?.homeBatting
                              ? (inningsData?.homeScores.overs || 0) + 0.1
                              : (inningsData?.guestScores.overs || 0) + 0.1,
                          ),

                    runs: runValue,
                    description: `${bowlerName} to ${strikerName}`,
                    relatedDataChanges: {
                      batting: {
                        playerId: striker,
                      },
                      bowling: {
                        playerId: bowler,
                      },
                    },
                    time:
                      selectedBallInEvents?.action === "add"
                        ? (
                            events?.data?.find(
                              (eventsDataLocal) =>
                                "ball" in eventsDataLocal &&
                                eventsDataLocal.ball.toFixed(1) ===
                                  overSafeBall(
                                    parseFloat(selectedBallInEvents?.id) + 0.1,
                                  ),
                            ) as {
                              time: { seconds: number; nanoseconds: number }
                            }
                          )?.time.seconds - 2
                        : null,
                  })
                }

                // If no-ball

                if (runValue !== null && ballType === "nb" && inningsData) {
                  // Update innings Data

                  await updateScores({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    scores: {
                      runs: runValue + 1,
                      wickets: 0,
                      overs: 0,
                    },
                    extras: {
                      noBalls: 1,
                      wide: 0,
                      legBye: 0,
                      bye: 0,
                    },
                  })

                  // Update Player Data

                  // Non-striker

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: nonStriker,
                    playerSchool: inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      status: "Not Out",
                    },
                  })

                  // Striker

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: striker,
                    playerSchool: inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      runs: runValue,
                      balls: 1,
                      status: "Not Out",
                    },
                  })

                  // Bowler

                  await updatePlayerData({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    playerId: bowler,
                    playerSchool: !inningsData.homeBatting ? "home" : "guest",
                    homeBatting: inningsData.homeBatting,
                    incrementData: {
                      runs: runValue,
                      overs: 0.1,
                    },
                  })

                  // Add Ball event

                  await addBallEvent({
                    matchId: params.matchId,
                    innings: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    ball:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData?.homeBatting
                              ? inningsData?.homeScores.overs || 0
                              : inningsData?.guestScores.overs || 0,
                          ),
                    separateID:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData[
                              inningsData.homeBatting
                                ? "homeScores"
                                : "guestScores"
                            ].overs,
                          ) +
                          "-nb-" +
                          new Date().getTime(),
                    runs: runValue,
                    description: `nb ${bowlerName} to ${strikerName}`,
                    relatedDataChanges: {
                      batting: {
                        playerId: striker,
                      },
                      bowling: {
                        playerId: bowler,
                      },
                      extras: {
                        noBalls: 1,
                      },
                    },
                    time:
                      selectedBallInEvents?.action === "add"
                        ? (
                            events?.data?.find(
                              (eventsDataLocal) =>
                                "ball" in eventsDataLocal &&
                                eventsDataLocal.ball.toFixed(1) ===
                                  overSafeBall(
                                    parseFloat(selectedBallInEvents?.id) + 0.1,
                                  ),
                            ) as {
                              time: { seconds: number; nanoseconds: number }
                            }
                          )?.time.seconds - 2
                        : null,
                  })
                }

                // If Wide

                if (runValue !== null && ballType === "wd" && inningsData) {
                  // Update innings Data

                  await updateScores({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    scores: {
                      runs: runValue + 1,
                      wickets: 0,
                      overs: 0,
                    },
                    extras: {
                      noBalls: 0,
                      wide: 1,
                      legBye: 0,
                      bye: 0,
                    },
                  })

                  // Add Ball event

                  await addBallEvent({
                    matchId: params.matchId,
                    innings: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    ball:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData?.homeBatting
                              ? inningsData?.homeScores.overs || 0
                              : inningsData?.guestScores.overs || 0,
                          ),
                    separateID: selectedBallInEvents?.action
                      ? selectedBallInEvents.id
                      : overSafeBall(
                          inningsData[
                            inningsData.homeBatting
                              ? "homeScores"
                              : "guestScores"
                          ].overs,
                        ) +
                        "-wd-" +
                        new Date().getTime(),
                    runs: runValue,
                    description: `wd ${bowlerName} to ${strikerName}`,
                    relatedDataChanges: {
                      batting: {
                        playerId: striker,
                      },
                      bowling: {
                        playerId: bowler,
                      },
                      extras: {
                        wide: 1,
                      },
                    },
                    time:
                      selectedBallInEvents?.action === "add"
                        ? (
                            events?.data?.find(
                              (eventsDataLocal) =>
                                "ball" in eventsDataLocal &&
                                eventsDataLocal.ball.toFixed(1) ===
                                  overSafeBall(
                                    parseFloat(selectedBallInEvents?.id) + 0.1,
                                  ),
                            ) as {
                              time: { seconds: number; nanoseconds: number }
                            }
                          )?.time.seconds - 2
                        : null,
                  })
                }

                // If Bye

                if (runValue !== null && ballType === "b" && inningsData) {
                  // Update innings Data

                  await updateScores({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    scores: {
                      runs: runValue,
                      wickets: 0,
                      overs: selectedBallInEvents?.action === "add" ? 0 : 0.1,
                    },
                    extras: {
                      noBalls: 0,
                      wide: 0,
                      legBye: 0,
                      bye: runValue,
                    },
                  })

                  // Add Ball event

                  await addBallEvent({
                    matchId: params.matchId,
                    innings: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    ball:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData?.homeBatting
                              ? (inningsData?.homeScores.overs || 0) + 0.1
                              : (inningsData?.guestScores.overs || 0) + 0.1,
                          ),

                    runs: runValue,
                    description: `b ${bowlerName} to ${strikerName}`,
                    relatedDataChanges: {
                      batting: {
                        playerId: striker,
                      },
                      bowling: {
                        playerId: bowler,
                      },
                      extras: {
                        bye: runValue,
                      },
                    },

                    time:
                      selectedBallInEvents?.action === "add"
                        ? (
                            events?.data?.find(
                              (eventsDataLocal) =>
                                "ball" in eventsDataLocal &&
                                eventsDataLocal.ball.toFixed(1) ===
                                  overSafeBall(
                                    parseFloat(selectedBallInEvents?.id) + 0.1,
                                  ),
                            ) as {
                              time: { seconds: number; nanoseconds: number }
                            }
                          )?.time.seconds - 2
                        : null,
                  })
                }

                // If Leg Bye

                if (runValue !== null && ballType === "lb" && inningsData) {
                  // Update innings Data

                  await updateScores({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    scores: {
                      runs: runValue,
                      wickets: 0,
                      overs: selectedBallInEvents?.action === "add" ? 0 : 0.1,
                    },
                    extras: {
                      noBalls: 0,
                      wide: 0,
                      legBye: runValue,
                      bye: 0,
                    },
                  })

                  // Add Ball event

                  await addBallEvent({
                    matchId: params.matchId,
                    innings: params.inningsId,
                    homeBatting: inningsData.homeBatting,
                    ball:
                      selectedBallInEvents?.action === "add"
                        ? selectedBallInEvents.id
                        : overSafeBall(
                            inningsData?.homeBatting
                              ? (inningsData?.homeScores.overs || 0) + 0.1
                              : (inningsData?.guestScores.overs || 0) + 0.1,
                          ),

                    runs: runValue,
                    description: `lb ${bowlerName} to ${strikerName}`,
                    relatedDataChanges: {
                      batting: {
                        playerId: striker,
                      },
                      bowling: {
                        playerId: bowler,
                      },
                      extras: {
                        legBye: runValue,
                      },
                    },

                    time:
                      selectedBallInEvents?.action === "add"
                        ? (
                            events?.data?.find(
                              (eventsDataLocal) =>
                                "ball" in eventsDataLocal &&
                                eventsDataLocal.ball.toFixed(1) ===
                                  overSafeBall(
                                    parseFloat(selectedBallInEvents?.id) + 0.1,
                                  ),
                            ) as {
                              time: { seconds: number; nanoseconds: number }
                            }
                          )?.time.seconds - 2
                        : null,
                  })
                }

                // If Wicket

                if (ballType === "w" && inningsData) {
                  if (
                    dismissal === "bowled" ||
                    dismissal === "caught" ||
                    dismissal === "lbw" ||
                    dismissal === "caught-and-bowled" ||
                    dismissal === "handled" ||
                    dismissal === "retired-out" ||
                    dismissal === "timed-out"
                  ) {
                    // Update innings Data

                    await updateScores({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      homeBatting: inningsData.homeBatting,
                      scores: {
                        runs: 0,
                        wickets: 1,
                        overs: selectedBallInEvents?.action === "add" ? 0 : 0.1,
                      },
                      extras: {
                        noBalls: 0,
                        wide: 0,
                        legBye: 0,
                        bye: 0,
                      },
                    })

                    // Update Player Data

                    // Non-striker

                    await updatePlayerData({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      playerId: nonStriker,
                      playerSchool: inningsData.homeBatting ? "home" : "guest",
                      homeBatting: inningsData.homeBatting,
                      incrementData: {
                        status: "Not Out",
                      },
                    })

                    // Striker

                    await updatePlayerData({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      playerId: striker,
                      playerSchool: inningsData.homeBatting ? "home" : "guest",
                      homeBatting: inningsData.homeBatting,
                      incrementData: {
                        runs: 0,
                        balls: 1,
                        status:
                          dismissal === "bowled"
                            ? `b ${bowlerName}`
                            : dismissal === "caught"
                            ? `c ${fielderName} b ${bowlerName}`
                            : dismissal === "lbw"
                            ? `lbw b ${bowlerName}`
                            : dismissal === "caught-and-bowled"
                            ? `c&b ${bowlerName}`
                            : dismissal === "handled"
                            ? `Handled the Ball`
                            : dismissal === "retired-out"
                            ? `Retired Out`
                            : dismissal === "timed-out"
                            ? `Timed out`
                            : "",
                      },
                    })

                    // Bowler

                    await updatePlayerData({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      playerId: bowler,
                      playerSchool: !inningsData.homeBatting ? "home" : "guest",
                      homeBatting: inningsData.homeBatting,
                      incrementData: {
                        wickets:
                          dismissal === "handled" ||
                          dismissal === "retired-out" ||
                          dismissal === "timed-out"
                            ? 0
                            : 1,
                        runs: 0,
                        overs: 0.1,
                      },
                    })

                    // Add Ball event

                    await addBallEvent({
                      matchId: params.matchId,
                      innings: params.inningsId,
                      homeBatting: inningsData.homeBatting,
                      ball:
                        selectedBallInEvents?.action === "add"
                          ? selectedBallInEvents.id
                          : overSafeBall(
                              inningsData?.homeBatting
                                ? (inningsData?.homeScores.overs || 0) + 0.1
                                : (inningsData?.guestScores.overs || 0) + 0.1,
                            ),
                      runs: 0,
                      description:
                        dismissal === "bowled"
                          ? `w b ${bowlerName} to ${strikerName}`
                          : dismissal === "caught"
                          ? `w c ${fielderName} b ${bowlerName} to ${strikerName}`
                          : dismissal === "lbw"
                          ? `w lbw b ${bowlerName} to ${strikerName}`
                          : dismissal === "caught-and-bowled"
                          ? `w c&b ${bowlerName} to ${strikerName}`
                          : dismissal === "handled"
                          ? `w handled the ball, ${strikerName}`
                          : dismissal === "retired-out"
                          ? `w retired out, ${strikerName}`
                          : dismissal === "timed-out"
                          ? `w timed out, ${strikerName}`
                          : "",
                      relatedDataChanges: {
                        batting: {
                          playerId: striker,
                          status: "Not Out",
                        },
                        bowling: {
                          playerId: bowler,
                        },
                        wickets: 1,
                        notBowlerWickets:
                          dismissal === "handled" ||
                          dismissal === "retired-out" ||
                          dismissal === "timed-out",
                      },
                      time:
                        selectedBallInEvents?.action === "add"
                          ? (
                              events?.data?.find(
                                (eventsDataLocal) =>
                                  "ball" in eventsDataLocal &&
                                  eventsDataLocal.ball.toFixed(1) ===
                                    overSafeBall(
                                      parseFloat(selectedBallInEvents?.id) +
                                        0.1,
                                    ),
                              ) as {
                                time: { seconds: number; nanoseconds: number }
                              }
                            )?.time.seconds - 2
                          : null,
                    })
                  }

                  setStriker("")
                }

                setRunValue(null)
                setBallType(null)
                setDismissal("")
                setFielder("")

                if (selectedBallInEvents?.action === "add") {
                  setSelectedBallInEvents(null)
                }

                setBallLoading(false)
              }
            }}
          >
            {ballLoading ? (
              <span className="loader-container">
                <span className="loader"></span>
              </span>
            ) : (
              "Update"
            )}
          </ColorButton>

          {selectedBallInEvents?.action === "add" && (
            <DefaultButton
              onClick={() => {
                setSelectedBallInEvents(null)
              }}
            >
              Discard
            </DefaultButton>
          )}
        </div>

        <div className="btm-c-r">
          <div className="batsman card-bg">
            <div className="batsman-c">
              <h3>Striker</h3>

              <DropDown
                placeHolder="Select Striker"
                value={striker}
                setValue={setStriker}
                onChange={async (value) => {
                  if (value !== null && value !== "") {
                    await changeActiveBatsman({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      homeBatting: inningsData?.homeBatting,
                      isStriker: true,
                      playerId: value,
                    })
                  }

                  if (value === nonStriker) {
                    toaster.warning(
                      "Striker and Non-Striker are same",
                      "Please select different players",
                    )
                  }

                  const batsmanData =
                    scores?.data &&
                    scores?.data[
                      inningsData?.homeBatting ? "home" : "guest"
                    ].batting.find((batsman) => batsman.playerId === value)

                  if (
                    batsmanData?.status !== "Yet to Bat" &&
                    batsmanData?.status !== "Not Out"
                  ) {
                    toaster.warning(
                      "Player already out",
                      "Please select different player",
                    )
                  }
                }}
                values={
                  inningsData?.homeBatting
                    ? matchPlayers?.data?.home.map((player) => {
                        return {
                          label:
                            player.number +
                            " | " +
                            player.firstName[0].toUpperCase() +
                            " " +
                            player.lastName +
                            " (Home)",
                          value: player.id,
                        }
                      }) || []
                    : matchPlayers?.data?.guest.map((player) => {
                        return {
                          label:
                            player.number +
                            " | " +
                            player.firstName[0].toUpperCase() +
                            " " +
                            player.lastName +
                            " (Guest)",
                          value: player.id,
                        }
                      }) || []
                }
              />
            </div>

            <DefaultButton
              onClick={async () => {
                if (!changeBatsmanLoading) {
                  setStrikerChangeLoading(true)

                  await switchBatsman({
                    matchId: params.matchId,
                    inningsId: params.inningsId,
                  })

                  setStrikerChangeLoading(false)
                }
              }}
            >
              {strikerChangeLoading ? (
                <span className="loader" />
              ) : (
                <div className="material-symbols-rounded">sync_alt</div>
              )}
            </DefaultButton>

            <div className="batsman-c">
              <h3>Non-Striker</h3>

              <DropDown
                placeHolder="Select Non-Striker"
                value={nonStriker}
                setValue={setNonStriker}
                onChange={async (value) => {
                  if (value !== null && value !== "") {
                    await changeActiveBatsman({
                      matchId: params.matchId,
                      inningsId: params.inningsId,
                      homeBatting: inningsData?.homeBatting,
                      isStriker: false,
                      playerId: value,
                    })
                  }

                  if (value === striker) {
                    toaster.warning(
                      "Striker and Non-Striker are same",
                      "Please select different players",
                    )
                  }

                  const batsmanData =
                    scores?.data &&
                    scores?.data[
                      inningsData?.homeBatting ? "home" : "guest"
                    ].batting.find((batsman) => batsman.playerId === value)

                  if (
                    batsmanData?.status !== "Yet to Bat" &&
                    batsmanData?.status !== "Not Out"
                  ) {
                    toaster.warning(
                      "Player already out",
                      "Please select different player",
                    )
                  }
                }}
                values={
                  inningsData?.homeBatting
                    ? matchPlayers?.data?.home.map((player) => {
                        return {
                          label:
                            player.number +
                            " | " +
                            player.firstName[0].toUpperCase() +
                            " " +
                            player.lastName +
                            " (Home)",
                          value: player.id,
                        }
                      }) || []
                    : matchPlayers?.data?.guest.map((player) => {
                        return {
                          label:
                            player.number +
                            " | " +
                            player.firstName[0].toUpperCase() +
                            " " +
                            player.lastName +
                            " (Guest)",
                          value: player.id,
                        }
                      }) || []
                }
              />
            </div>
          </div>

          <div className="btm-c-cards">
            <EventCard
              commentaryFocused={false}
              selectValue={selectedBallInEvents}
              setSelectValue={setSelectedBallInEvents}
            />

            <div className="table-c card-bg">
              <ScoreTableIndividual
                home={tableSchoolHome}
                batting={tableBatting}
                matchId={params.matchId}
                inningsId={params.inningsId}
              >
                <div className="btn-r-c">
                  <div className="btn-row">
                    <DefaultButton
                      onClick={() => {
                        setTableSchoolHome(true)
                      }}
                      disabled={!tableSchoolHome}
                      disableButton={false}
                    >
                      Home
                    </DefaultButton>

                    <DefaultButton
                      onClick={() => {
                        setTableSchoolHome(false)
                      }}
                      disabled={tableSchoolHome}
                      disableButton={false}
                    >
                      Guest
                    </DefaultButton>
                  </div>

                  <div className="btn-row">
                    <DefaultButton
                      onClick={() => {
                        setTableBatting(true)
                      }}
                      disabled={!tableBatting}
                      disableButton={false}
                    >
                      Batting
                    </DefaultButton>

                    <DefaultButton
                      onClick={() => {
                        setTableBatting(false)
                      }}
                      disabled={tableBatting}
                      disableButton={false}
                    >
                      Bowling
                    </DefaultButton>
                  </div>
                </div>
              </ScoreTableIndividual>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
