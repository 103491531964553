import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch } from "../../../app/hooks"
import { MatchPlayers as MatchPlayersType, Schools } from "../../../app/types"

import {
  useAddPlayerMutation,
  useEditPlayerMutation,
  useGetPlayersQuery,
} from "../../../features/firebase/subSlices/matches/playersSlice"
import { useGetSchoolsQuery } from "../../../features/firebase/subSlices/schoolsSlice"
import { useGetMatchesQuery } from "../../../features/firebase/subSlices/matches/matchesSlice"
import { changeHeader } from "../../../features/client/pageHeader"

import "./MatchPlayers.css"

import InputField from "../../../components/InputField/InputField"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import ColorButton from "../../../components/Buttons/ColorButton/ColorButton"

export default function MatchPlayers() {
  const dispatch = useAppDispatch()
  const params = useParams()

  const matchPlayers =
    params.matchId !== undefined ? useGetPlayersQuery(params.matchId) : null

  const schools = useGetSchoolsQuery()
  const matches = useGetMatchesQuery()
  const [addPlayer] = useAddPlayerMutation()
  const [editPlayer] = useEditPlayerMutation()

  const [homeSchool, setHomeSchool] = useState<Schools[0]>()
  const [guestSchool, setGuestSchool] = useState<Schools[0]>()

  const [homeFirstName, setHomeFirstName] = useState("")
  const [homeLastName, setHomeLastName] = useState("")
  const [homeNumber, setHomeNumber] = useState("")

  const [guestFirstName, setGuestFirstName] = useState("")
  const [guestLastName, setGuestLastName] = useState("")
  const [guestNumber, setGuestNumber] = useState("")

  const [homeLoading, setHomeLoading] = useState(false)
  const [guestLoading, setGuestLoading] = useState(false)

  const [homeEdit, setHomeEdit] = useState<MatchPlayersType["home"][0] | null>(
    null,
  )
  const [guestEdit, setGuestEdit] = useState<
    MatchPlayersType["guest"][0] | null
  >(null)

  useEffect(() => {
    const match = matches.data?.find((m) => m.id === params.matchId)
    if (match) {
      const home = schools.data?.find((s) => s.id === match.homeSchool)
      const guest = schools.data?.find((s) => s.id === match.guestSchool)
      setHomeSchool(home)
      setGuestSchool(guest)
    }
  }, [matches, params.matchId])

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Match Players",
      }),
    )
  }, [dispatch])

  return (
    <div className="players-page">
      <div className="home card-bg">
        <div className="row">
          <h2>Home Players</h2>
          <div>{matchPlayers?.data?.home.length} Players</div>
        </div>

        <div className="school-row">
          {homeSchool ? (
            <>
              <img src={homeSchool.image} alt={homeSchool.name} />
              <div className="school-d">
                <h4>{homeSchool.name}</h4>
                <p>{homeSchool.city}</p>
              </div>
            </>
          ) : (
            <div className="loader-container">
              <div className="loader" />
            </div>
          )}
        </div>

        {matchPlayers?.isLoading ? (
          <div className="loader"></div>
        ) : matchPlayers?.data?.home && matchPlayers?.data?.home.length > 0 ? (
          <table className="players">
            <tbody>
              <tr>
                <th>Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th></th>
              </tr>

              {matchPlayers?.data?.home.map((player) => (
                <tr key={player.id} className="player">
                  <td>{player.number}</td>
                  <td>{player.firstName}</td>
                  <td>{player.lastName}</td>
                  <td>
                    <button
                      className="material-symbols-rounded edit-btn"
                      onClick={() => {
                        setHomeEdit(player)
                        setHomeFirstName(player.firstName)
                        setHomeLastName(player.lastName)
                        setHomeNumber(player.number)
                      }}
                    >
                      edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-players">No Players Added</div>
        )}
      </div>

      {homeEdit !== null ? (
        <div className="add-player-card card-bg">
          <h3>Edit Player</h3>

          <InputField
            title="Player First Name"
            name="homeFirstName"
            placeholder="Enter Player First Name"
            value={homeFirstName}
            setValue={setHomeFirstName}
          />

          <InputField
            title="Player Last Name"
            name="homeLastName"
            placeholder="Enter Player Last Name"
            value={homeLastName}
            setValue={setHomeLastName}
          />

          <InputField
            title="Player Number"
            name="homeNumber"
            placeholder="Enter Player Number"
            value={homeNumber}
            setValue={setHomeNumber}
          />

          <ColorButton
            onClick={async () => {
              setHomeLoading(true)
              await editPlayer({
                matchId: params.matchId,
                playerId: homeEdit.id,
                playerType: "home",
                firstName:
                  homeFirstName === homeEdit.firstName
                    ? undefined
                    : homeFirstName,
                lastName:
                  homeLastName === homeEdit.lastName ? undefined : homeLastName,
                number: homeNumber === homeEdit.number ? undefined : homeNumber,
              })

              setHomeLoading(false)
              setHomeEdit(null)
              setHomeFirstName("")
              setHomeLastName("")
              setHomeNumber("")
            }}
          >
            {homeLoading ? (
              <span className="loader" />
            ) : (
              <>
                <div className="material-symbols-rounded">edit</div>
                <div className="text">Edit Player</div>
              </>
            )}
          </ColorButton>

          <DefaultButton
            onClick={() => {
              setHomeEdit(null)
              setHomeFirstName("")
              setHomeLastName("")
            }}
          >
            <div className="material-symbols-rounded">delete_forever</div>
            <div className="text">Discard</div>
          </DefaultButton>
        </div>
      ) : (
        <div className="add-player-card card-bg">
          <h3>Add Home Player</h3>

          <InputField
            title="Player First Name"
            name="homeFirstName"
            placeholder="Enter Player First Name"
            value={homeFirstName}
            setValue={setHomeFirstName}
          />

          <InputField
            title="Player Last Name"
            name="homeLastName"
            placeholder="Enter Player Last Name"
            value={homeLastName}
            setValue={setHomeLastName}
          />

          <InputField
            title="Player Number"
            name="homeNumber"
            placeholder="Enter Player Number"
            value={homeNumber}
            setValue={setHomeNumber}
          />

          <DefaultButton
            onClick={async () => {
              setHomeLoading(true)
              await addPlayer({
                matchId: params.matchId,
                playerType: "home",
                firstName: homeFirstName,
                lastName: homeLastName,
                number: homeNumber,
              })

              setHomeLoading(false)
              setHomeFirstName("")
              setHomeLastName("")
              setHomeNumber("")
            }}
          >
            {homeLoading ? (
              <span className="loader" />
            ) : (
              <>
                <div className="material-symbols-rounded">person_add</div>
                <div className="text">Add Player</div>
              </>
            )}
          </DefaultButton>
        </div>
      )}

      <div className="guest card-bg">
        <div className="row">
          <h2>Guest Players</h2>
          <div>{matchPlayers?.data?.guest.length} Players</div>
        </div>

        <div className="school-row">
          {guestSchool ? (
            <>
              <img src={guestSchool.image} alt={guestSchool.name} />
              <div className="school-d">
                <h4>{guestSchool.name}</h4>
                <p>{guestSchool.city}</p>
              </div>
            </>
          ) : (
            <div className="loader-container">
              <div className="loader" />
            </div>
          )}
        </div>

        {matchPlayers?.isLoading ? (
          <div className="loader"></div>
        ) : matchPlayers?.data?.guest &&
          matchPlayers?.data?.guest.length > 0 ? (
          <table className="players">
            <tbody>
              <tr>
                <th>Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th></th>
              </tr>

              {matchPlayers?.data?.guest.map((player) => (
                <tr key={player.id} className="player">
                  <td>{player.number}</td>
                  <td>{player.firstName}</td>
                  <td>{player.lastName}</td>
                  <td>
                    <button
                      className="material-symbols-rounded edit-btn"
                      onClick={() => {
                        setGuestEdit(player)
                        setGuestFirstName(player.firstName)
                        setGuestLastName(player.lastName)
                        setGuestNumber(player.number)
                      }}
                    >
                      edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-players">No Players Added</div>
        )}
      </div>

      {guestEdit !== null ? (
        <div className="add-player-card card-bg">
          <h3>Edit Player</h3>

          <InputField
            title="Player First Name"
            name="guestFirstName"
            placeholder="Enter Player First Name"
            value={guestFirstName}
            setValue={setGuestFirstName}
          />

          <InputField
            title="Player Last Name"
            name="guestLastName"
            placeholder="Enter Player Last Name"
            value={guestLastName}
            setValue={setGuestLastName}
          />

          <InputField
            title="Player Number"
            name="guestNumber"
            placeholder="Enter Player Number"
            value={guestNumber}
            setValue={setGuestNumber}
          />

          <ColorButton
            onClick={async () => {
              setGuestLoading(true)
              await editPlayer({
                matchId: params.matchId,
                playerId: guestEdit.id,
                playerType: "guest",
                firstName:
                  guestFirstName === guestEdit.firstName
                    ? undefined
                    : guestFirstName,
                lastName:
                  guestLastName === guestEdit.lastName
                    ? undefined
                    : guestLastName,
                number:
                  guestNumber === guestEdit.number ? undefined : guestNumber,
              })

              setGuestLoading(false)
              setGuestEdit(null)
              setGuestFirstName("")
              setGuestLastName("")
              setGuestNumber("")
            }}
          >
            {guestLoading ? (
              <span className="loader" />
            ) : (
              <>
                <div className="material-symbols-rounded">edit</div>
                <div className="text">Edit Player</div>
              </>
            )}
          </ColorButton>

          <DefaultButton
            onClick={() => {
              setGuestEdit(null)
              setGuestFirstName("")
              setGuestLastName("")
              setGuestNumber("")
            }}
          >
            <div className="material-symbols-rounded">delete_forever</div>
            <div className="text">Discard</div>
          </DefaultButton>
        </div>
      ) : (
        <div className="add-player-card card-bg">
          <h3>Add Guest Player</h3>

          <InputField
            title="Player First Name"
            name="guestFirstName"
            placeholder="Enter Player First Name"
            value={guestFirstName}
            setValue={setGuestFirstName}
          />

          <InputField
            title="Player Last Name"
            name="guestLastName"
            placeholder="Enter Player Last Name"
            value={guestLastName}
            setValue={setGuestLastName}
          />

          <InputField
            title="Player Number"
            name="guestNumber"
            placeholder="Enter Player Number"
            value={guestNumber}
            setValue={setGuestNumber}
          />

          <DefaultButton
            onClick={async () => {
              setGuestLoading(true)
              await addPlayer({
                matchId: params.matchId,
                playerType: "guest",
                firstName: guestFirstName,
                lastName: guestLastName,
                number: guestNumber,
              })

              setGuestLoading(false)
              setGuestFirstName("")
              setGuestLastName("")
              setGuestNumber("")
            }}
          >
            {guestLoading ? (
              <span className="loader" />
            ) : (
              <>
                <div className="material-symbols-rounded">person_add</div>
                <div className="text">Add Player</div>
              </>
            )}
          </DefaultButton>
        </div>
      )}
    </div>
  )
}
