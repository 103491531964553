import { useEffect } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { onAuthStateChanged } from "firebase/auth"

import { generateUserSnippetFromUser } from "./app/functions"
import { useAppDispatch } from "./app/hooks"
import { auth } from "./app/firebase"
import { toaster } from "./app/toaster"

import { useUpdateUserListMutation } from "./features/firebase/subSlices/userListSlice"
import { setUser } from "./features/userSlice"

import Root from "./routes/Root/Root"
import VerifyEmail from "./routes/VerifyEmail/VerifyEmail"
import Dashboard from "./routes/Dashboard/Dashboard"
import Matches from "./routes/Matches/Matches"
import NewMatch from "./routes/Matches/NewMatch/NewMatch"
import MatchDetails from "./routes/Matches/MatchDetails/MatchDetails"
import EditMatch from "./routes/Matches/EditMatch/EditMatch"
import MatchPlayers from "./routes/Matches/MatchPlayers/MatchPlayers"
import Innings from "./routes/Matches/Cricket/Innings/Innings"
import InningsDashboard from "./routes/Matches/Cricket/Innings/InningsDashboard/InningsDashboard"
import InningsScoreTable from "./routes/Matches/Cricket/Innings/InningsDashboard/ScoreTable/InningsScoreTable"
import Commentary from "./routes/Matches/Commentary/Commentary"
import News from "./routes/News/News"
import NewsPage from "./routes/News/NewsPage/NewsPage"
import NewNews from "./routes/News/NewNews/NewNews"
import Admins from "./routes/Admins/Admins"
import NewAdmin from "./routes/Admins/NewAdmin/NewAdmin"
import NewSchool from "./routes/NewSchool/NewSchool"
import EditSchool from "./routes/EditSchool/EditSchool"

import ErrorPage from "./pages/ErrorPage/ErrorPage"
import LoginPage from "./pages/LoginPage/LoginPage"

import Toast from "./components/Toast/Toast"
import MatchDashboard from "./routes/Matches/Dashboard/MatchDashboard"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/matches",
        element: <Matches />,
      },
      {
        path: "/matches/new",
        element: <NewMatch />,
      },
      {
        path: "/matches/:matchId",
        element: <MatchDetails />,
      },
      {
        path: "/matches/:matchId/edit",
        element: <EditMatch />,
      },
      {
        path: "/matches/:matchId/players",
        element: <MatchPlayers />,
      },
      {
        path: "/matches/:matchId/innings",
        element: <Innings />,
      },
      {
        path: "/matches/:matchId/dashboard",
        element: <MatchDashboard />,
      },
      {
        path: "/matches/:matchId/commentary",
        element: <Commentary />,
      },
      {
        path: "/matches/:matchId/innings/:inningsId",
        element: <InningsDashboard />,
      },
      {
        path: "/matches/:matchId/innings/:inningsId/score-table",
        element: <InningsScoreTable />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/news/:newsId",
        element: <NewsPage />,
      },
      {
        path: "/news/new",
        element: <NewNews />,
      },
      {
        path: "/notifications",
        element: <Dashboard />,
      },
      {
        path: "/admins",
        element: <Admins />,
      },
      {
        path: "/admins/new",
        element: <NewAdmin />,
      },
      {
        path: "/new-school",
        element: <NewSchool />,
      },
      {
        path: "/edit-school/:schoolId",
        element: <EditSchool />,
      },
    ],
  },
])

function App() {
  const dispatch = useAppDispatch()

  const [updateUserList] = useUpdateUserListMutation()

  // This is a must-want code snippet for the function of the app
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(setUser(await generateUserSnippetFromUser(user, "success")))
        await user
          ?.getIdTokenResult()
          .then((idTokenResult) => {
            if (
              idTokenResult.claims.admin === "true" ||
              idTokenResult.claims.admin === true
                ? true
                : false
            ) {
              updateUserList(null)
            }
          })
          .catch((error) => {
            toaster.error("Authentication Error", "Can't get user claims")
            console.error(error)
            throw new Error("Can't get user claims")
          })
      } else {
        dispatch(
          setUser({
            uid: undefined,
            status: "logout",
            admin: false,
            userData: undefined,
          }),
        )
      }
    })
  }, [updateUserList, dispatch])

  return (
    <>
      <Toast />
      <RouterProvider router={router} />
    </>
  )
}

export default App
