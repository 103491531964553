import { useEffect, useState } from "react"
import { sendPasswordResetEmail } from "firebase/auth"
import { useNavigate } from "react-router-dom"

import { PopupButtonActions } from "../../app/types"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { validateEmail } from "../../app/functions"
import { auth } from "../../app/firebase"

import {
  closePopup,
  setButtonLoading,
  setErrorMessage,
  setInputValue,
  showPopup,
} from "../../features/client/popupSlice"
import { useChangeEditorToAdminMutation } from "../../features/firebase/subSlices/userListSlice"
import { useDeleteSchoolMutation } from "../../features/firebase/subSlices/schoolsSlice"
import { useDeleteNewsMutation } from "../../features/firebase/subSlices/newsSlice"
import { useDeleteCommentaryEventMutation } from "../../features/firebase/subSlices/matches/cricket/eventSlice"

import "./Popup.css"

import InputField from "../InputField/InputField"
import DefaultButton from "../Buttons/DefaultButton/DefaultButton"

export default function Popup() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const popupData = useAppSelector((state) => state.popup)

  const [setChangeUserToAdmin] = useChangeEditorToAdminMutation()
  const [deleteSchool] = useDeleteSchoolMutation()
  const [deleteNews] = useDeleteNewsMutation()
  const [deleteCommentaryEvent] = useDeleteCommentaryEventMutation()

  const [showError, setShowError] = useState(true)

  function getButtonActionFunction(
    action: PopupButtonActions,
  ): (data: string | undefined) => void {
    switch (action) {
      case "reset-password":
        return async () => {
          if (popupData.inputValue === "" || undefined) {
            dispatch(setErrorMessage("Enter a Valid Email"))
          } else if (!validateEmail(popupData.inputValue)) {
            dispatch(setErrorMessage("Entered Email is Invalid"))
          } else {
            dispatch(setButtonLoading({ index: 0, state: true }))
            await sendPasswordResetEmail(auth, popupData.inputValue)
              .then(() => {
                setShowError(false)
                dispatch(
                  showPopup({
                    title: "Reset Password Email Sent",
                    message:
                      "Successfully sent an Email with a link to reset your password. If you don't see it in your inbox, take a quick peek in your spam folder",
                    errorMsg: "",
                    buttons: [
                      {
                        title: "Close",
                        action: "close",
                        loading: false,
                      },
                    ],
                  }),
                )
              })
              .catch((error) => {
                dispatch(setButtonLoading({ index: 0, state: false }))

                if (error.code === "auth/user-not-found") {
                  dispatch(setErrorMessage("Entered email is Invalid"))
                } else {
                  dispatch(setErrorMessage("Something went wrong, Try again"))
                }

                throw new Error(error)
              })
          }
        }

      case "close":
        return () => {
          setTimeout(() => {
            dispatch(closePopup())
          }, 300)
        }

      case "change-user-to-admin":
        return (data: string | undefined) => {
          if (data) {
            setChangeUserToAdmin({ uid: data })
            dispatch(closePopup())
          }
        }

      case "delete-school":
        return async (data: string | undefined) => {
          if (data) {
            dispatch(setButtonLoading({ index: 0, state: true }))
            await deleteSchool({ id: data })
            dispatch(closePopup())
            navigate("/dashboard")
          }
        }

      case "delete-news":
        return async (data: string | undefined) => {
          if (data) {
            dispatch(setButtonLoading({ index: 0, state: true }))
            await deleteNews({ id: data })
            dispatch(closePopup())
            navigate("/news")
          }
        }

      case "delete-event":
        return async (data: string | undefined) => {
          if (data) {
            dispatch(setButtonLoading({ index: 0, state: true }))
            await deleteCommentaryEvent({
              matchId: data.split("---")[0],
              eventId: data.split("---")[1],
            })
            dispatch(closePopup())
          }
        }

      default:
        return () => {}
    }
  }

  const [showPopupUI, setShowPopupUI] = useState(false)

  useEffect(() => {
    if (popupData.active) {
      setShowPopupUI(true)
    } else {
      setTimeout(() => {
        setShowPopupUI(false)
      }, 500)
    }
  }, [popupData.active])

  return (
    (popupData.active || showPopupUI) && (
      <div
        id="popup"
        style={{
          animation: popupData.active
            ? "fade-in 0.1s linear 0s 1 normal forwards"
            : "fade-out 0.1s linear 0s 1 normal forwards",
        }}
        onClick={() => {
          setTimeout(() => {
            dispatch(closePopup())
          }, 300)
        }}
      >
        <div
          className="popup-card"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <h3>{popupData.title}</h3>
          <p>{popupData.message}</p>

          {popupData.input ? (
            <InputField
              title={popupData.input.title}
              name={popupData.input.name}
              type={popupData.input.type}
              placeholder={popupData.input.placeholder}
              value={popupData.inputValue}
              setValueManual={(value) => {
                dispatch(setInputValue(value))
              }}
            />
          ) : (
            <br />
          )}

          {popupData.errorMsg !== ("" || undefined) && showError && (
            <p className="error-msg">* {popupData.errorMsg}</p>
          )}

          {popupData.buttons?.map((button, index) => (
            <DefaultButton
              key={index + "-popup-btn"}
              onClick={() => {
                getButtonActionFunction(button.action)(button.data)
              }}
            >
              {button.loading ? <span className="loader" /> : button.title}
            </DefaultButton>
          ))}
        </div>
      </div>
    )
  )
}
