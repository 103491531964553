import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"

import { auth } from "../app/firebase"
import { RootState } from "../app/store"
import { LoginInput, UserSnippet } from "../app/types"
import { generateUserSnippetFromUser } from "../app/functions"
import { toaster } from "../app/toaster"

const initialState: UserSnippet = {
  uid: undefined,
  status: "loading",
  admin: false,
  userData: undefined,
}

export const login = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }: LoginInput) => {
    return await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user

        return await generateUserSnippetFromUser(user, "success")
      })
      .catch((error) => {
        toaster.error("Authentication Error", "Failed to login, Try again")
        const errorCode = error.code
        console.error(error)

        return { status: errorCode, uid: undefined, userData: undefined }
      })
  },
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserSnippet | undefined>) => {
      state.uid = action.payload?.uid
      state.status = action.payload?.status
      state.admin = action.payload?.admin
      state.userData = action.payload?.userData
    },
    logout: (state) => {
      signOut(auth)
        .then(() => {
          state = {
            uid: undefined,
            status: "logout",
            admin: false,
            userData: undefined,
          }
        })
        .catch((error) => {
          toaster.error("Authentication Error", "Failed to logout, Try again")
          console.error("Error signing out")
          throw new Error(error)
        })
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading"
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = action.payload?.status
        if (action.payload?.uid) state.uid = action.payload?.uid
        if (action.payload?.userData) state.userData = action.payload?.userData
      })
      .addCase(login.rejected, (state) => {
        state.status = "error"
      })
  },
})

export const { setUser, logout } = userSlice.actions

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer
