import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface PageHeaderType {
  title: string
  backButton?: boolean
  buttons?: {
    title: string
    icon: string
    functionName: string
    data: string
  }[]
}

const initialState: PageHeaderType = {
  title: "",
  backButton: true,
  buttons: [],
}

export const pageHeaderSlice = createSlice({
  name: "pageHeader",
  initialState,
  reducers: {
    changeHeader: (state, action: PayloadAction<PageHeaderType>) => {
      state.title = action.payload.title
      state.backButton =
        action.payload.backButton === undefined
          ? true
          : action.payload.backButton
      state.buttons = action.payload.buttons
    },
  },
})

export const { changeHeader } = pageHeaderSlice.actions

export default pageHeaderSlice.reducer
