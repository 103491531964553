import { useAppSelector } from "../../app/hooks"

import { useDisableAllEditorsMutation } from "../../features/firebase/subSlices/userListSlice"

import "./DisableEditorsCard.css"

import ColorButton from "../Buttons/ColorButton/ColorButton"

export default function DisableEditorsCard() {
  const userListLoad = useAppSelector((state) => state.userListLoad)

  const [setDisableAllEditors] = useDisableAllEditorsMutation()

  return (
    <div className="card-bg editor-disable-all-card">
      <h3>Editor Access</h3>
      <p>
        Temporally disable admin panel access of all admins with Editor Roles.
      </p>
      <ColorButton
        color="rgba(159, 9, 183, 0.7)"
        hoverColor="rgba(159, 9, 183, 0.5)"
        onClick={() => {
          setDisableAllEditors(null)
        }}
      >
        {userListLoad.disableAllLoading ? (
          <span className="loader" />
        ) : (
          "Disable All"
        )}
      </ColorButton>
    </div>
  )
}
