import { useEffect, useState } from "react"

import { useAppDispatch } from "../../app/hooks"

import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../features/firebase/subSlices/settingsSlice"
import { changeHeader } from "../../features/client/pageHeader"
import { useGetMatchesQuery } from "../../features/firebase/subSlices/matches/matchesSlice"

import "./Matches.css"

import MatchCard from "../../components/MatchCard/MatchCard"
import DropDown from "../../components/DropDown/DropDown"

export default function Matches() {
  const dispatch = useAppDispatch()

  const matches = useGetMatchesQuery()

  const settings = useGetSettingsQuery()
  const [setSettings] = useUpdateSettingsMutation()

  const [matchOnHome, setMatchOnHome] = useState<string>("")

  useEffect(() => {
    if (settings.data) {
      setMatchOnHome(settings.data.matchOnHome)
    }
  }, [settings.data])

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Matches",
        backButton: false,
        buttons: [
          {
            title: "New Match",
            icon: "add",
            functionName: "redirect",
            data: "/matches/new",
          },
        ],
      }),
    )
  }, [dispatch])

  return matches.isLoading ? (
    <div className="loader-container">
      <span className="loader" />
    </div>
  ) : (
    <div>
      <div className="match-on-home-card card-bg">
        <h2>Match on Home</h2>
        <p>
          Select the match to be shown in home screen and app background image.
          This match will be visible in the homepage of the app.
        </p>

        <DropDown
          placeHolder="Select a match"
          values={
            matches.data?.map((match) => {
              return {
                label: `${match.title} (${match.id})`,
                value: match.id,
              }
            }) || []
          }
          value={matchOnHome}
          setValue={setMatchOnHome}
          onChange={(value) => {
            setSettings({ field: "matchOnHome", data: value })
          }}
        />
      </div>

      <div className="matches-container">
        {matches.data?.map((match) => (
          <MatchCard
            key={match.id}
            id={match.id}
            active={match.active}
            title={match.title}
            subText={match.subText}
            image={match.image}
            startTime={new Date(match.startTime.seconds * 1000)}
            location={match.location}
            infoChip={match.infoChip}
            matchStatus={match.matchStatus}
            homeSchool={match.homeSchool}
            guestSchool={match.guestSchool}
            matchType={match.matchType}
            openDetails
          />
        ))}
      </div>
    </div>
  )
}
