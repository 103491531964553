import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore"

import { db } from "../../../../app/firebase"
import { toaster } from "../../../../app/toaster"
import { MatchPlayers } from "../../../../app/types"

import { firebaseSlice } from "../../firebaseSlice"

export const extendedMatchPlayersSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlayers: builder.query<MatchPlayers, string>({
      queryFn: async (args) => {
        try {
          const matchId = args

          const querySnapshotHome = await getDocs(
            query(
              collection(db, "matches-prod", matchId, "homePlayers"),
              orderBy("firstName"),
            ),
          )

          const homePlayers: MatchPlayers["home"] = []

          querySnapshotHome.forEach((doc) => {
            const data = doc.data()

            if (!data.firstName) {
              toaster.error("Firestore Error", "Player firstName is missing")
              return { error: `Player firstName is missing document ${doc.id}` }
            }

            if (!data.lastName) {
              toaster.error("Firestore Error", "Player lastName is missing")
              return { error: `Player lastName is missing document ${doc.id}` }
            }

            homePlayers.push({
              id: doc.id,
              ...data,
            } as MatchPlayers["home"][0])
          })

          const querySnapshotGuest = await getDocs(
            query(
              collection(db, "matches-prod", matchId, "guestPlayers"),
              orderBy("firstName"),
            ),
          )

          const guestPlayers: MatchPlayers["guest"] = []

          querySnapshotGuest.forEach((doc) => {
            const data = doc.data()

            if (!data.firstName) {
              toaster.error("Firestore Error", "Player firstName is missing")
              return { error: `Player firstName is missing document ${doc.id}` }
            }

            if (!data.lastName) {
              toaster.error("Firestore Error", "Player lastName is missing")
              return { error: `Player lastName is missing document ${doc.id}` }
            }

            guestPlayers.push({
              id: doc.id,
              ...data,
            } as MatchPlayers["guest"][0])
          })

          return { data: { home: homePlayers, guest: guestPlayers } }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.home.map(({ id }) => ({
                type: "MatchPlayers" as const,
                id: arg + id,
              })),
              ...result.guest.map(({ id }) => ({
                type: "MatchPlayers" as const,
                id: arg + id,
              })),
              "MatchPlayers",
            ]
          : ["MatchPlayers"],

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            query(
              collection(db, "matches-prod", arg, "homePlayers"),
              orderBy("firstName"),
            ),
            (querySnapshot) => {
              const homePlayers: MatchPlayers["home"] = []

              querySnapshot.forEach((doc) => {
                const data = doc.data()

                if (!data.firstName) {
                  toaster.error(
                    "Firestore Error",
                    "Player firstName is missing",
                  )
                  return {
                    error: `Player firstName is missing document ${doc.id}`,
                  }
                }

                if (!data.lastName) {
                  toaster.error("Firestore Error", "Player lastName is missing")
                  return {
                    error: `Player lastName is missing document ${doc.id}`,
                  }
                }

                homePlayers.push({
                  id: doc.id,
                  ...data,
                } as MatchPlayers["home"][0])
              })

              updateCachedData((draft) => {
                Object.assign(draft.home, homePlayers)
              })
            },
          )

          onSnapshot(
            query(
              collection(db, "matches-prod", arg, "guestPlayers"),
              orderBy("firstName"),
            ),
            (querySnapshot) => {
              const guestPlayers: MatchPlayers["guest"] = []

              querySnapshot.forEach((doc) => {
                const data = doc.data()

                if (!data.firstName) {
                  toaster.error(
                    "Firestore Error",
                    "Player firstName is missing",
                  )
                  return {
                    error: `Player firstName is missing document ${doc.id}`,
                  }
                }

                if (!data.lastName) {
                  toaster.error("Firestore Error", "Player lastName is missing")
                  return {
                    error: `Player lastName is missing document ${doc.id}`,
                  }
                }

                guestPlayers.push({
                  id: doc.id,
                  ...data,
                } as MatchPlayers["guest"][0])
              })

              updateCachedData((draft) => {
                Object.assign(draft.guest, guestPlayers)
              })
            },
          )
        } catch (error: any) {
          toaster.error(
            "Firestore Error",
            "Failed to add cache entry for players",
          )
        }

        await cacheEntryRemoved
      },
    }),

    addPlayer: builder.mutation({
      async queryFn({ matchId, playerType, firstName, lastName, number }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!playerType) {
            toaster.error("Check all fields", "Player type is missing")
            return { error: "Player type is missing" }
          } else {
            if (playerType !== "home" && playerType !== "guest") {
              toaster.error("Check all fields", "Player type is invalid")
              return { error: "Player type is invalid" }
            }
          }

          if (!firstName) {
            toaster.error("Check all fields", "Player firstName is missing")
            return { error: "Player firstName is missing" }
          }

          if (!lastName) {
            toaster.error("Check all fields", "Player lastName is missing")
            return { error: "Player lastName is missing" }
          }

          const updateDoc = {
            firstName,
            lastName,
          }

          if (number) {
            Object.assign(updateDoc, { number })
          }

          const docRef = await addDoc(
            collection(
              db,
              "matches-prod",
              matchId,
              playerType === "home" ? "homePlayers" : "guestPlayers",
            ),
            updateDoc,
          )

          toaster.success(
            "Player Added",
            `Player ${firstName} ${lastName} had been added successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add player")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["MatchPlayers"],
    }),

    editPlayer: builder.mutation({
      async queryFn({
        matchId,
        playerId,
        playerType,
        firstName,
        lastName,
        number,
      }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!playerId) {
            toaster.error("Check all fields", "Player id is missing")
            return { error: "Player id is missing" }
          }

          if (!playerType) {
            toaster.error("Check all fields", "Player type is missing")
            return { error: "Player type is missing" }
          } else {
            if (playerType !== "home" && playerType !== "guest") {
              toaster.error("Check all fields", "Player type is invalid")
              return { error: "Player type is invalid" }
            }
          }

          const updateObject = {}

          if (firstName) {
            Object.assign(updateObject, { firstName })
          }

          if (lastName) {
            Object.assign(updateObject, { lastName })
          }

          if (number) {
            Object.assign(updateObject, { number })
          }

          if (Object.keys(updateObject).length > 0) {
            await updateDoc(
              doc(
                db,
                "matches-prod",
                matchId,
                playerType === "home" ? "homePlayers" : "guestPlayers",
                playerId,
              ),
              updateObject,
            ).catch((error) => {
              toaster.error("Firestore Error", "Failed to update player")
              throw new Error(error)
            })
          }

          if (Object.keys(updateObject).length > 0) {
            toaster.success(
              "Player Updated",
              `Player ${firstName} ${lastName} had been updated successfully`,
            )
          }

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update player")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "MatchPlayers", id: arg.matchId + arg.playerId },
      ],
    }),
  }),
})

export const {
  useGetPlayersQuery,
  useAddPlayerMutation,
  useEditPlayerMutation,
} = extendedMatchPlayersSlice

export const selectPlayers =
  extendedMatchPlayersSlice.endpoints.getPlayers.select
