import { InputFieldType } from "../../app/types"

import "./InputField.css"

export default function InputField({
  title = null,
  name,
  type = "text",
  placeholder,
  icon,
  iconOnClick,
  value,
  step,
  setValue,
  setValueManual,
  textareaCharLimit = null,
  textareaHeight = "7.5rem",
  disabled = false,
}: InputFieldType) {
  return (
    <div className="input-field">
      {title !== null && (
        <label htmlFor={name} className={disabled ? "disabled" : ""}>
          {title}
        </label>
      )}

      <div className="field-container">
        {type === "textarea" ? (
          <>
            <textarea
              className={disabled ? "disabled" : ""}
              id={name}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                if (setValue) setValue(e.target.value)
                if (setValueManual) setValueManual(e.target.value)
              }}
              style={{ height: textareaHeight }}
            ></textarea>
          </>
        ) : (
          <>
            <input
              onKeyDown={(e) => {
                return e.key !== "Enter"
              }}
              className={disabled ? "disabled" : ""}
              id={name}
              type={type}
              step={step}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                if (type === "email") {
                  if (setValue) setValue(e.target.value.replaceAll(" ", ""))
                  if (setValueManual)
                    setValueManual(e.target.value.replaceAll(" ", ""))
                } else {
                  if (setValue) setValue(e.target.value)
                  if (setValueManual) setValueManual(e.target.value)
                }
              }}
            />
            {icon && (
              <div
                className="material-symbols-rounded"
                onClick={() => {
                  iconOnClick && iconOnClick()
                }}
              >
                {icon}
              </div>
            )}
          </>
        )}
      </div>

      {type === "textarea" &&
        textareaCharLimit !== null &&
        value.length > textareaCharLimit && (
          <div className="warning">
            * Characters count exceeds {textareaCharLimit}. Recommended to keep
            it below {textareaCharLimit} Characters. ({value.length} Characters)
          </div>
        )}
    </div>
  )
}
