import { useEffect, useState } from "react"

import { overSafeBall, structurePlayerName } from "../../../../app/functions"

import { useGetMatchesQuery } from "../../../../features/firebase/subSlices/matches/matchesSlice"
import { useGetSchoolsQuery } from "../../../../features/firebase/subSlices/schoolsSlice"
import { useGetPlayersQuery } from "../../../../features/firebase/subSlices/matches/playersSlice"
import { useGetScoresQuery } from "../../../../features/firebase/subSlices/matches/cricket/scoresSlice"
import { useGetInningsQuery } from "../../../../features/firebase/subSlices/matches/cricket/inningsSlice"

import "./ScoreCard.css"

export default function ScoreCard({
  matchId,
  inningsId,
  OnClick,
}: {
  matchId: string
  inningsId: string
  OnClick?: () => void
}) {
  const schools = useGetSchoolsQuery()
  const matches = useGetMatchesQuery()

  const matchPlayers = useGetPlayersQuery(matchId)

  const inningsAll = useGetInningsQuery(matchId)

  const match = matches.data?.find((match) => match.id === matchId) || null
  const innings =
    inningsAll?.data?.find((innings) => innings.id === inningsId) || null

  const scores = useGetScoresQuery({
    matchId: matchId,
    inningsId: inningsId,
  })

  const homeSchool = schools.data?.find(
    (school) => school.id === match?.homeSchool,
  )
  const guestSchool = schools.data?.find(
    (school) => school.id === match?.guestSchool,
  )

  const [playerData, setPlayerData] = useState<null | {
    home: {
      isPlayerOneStriker?: boolean
      playerOne: {
        id: string
        name: string
        striker?: boolean
        runs: number
        balls?: number
        wickets?: number
        overs?: number
      } | null
      playerTwo: {
        id: string
        name: string
        striker?: boolean
        runs: number
        balls?: number
        wickets?: number
        overs?: number
      } | null
    }
    guest: {
      isPlayerOneStriker?: boolean
      playerOne: {
        id: string
        name: string
        striker?: boolean
        runs: number
        balls?: number
        wickets?: number
        overs?: number
      } | null
      playerTwo: {
        id: string
        name: string
        striker?: boolean
        runs: number
        balls?: number
        wickets?: number
        overs?: number
      } | null
    }
  }>(null)

  useEffect(() => {
    if (scores?.data && matchPlayers?.data && innings) {
      if (innings.homeBatting) {
        // If the players are same as before

        const currentStrikerSameAsBefore =
          (playerData?.home.isPlayerOneStriker
            ? playerData?.home?.playerOne?.id
            : playerData?.home?.playerTwo?.id) ===
          innings.homeScores.activeBatsman.striker

        const currentNonStrikerSameAsBefore =
          (playerData?.home.isPlayerOneStriker
            ? playerData?.home?.playerTwo?.id
            : playerData?.home?.playerOne?.id) ===
          innings.homeScores.activeBatsman.nonStriker

        if (currentStrikerSameAsBefore && currentNonStrikerSameAsBefore) {
          return
        }

        // Check if the players are switched

        const currentStrikerIsNonStriker =
          (playerData?.home.isPlayerOneStriker
            ? playerData?.home?.playerTwo?.id
            : playerData?.home.playerOne?.id) ===
          innings.homeScores.activeBatsman.striker

        const currentNonStrikerIsStriker =
          (playerData?.home.isPlayerOneStriker
            ? playerData?.home?.playerOne?.id
            : playerData?.home.playerTwo?.id) ===
          innings.homeScores.activeBatsman.nonStriker

        if (currentStrikerIsNonStriker && currentNonStrikerIsStriker) {
          setPlayerData((preVal) => {
            if (preVal?.home.playerOne && preVal?.home.playerTwo) {
              return {
                ...preVal,
                home: {
                  isPlayerOneStriker: !preVal.home.isPlayerOneStriker,
                  playerOne: {
                    ...preVal.home.playerOne,
                    striker: !preVal.home.playerOne.striker,
                  },
                  playerTwo: {
                    ...preVal.home.playerTwo,
                    striker: !preVal.home.playerTwo.striker,
                  },
                },
              }
            } else {
              return preVal
            }
          })

          return
        }

        const striker = matchPlayers.data.home.find(
          (player) => player.id === innings.homeScores.activeBatsman.striker,
        )

        const strikerScores = scores.data.home.batting.find(
          (player) => player.playerId === striker?.id,
        )

        const nonStriker = matchPlayers.data.home.find(
          (player) => player.id === innings.homeScores.activeBatsman.nonStriker,
        )

        const nonStrikerScores = scores.data.home.batting.find(
          (player) => player.playerId === nonStriker?.id,
        )

        const bowlers = scores.data.guest.bowling

        if (!bowlers) {
          return
        }

        const bowlerOne = matchPlayers.data.guest.find(
          (player) => player.id === bowlers[0]?.playerId,
        )

        const bowlerTwo = matchPlayers.data.guest.find(
          (player) => player.id === bowlers[1]?.playerId,
        )

        setPlayerData({
          home: {
            isPlayerOneStriker: true,
            playerOne: striker
              ? {
                  id: striker.id,
                  name: structurePlayerName(
                    striker.firstName,
                    striker.lastName,
                  ),
                  striker: true,
                  runs: strikerScores?.runs || 0,
                  balls: strikerScores?.balls || 0,
                }
              : null,
            playerTwo: nonStriker
              ? {
                  id: nonStriker.id,
                  name: structurePlayerName(
                    nonStriker.firstName,
                    nonStriker.lastName,
                  ),
                  striker: false,
                  runs: nonStrikerScores?.runs || 0,
                  balls: nonStrikerScores?.balls || 0,
                }
              : null,
          },
          guest: {
            playerOne: {
              id: bowlers[0].playerId,
              name: structurePlayerName(
                bowlerOne?.firstName || "",
                bowlerOne?.lastName || "",
              ),
              runs: bowlers[0].runs,
              wickets: bowlers[0].wickets,
              overs: bowlers[0].overs,
            },
            playerTwo: {
              id: bowlers[1]?.playerId,
              name:
                structurePlayerName(
                  bowlerTwo?.firstName || "",
                  bowlerTwo?.lastName || "",
                ) || "",
              runs: bowlers[1]?.runs,
              wickets: bowlers[1]?.wickets,
              overs: bowlers[1]?.overs,
            },
          },
        })
      } else {
        // If the players are same as before

        const currentStrikerSameAsBefore =
          (playerData?.guest.isPlayerOneStriker
            ? playerData?.guest?.playerOne?.id
            : playerData?.guest?.playerTwo?.id) ===
          innings.guestScores.activeBatsman.striker

        const currentNonStrikerSameAsBefore =
          (playerData?.guest.isPlayerOneStriker
            ? playerData?.guest?.playerTwo?.id
            : playerData?.guest?.playerOne?.id) ===
          innings.guestScores.activeBatsman.nonStriker

        if (currentStrikerSameAsBefore && currentNonStrikerSameAsBefore) {
          return
        }

        // Check if the players are switched

        const currentStrikerIsNonStriker =
          (playerData?.guest.isPlayerOneStriker
            ? playerData?.guest?.playerTwo?.id
            : playerData?.guest?.playerOne?.id) ===
          innings.guestScores.activeBatsman.striker

        const currentNonStrikerIsStriker =
          (playerData?.guest.isPlayerOneStriker
            ? playerData?.guest?.playerOne?.id
            : playerData?.guest?.playerTwo?.id) ===
          innings.guestScores.activeBatsman.nonStriker

        if (currentStrikerIsNonStriker && currentNonStrikerIsStriker) {
          setPlayerData((preVal) => {
            if (preVal?.guest?.playerOne && preVal?.guest?.playerTwo) {
              return {
                ...preVal,
                guest: {
                  isPlayerOneStriker: !preVal.guest?.isPlayerOneStriker,
                  playerOne: {
                    ...preVal.guest?.playerOne,
                    striker: !preVal.guest?.playerOne.striker,
                  },
                  playerTwo: {
                    ...preVal.guest.playerTwo,
                    striker: !preVal.guest?.playerTwo.striker,
                  },
                },
              }
            } else {
              return preVal
            }
          })

          return
        }

        const striker = matchPlayers.data.guest.find(
          (player) => player.id === innings.guestScores.activeBatsman?.striker,
        )

        const strikerScores = scores.data.guest.batting.find(
          (player) => player.playerId === striker?.id,
        )

        const nonStriker = matchPlayers.data.guest.find(
          (player: { id: any }) =>
            player.id === innings.guestScores.activeBatsman?.nonStriker,
        )

        const nonStrikerScores = scores.data.guest.batting.find(
          (player) => player.playerId === nonStriker?.id,
        )

        const bowlers = scores.data.home.bowling

        const bowlerOne = matchPlayers.data.home.find(
          (player) => player.id === bowlers[0]?.playerId,
        )

        const bowlerTwo = matchPlayers.data.home.find(
          (player) => player.id === bowlers[1]?.playerId,
        )

        setPlayerData({
          guest: {
            isPlayerOneStriker: true,
            playerOne: striker
              ? {
                  id: striker.id,
                  name: structurePlayerName(
                    striker.firstName,
                    striker.lastName,
                  ),
                  striker: true,
                  runs: strikerScores?.runs || 0,
                  balls: strikerScores?.balls || 0,
                }
              : null,
            playerTwo: nonStriker
              ? {
                  id: nonStriker.id,
                  name: structurePlayerName(
                    nonStriker.firstName,
                    nonStriker.lastName,
                  ),
                  striker: false,
                  runs: nonStrikerScores?.runs || 0,
                  balls: nonStrikerScores?.balls || 0,
                }
              : null,
          },
          home: {
            playerOne: {
              id: bowlers[0].playerId,
              name: structurePlayerName(
                bowlerOne?.firstName || "",
                bowlerOne?.lastName || "",
              ),
              runs: bowlers[0]?.runs,
              wickets: bowlers[0]?.wickets,
              overs: bowlers[0]?.overs,
            },
            playerTwo: {
              id: bowlers[1]?.playerId,
              name:
                structurePlayerName(
                  bowlerTwo?.firstName || "",
                  bowlerTwo?.lastName || "",
                ) || "",
              runs: bowlers[1]?.runs,
              wickets: bowlers[1]?.wickets,
              overs: bowlers[1]?.overs,
            },
          },
        })
      }
    }
  }, [
    innings?.homeScores.activeBatsman,
    innings?.guestScores.activeBatsman,
    matchPlayers?.data,
    scores?.data,
  ])

  return (
    <div
      className="score-card"
      onClick={() => {
        if (OnClick) OnClick()
      }}
    >
      <div className="title-c">
        <h2>{match?.title}</h2>
        <h3>{innings?.name}</h3>
      </div>

      <div className="schools-c">
        <div className="school">
          <img src={homeSchool?.image} />

          <div className="data-c">
            <div className="status">
              {innings?.homeBatting ? "Batting" : "Bowling"}
            </div>

            <div className="main-data-c">
              <div className="main-data">
                {String(innings?.homeScores.runs)}/
                {String(innings?.homeScores.wickets)}
              </div>

              <div className="minor-data">
                ({overSafeBall(innings?.homeScores.overs || 0)})
              </div>
            </div>
          </div>
        </div>

        <div className="school guest">
          <div className="data-c">
            <div className="status">
              {innings?.homeBatting ? "Bowling" : "Batting"}
            </div>

            <div className="main-data-c">
              <div className="main-data">
                {String(innings?.guestScores.runs)}/
                {String(innings?.guestScores.wickets)}
              </div>

              <div className="minor-data">
                ({overSafeBall(innings?.guestScores.overs || 0)})
              </div>
            </div>
          </div>

          <img src={guestSchool?.image} />
        </div>
      </div>

      <div className="player-data">
        <div className="home">
          {!playerData ? (
            <div className="loader"></div>
          ) : (
            playerData?.home && (
              <>
                {playerData.home?.playerOne && (
                  <div className="player-row">
                    <div className="scores-d">
                      {playerData.home?.playerOne?.name}{" "}
                      {playerData.home?.playerOne?.runs}
                      {innings?.homeBatting
                        ? ""
                        : "/" + playerData.home?.playerOne?.wickets}{" "}
                      (
                      {innings?.homeBatting
                        ? playerData.home.playerOne?.balls
                        : playerData.home.playerOne?.overs}
                      )
                    </div>

                    {playerData.home.playerOne?.striker && (
                      <div className="star">*</div>
                    )}
                  </div>
                )}

                {playerData.home.playerTwo && (
                  <div className="player-row">
                    <div className="scores-d">
                      {playerData.home.playerTwo?.name}{" "}
                      {playerData.home.playerTwo?.runs}
                      {innings?.homeBatting
                        ? ""
                        : "/" + playerData.home.playerTwo?.wickets}{" "}
                      (
                      {innings?.homeBatting
                        ? playerData.home.playerTwo?.balls
                        : playerData.home.playerTwo?.overs}
                      )
                    </div>

                    {playerData.home.playerTwo?.striker && (
                      <div className="star">*</div>
                    )}
                  </div>
                )}
              </>
            )
          )}
        </div>

        <div className="guest">
          {!playerData ? (
            <div className="loader"></div>
          ) : (
            playerData?.guest && (
              <>
                {playerData.guest.playerOne && (
                  <div className="player-row">
                    <div className="scores-d">
                      {playerData.guest.playerOne?.name}{" "}
                      {playerData.guest.playerOne?.runs}
                      {!innings?.homeBatting
                        ? ""
                        : "/" + playerData.guest.playerOne?.wickets}{" "}
                      (
                      {!innings?.homeBatting
                        ? playerData.guest.playerOne?.balls
                        : playerData.guest.playerOne?.overs}
                      )
                    </div>

                    {playerData.guest.playerOne?.striker && (
                      <div className="star">*</div>
                    )}
                  </div>
                )}

                {playerData.guest.playerTwo && (
                  <div className="player-row">
                    <div className="scores-d">
                      {playerData.guest.playerTwo?.name}{" "}
                      {playerData.guest.playerTwo?.runs}
                      {!innings?.homeBatting
                        ? ""
                        : "/" + playerData.guest.playerTwo?.wickets}{" "}
                      (
                      {!innings?.homeBatting
                        ? playerData.guest.playerTwo?.balls
                        : playerData.guest.playerTwo?.overs}
                      )
                    </div>

                    {playerData.guest.playerTwo?.striker && (
                      <div className="star">*</div>
                    )}
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>

      <div className="gradient" />
    </div>
  )
}
