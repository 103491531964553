import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../../../app/hooks"
import "./RugbyMatchDashboard.css"
import { useEffect, useState } from "react"
import { changeHeader } from "../../../../features/client/pageHeader"

import { RugbyMatch, Schools } from "../../../../app/types"
import {
  useGetMatchesQuery,
  useUpdateRugbyMatchScoresMutation,
} from "../../../../features/firebase/subSlices/matches/matchesSlice"
import StatusCard from "../../../../components/MatchCard/StatusCard/StatusCard"
import { useGetSchoolsQuery } from "../../../../features/firebase/subSlices/schoolsSlice"
import InfoChip from "../../../../components/MatchCard/InfoChip/InfoChip"
import DefaultButton from "../../../../components/Buttons/DefaultButton/DefaultButton"
import PointCounter from "../../../../components/PointCounter/PointCounter"
import { useGetPlayersQuery } from "../../../../features/firebase/subSlices/matches/playersSlice"
import DropDown from "../../../../components/DropDown/DropDown"
import { useAddRugbyEventMutation } from "../../../../features/firebase/subSlices/matches/cricket/eventSlice"

export default function RugbyMatchDashboard() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()
  const schools = useGetSchoolsQuery()

  const matchPlayers =
    params.matchId !== undefined ? useGetPlayersQuery(params.matchId) : null

  const [updateRugbyMatchScores] = useUpdateRugbyMatchScoresMutation()
  const [addRugbyEvent] = useAddRugbyEventMutation()

  const [match, setMatch] = useState<null | RugbyMatch>(null)

  const [homeSchool, setHomeSchool] = useState<null | Schools[0]>(null)
  const [guestSchool, setGuestSchool] = useState<null | Schools[0]>(null)

  const [homeEventType, setHomeEventType] = useState<
    "conversion" | "try" | "penalty" | "dropGoal" | null
  >(null)
  const [homePlayer, setHomePlayer] = useState<string>("")
  const [homeEvent, setHomeEvent] = useState<string | null>(null)

  const [guestEventType, setGuestEventType] = useState<
    "conversion" | "try" | "penalty" | "dropGoal" | null
  >(null)
  const [guestPlayer, setGuestPlayer] = useState<string>("")
  const [guestEvent, setGuestEvent] = useState<string | null>(null)

  useEffect(() => {
    if (params.matchId) {
      setMatch(
        (matches.data?.find(
          (match: any) => match.id === params.matchId,
        ) as RugbyMatch) || null,
      )
    } else {
      navigate("/matches")
    }
  }, [params.matchId, matches.data, navigate])

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Match Dashboard",
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (schools.data) {
      setHomeSchool(
        schools.data.find((school: any) => school.id === match?.homeSchool) ||
          null,
      )
      setGuestSchool(
        schools.data.find((school: any) => school.id === match?.guestSchool) ||
          null,
      )
    }
  }, [schools.data, match?.homeSchool, match?.guestSchool])

  return (
    <div className="match-dashboard-page">
      <div className="top-c card-bg">
        <div className="top-c-c">
          <div>
            <div className="title">{match?.title}</div>
            <div className="sub-title">{match?.subText}</div>
          </div>

          <div className="chips">
            <StatusCard
              matchStatus={match?.matchStatus || "upcoming"}
              homeSchool={match?.homeSchool || ""}
              guestSchool={match?.guestSchool || ""}
              colors={{
                home: homeSchool?.color || "",
                guest: guestSchool?.color || "",
              }}
            />

            <InfoChip
              active={match?.infoChip.active || false}
              text={match?.infoChip.text || ""}
              icon={match?.infoChip.icon || ""}
            />
          </div>
        </div>

        <div className="schools-c">
          <div className="school">
            <img src={homeSchool?.image} />

            <div className="data-c">
              <div className="score-table">
                <div className="header">C</div>
                <div className="header">T</div>
                <div className="header">P</div>
                <div className="header">D</div>
                <div
                  className="header"
                  style={{
                    borderRight: "none",
                  }}
                >
                  Total
                </div>
                <div>{String(match?.homeScores?.conversion) || "-"}</div>
                <div>{String(match?.homeScores?.try) || "-"}</div>
                <div>{String(match?.homeScores?.penalty) || "-"}</div>
                <div>{String(match?.homeScores?.dropGoal) || "-"}</div>

                <div
                  style={{
                    borderRight: "none",
                    opacity: 1,
                  }}
                >
                  {match?.homeScores?.conversion !== undefined &&
                  match?.homeScores?.try !== undefined &&
                  match?.homeScores?.penalty !== undefined &&
                  match?.homeScores?.dropGoal !== undefined
                    ? match?.homeScores?.conversion * 2 +
                      match?.homeScores?.try * 5 +
                      match?.homeScores?.penalty * 3 +
                      match?.homeScores?.dropGoal * 3
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          <div className="spacer" />

          <div className="school guest">
            <div className="data-c">
              <div className="score-table">
                <div className="header">C</div>
                <div className="header">T</div>
                <div className="header">P</div>
                <div className="header">D</div>
                <div
                  className="header"
                  style={{
                    borderRight: "none",
                  }}
                >
                  Total
                </div>
                <div>{String(match?.guestScores?.conversion) || "-"}</div>
                <div>{String(match?.guestScores?.try) || "-"}</div>
                <div>{String(match?.guestScores?.penalty) || "-"}</div>
                <div>{String(match?.guestScores?.dropGoal) || "-"}</div>

                <div
                  style={{
                    borderRight: "none",
                    opacity: 1,
                  }}
                >
                  {match?.guestScores?.conversion !== undefined &&
                  match?.guestScores?.try !== undefined &&
                  match?.guestScores?.penalty !== undefined &&
                  match?.guestScores?.dropGoal !== undefined
                    ? match?.guestScores?.conversion * 2 +
                      match?.guestScores?.try * 5 +
                      match?.guestScores?.penalty * 3 +
                      match?.guestScores?.dropGoal * 3
                    : "-"}
                </div>
              </div>
            </div>

            <img src={guestSchool?.image} />
          </div>
        </div>
      </div>

      <div className="btm-c">
        <div className="updater-card card-bg">
          <div className="school">
            <img src={homeSchool?.image} />
            <div>
              <div className="title">{homeSchool?.name}</div>
              <div className="sub-title">{homeSchool?.city}</div>
            </div>
          </div>

          <div className="point-counter-div">
            <PointCounter
              label="C"
              value={match?.homeScores?.conversion || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: true,
                  scoreType: "conversion",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.homeScores.conversion !== undefined &&
                  match?.homeScores?.conversion > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: true,
                    scoreType: "conversion",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="T"
              value={match?.homeScores?.try || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: true,
                  scoreType: "try",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.homeScores.try !== undefined &&
                  match?.homeScores?.try > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: true,
                    scoreType: "try",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="P"
              value={match?.homeScores?.penalty || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: true,
                  scoreType: "penalty",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.homeScores.penalty !== undefined &&
                  match?.homeScores?.penalty > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: true,
                    scoreType: "penalty",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="D"
              value={match?.homeScores?.dropGoal || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: true,
                  scoreType: "dropGoal",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.homeScores.dropGoal !== undefined &&
                  match?.homeScores?.dropGoal > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: true,
                    scoreType: "dropGoal",
                    isIncrement: false,
                  })
                }
              }}
            />
          </div>

          <hr />

          <div className="event-row">
            <div className="event-btn-c">
              <DefaultButton
                onClick={() => {
                  setHomeEventType("conversion")
                }}
                disabled={
                  homeEventType !== "conversion" && homeEventType !== null
                }
              >
                C
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setHomeEventType("try")
                }}
                disabled={homeEventType !== "try" && homeEventType !== null}
              >
                T
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setHomeEventType("penalty")
                }}
                disabled={homeEventType !== "penalty" && homeEventType !== null}
              >
                P
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setHomeEventType("dropGoal")
                }}
                disabled={
                  homeEventType !== "dropGoal" && homeEventType !== null
                }
              >
                D
              </DefaultButton>
            </div>

            <div>
              <DropDown
                value={homePlayer}
                setValue={setHomePlayer}
                placeHolder="Select Player"
                values={
                  matchPlayers?.data?.home.map((player) => {
                    return {
                      label:
                        player.number +
                        " | " +
                        player.firstName[0].toUpperCase() +
                        " " +
                        player.lastName,
                      value: player.id,
                    }
                  }) || []
                }
                disabled={homeEventType === null}
              />

              <div className="event-btn-row">
                <DefaultButton
                  onClick={() => {
                    setHomeEventType(null)
                    setHomePlayer("")
                    setHomeEvent(null)
                  }}
                >
                  Clear
                </DefaultButton>
                <DefaultButton
                  onClick={async () => {
                    //  TODO: Add event

                    addRugbyEvent({
                      matchId: match?.id || "",
                      isHome: true,
                      eventType: homeEventType || "",
                      playerId: homePlayer,
                    })

                    setHomeEventType(null)
                    setHomePlayer("")
                  }}
                  disabled={homeEventType === null || homePlayer === ""}
                >
                  Add
                </DefaultButton>
              </div>
            </div>
          </div>

          <hr />

          <div className="event-c">
            <DefaultButton
              onClick={() => {
                setHomeEvent("line-out")
              }}
              disabled={homeEvent !== null && homeEvent !== "line-out"}
            >
              Line out
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setHomeEvent("in-touch")
              }}
              disabled={homeEvent !== null && homeEvent !== "in-touch"}
            >
              In touch
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setHomeEvent("conversion-lost")
              }}
              disabled={homeEvent !== null && homeEvent !== "conversion-lost"}
            >
              Conversion lost
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setHomeEvent("penalty-conceded")
              }}
              disabled={homeEvent !== null && homeEvent !== "penalty-conceded"}
            >
              Penalty conceded
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setHomeEvent("penalty-lost")
              }}
              disabled={homeEvent !== null && homeEvent !== "penalty-lost"}
            >
              Penalty lost
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                // TODO: Add event

                addRugbyEvent({
                  matchId: match?.id || "",
                  isHome: true,
                  eventType: homeEvent || "",
                })

                setHomeEvent(null)
              }}
            >
              <div>Update</div>
              <div className="material-symbols-rounded">send</div>
            </DefaultButton>
          </div>
        </div>

        <div className="updater-card card-bg">
          <div className="school">
            <img src={guestSchool?.image} />
            <div>
              <div className="title">{guestSchool?.name}</div>
              <div className="sub-title">{guestSchool?.city}</div>
            </div>
          </div>

          <div className="point-counter-div">
            <PointCounter
              label="C"
              value={match?.guestScores?.conversion || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: false,
                  scoreType: "conversion",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.guestScores.conversion !== undefined &&
                  match?.guestScores?.conversion > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: false,
                    scoreType: "conversion",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="T"
              value={match?.guestScores?.try || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: false,
                  scoreType: "try",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.guestScores.try !== undefined &&
                  match?.guestScores?.try > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: false,
                    scoreType: "try",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="P"
              value={match?.guestScores?.penalty || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: false,
                  scoreType: "penalty",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.guestScores.penalty !== undefined &&
                  match?.guestScores?.penalty > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: false,
                    scoreType: "penalty",
                    isIncrement: false,
                  })
                }
              }}
            />

            <PointCounter
              label="D"
              value={match?.guestScores?.dropGoal || 0}
              onIncrement={async () => {
                await updateRugbyMatchScores({
                  id: match?.id || "",
                  isHome: false,
                  scoreType: "dropGoal",
                  isIncrement: true,
                })
              }}
              onDecrement={async () => {
                if (
                  match?.guestScores.dropGoal !== undefined &&
                  match?.guestScores?.dropGoal > 0
                ) {
                  await updateRugbyMatchScores({
                    id: match?.id || "",
                    isHome: false,
                    scoreType: "dropGoal",
                    isIncrement: false,
                  })
                }
              }}
            />
          </div>

          <hr />

          <div className="event-row">
            <div className="event-btn-c">
              <DefaultButton
                onClick={() => {
                  setGuestEventType("conversion")
                }}
                disabled={
                  guestEventType !== "conversion" && guestEventType !== null
                }
              >
                C
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setGuestEventType("try")
                }}
                disabled={guestEventType !== "try" && guestEventType !== null}
              >
                T
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setGuestEventType("penalty")
                }}
                disabled={
                  guestEventType !== "penalty" && guestEventType !== null
                }
              >
                P
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  setGuestEventType("dropGoal")
                }}
                disabled={
                  guestEventType !== "dropGoal" && guestEventType !== null
                }
              >
                D
              </DefaultButton>
            </div>

            <div>
              <DropDown
                value={guestPlayer}
                setValue={setGuestPlayer}
                placeHolder="Select Player"
                values={
                  matchPlayers?.data?.guest.map((player) => {
                    return {
                      label:
                        player.number +
                        " | " +
                        player.firstName[0].toUpperCase() +
                        " " +
                        player.lastName,
                      value: player.id,
                    }
                  }) || []
                }
                disabled={guestEventType === null}
              />

              <div className="event-btn-row">
                <DefaultButton
                  onClick={() => {
                    setGuestEventType(null)
                    setGuestPlayer("")
                    setGuestEvent(null)
                  }}
                >
                  Clear
                </DefaultButton>
                <DefaultButton
                  onClick={async () => {
                    //  TODO: Add event

                    addRugbyEvent({
                      matchId: match?.id || "",
                      isHome: false,
                      eventType: guestEventType || "",
                      playerId: guestPlayer,
                    })

                    setGuestEventType(null)
                    setGuestPlayer("")
                  }}
                  disabled={guestEventType === null || guestPlayer === ""}
                >
                  Add
                </DefaultButton>
              </div>
            </div>
          </div>

          <hr />

          <div className="event-c">
            <DefaultButton
              onClick={() => {
                setGuestEvent("line-out")
              }}
              disabled={guestEvent !== null && guestEvent !== "line-out"}
            >
              Line out
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setGuestEvent("in-touch")
              }}
              disabled={guestEvent !== null && guestEvent !== "in-touch"}
            >
              In touch
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setGuestEvent("conversion-lost")
              }}
              disabled={guestEvent !== null && guestEvent !== "conversion-lost"}
            >
              Conversion lost
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setGuestEvent("penalty-conceded")
              }}
              disabled={
                guestEvent !== null && guestEvent !== "penalty-conceded"
              }
            >
              Penalty conceded
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setGuestEvent("penalty-lost")
              }}
              disabled={guestEvent !== null && guestEvent !== "penalty-lost"}
            >
              Penalty lost
            </DefaultButton>

            <DefaultButton
              onClick={() => {
                // TODO: Add event

                addRugbyEvent({
                  matchId: match?.id || "",
                  isHome: false,
                  eventType: guestEvent || "",
                })

                setGuestEvent(null)
              }}
            >
              <div>Update</div>
              <div className="material-symbols-rounded">send</div>
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  )
}
