import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { useAppDispatch } from "../../app/hooks"

import { selectUser } from "../../features/userSlice"
import {
  useEditSchoolMutation,
  useGetSchoolsQuery,
} from "../../features/firebase/subSlices/schoolsSlice"
import { showPopup } from "../../features/client/popupSlice"
import { changeHeader } from "../../features/client/pageHeader"

import NewItemPageContainer from "../../components/NewItemPageContainer/NewItemPageContainer"
import InputField from "../../components/InputField/InputField"
import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton"
import DropArea from "../../components/DropArea/DropArea"

import "./EditSchool.css"

export default function EditSchool() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useSelector(selectUser)

  const schools = useGetSchoolsQuery()

  const params = useParams()

  const [editSchool] = useEditSchoolMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Edit School",
      }),
    )
  }, [dispatch])

  const [name, setName] = useState("")
  const [city, setCity] = useState("")
  const [color, setColor] = useState("#000000")

  const [file, setFile] = useState<File | null>(null)

  const [image, setImage] = useState("")

  useEffect(() => {
    if (!schools.isLoading && schools.data !== undefined) {
      const school = schools.data.find(
        (school) => school.id === params.schoolId,
      )

      if (school) {
        setName(school.name)
        setCity(school.city)
        setColor(`#${school.color}`)
        setImage(school.image)
      } else {
        navigate("/dashboard")
      }
    }
  }, [schools, params.schoolId])

  const [loading, setLoading] = useState(false)

  return (
    <NewItemPageContainer
      className="add-school-page edit-school"
      title={`Edit ${name}'s Details`}
    >
      <div className="input-container">
        <InputField
          title="Name"
          name="name"
          placeholder="Enter Name"
          value={name}
          setValue={setName}
        />

        <InputField
          title="City"
          name="city"
          placeholder="Enter City"
          value={city}
          setValue={setCity}
        />

        <div>Crest</div>

        <div className="drop-container">
          {!file && (
            <div
              className="img-preview"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          )}
          <DropArea value={file} setValue={setFile} />
        </div>
      </div>

      <div className="btn-container">
        <InputField
          title="School Color"
          name="color"
          type="color"
          value={color}
          setValue={setColor}
        />

        {user.admin && (
          <DefaultButton
            color="rgba(150, 29, 87, 0.7)"
            hoverColor="rgba(150, 29, 87, 0.5)"
            onClick={async () => {
              dispatch(
                showPopup({
                  title: "Delete School",
                  message: `Are You sure to delete school ${name}? This action cannot be undone.`,
                  buttons: [
                    {
                      title: "Delete",
                      action: "delete-school",
                      data: params.schoolId,
                      loading: false,
                    },
                    {
                      title: "Cancel",
                      action: "close",
                      loading: false,
                    },
                  ],
                }),
              )
            }}
          >
            <span className="material-symbols-rounded">delete_forever</span>
            <span className="text">Delete</span>
          </DefaultButton>
        )}

        <DefaultButton
          onClick={() => {
            navigate("/dashboard")
          }}
        >
          <span className="material-symbols-rounded">delete</span>
          <span className="text">Discard</span>
        </DefaultButton>

        <DefaultButton
          color="rgba(14, 67, 131, 0.7)"
          hoverColor="rgba(14, 67, 131, 0.5)"
          onClick={async () => {
            if (!loading) {
              const originalSchool = schools.data?.find(
                (school) => school.id === params.schoolId,
              )

              setLoading(true)

              await editSchool({
                id: params.schoolId,
                name: name === originalSchool?.name ? undefined : name,
                city: city === originalSchool?.city ? undefined : city,
                color:
                  color === originalSchool?.color
                    ? undefined
                    : color.replaceAll("#", ""),
                image: file,
              })

              navigate("/dashboard")
            }
          }}
        >
          {loading ? (
            <span className="loader" />
          ) : (
            <>
              <span className="material-symbols-rounded">edit</span>
              <span className="text">Edit School</span>
            </>
          )}
        </DefaultButton>
      </div>
    </NewItemPageContainer>
  )
}
