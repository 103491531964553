import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Match } from "../../app/types"

import { useGetMatchesQuery } from "../../features/firebase/subSlices/matches/matchesSlice"

import CricketEventCard from "./CricketEventCard/CricketEventCard"

export default function EventCard({
  commentaryFocused = true,
  setSelectValue,
}: {
  commentaryFocused?: boolean
  selectValue?: {
    action: "edit" | "delete" | "comment" | "add"
    id: string
  } | null
  setSelectValue?: React.Dispatch<
    React.SetStateAction<{
      action: "edit" | "delete" | "comment" | "add"
      id: string
    } | null>
  >
}) {
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()

  const [match, setMatch] = useState<null | Match>(null)

  useEffect(() => {
    if (params.matchId) {
      setMatch(
        matches.data?.find((match: any) => match.id === params.matchId) || null,
      )
    } else {
      navigate("/matches")
    }
  }, [params.matchId, matches.data])

  return match && match.matchType === "cricket" ? (
    <CricketEventCard
      commentaryFocused={commentaryFocused}
      setSelectValue={setSelectValue}
    />
  ) : (
    <></>
  )
}
