import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore"

import { db } from "../../../app/firebase"
import { toaster } from "../../../app/toaster"
import { Settings } from "../../../app/types"

import { firebaseSlice } from "../firebaseSlice"

export const extendedSettingsSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<Settings, void>({
      async queryFn(): Promise<any> {
        try {
          const docRef = doc(db, "app", "settings")
          const docSnap = await getDoc(docRef)

          if (docSnap.exists()) {
            const data = docSnap.data() as Settings

            if (!data.maintenanceMode) {
              toaster.error(
                "Firestore Structure Error",
                "Missing [app -> settings -> maintenanceMode] field",
              )
            } else if (
              data.maintenanceMode !== "none" &&
              data.maintenanceMode !== "temp" &&
              data.maintenanceMode !== "static"
            ) {
              toaster.error(
                "Firestore Structure Error",
                "Invalid data in [app -> settings -> maintenanceMode] field",
              )
              throw new Error("Invalid data maintenance mode field")
            }

            if (!data.matchOnHome) {
              toaster.error(
                "Firestore Structure Error",
                "Missing [app -> settings -> matchOnHome] field",
              )
            } else if (typeof data.matchOnHome !== "string") {
              toaster.error(
                "Firestore Structure Error",
                "Invalid data type in [app -> settings -> matchOnHome] field",
              )
            }

            return {
              data,
            }
          } else {
            toaster.error(
              "Firestore Structure Error",
              "Can't find [app -> settings] document in Firebase Firestore",
            )
            console.error("Can't find the `app/settings` document in firebase!")
            return {
              error: "Can't find the `app/settings` document in firebase!",
            }
          }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: ["Settings"],
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            doc(db, "app", "settings"),
            { includeMetadataChanges: false },
            (doc) => {
              const data = doc.data() as Settings

              if (
                data.maintenanceMode === "temp" ||
                data.maintenanceMode === "static"
              ) {
                toaster.warning(
                  "Maintenance Mode is Active",
                  "The app is in maintenance mode. Users may not be able to access the app properly.",
                )
              }

              updateCachedData((draft) => {
                Object.assign(draft, data)
              })
            },
          )
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to get settings")
        }

        await cacheEntryRemoved
      },
    }),

    updateSettings: builder.mutation({
      // Call this function like this:
      //   setSettings({ field: "maintenanceMode", data: "temp" })
      //   setSettings({ field: "matchOnHome", data: "random_match_id" })

      async queryFn({ field, data }) {
        try {
          if (field !== "maintenanceMode" && field !== "matchOnHome") {
            toaster.error("Firestore Structure Error", "Invalid field name")
            throw new Error("Invalid field name")
          }

          if (
            field === "maintenanceMode" &&
            data !== "none" &&
            data !== "temp" &&
            data !== "static"
          ) {
            toaster.error(
              "Invalid Data for [maintenanceMode] field]",
              "Invalid maintenance mode",
            )
            throw new Error("Invalid maintenance mode")
          }

          if (field === "matchOnHome" && typeof data !== "string") {
            toaster.error(
              "Invalid Data for [matchOnHome] field]",
              "Invalid match ID",
            )
            throw new Error("Invalid match id")
          }

          const docRef = doc(db, "app", "settings")

          await updateDoc(
            docRef,
            field === "maintenanceMode"
              ? { maintenanceMode: data }
              : { matchOnHome: data },
          )

          toaster.success(
            "Settings Updated",
            `Updated ${field} field to [${data}]`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update settings")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["Settings"],
    }),

    getHomeImage: builder.query<void, void>({
      async queryFn() {
        try {
          const docRef = doc(db, "app", "settings")
          const docSnap = await getDoc(docRef)

          if (docSnap.exists()) {
            const data = docSnap.data() as Settings

            if (data.matchOnHome) {
              const matchDocRef = doc(db, "matches-prod", data.matchOnHome)
              const matchDocSnap = await getDoc(matchDocRef)

              if (matchDocSnap.exists()) {
                const matchData = matchDocSnap.data()

                if (matchData?.image) {
                  return {
                    data: matchData.image,
                  }
                } else {
                  toaster.error(
                    "Firestore Structure Error",
                    "Missing [matches -> homeImage] field",
                  )
                  return {
                    error: "Missing `matches/homeImage` field in firebase!",
                  }
                }
              } else {
                toaster.error(
                  "Firestore Structure Error",
                  "Can't find [matches -> homeImage] document in Firebase Firestore",
                )
                console.error(
                  "Can't find the `matches/homeImage` document in firebase!",
                )
                return {
                  error:
                    "Can't find the `matches/homeImage` document in firebase!",
                }
              }
            } else {
              toaster.error(
                "Firestore Structure Error",
                "Missing [app -> settings -> matchOnHome] field",
              )
              return {
                error: "Missing `app/settings/matchOnHome` field in firebase!",
              }
            }
          } else {
            toaster.error(
              "Firestore Structure Error",
              "Can't find [app -> settings] document in Firebase Firestore",
            )
            console.error("Can't find the `app/settings` document in firebase!")
            return {
              error: "Can't find the `app/settings` document in firebase!",
            }
          }
        } catch (error: any) {
          console.error(error)
          return error.message
        }
      },
    }),
  }),
})

export const {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetHomeImageQuery,
} = extendedSettingsSlice

export const selectSettings =
  extendedSettingsSlice.endpoints.getSettings.select()
