import { Dispatch, SetStateAction } from "react"

import "./Toggle.css"

export default function Toggle({
  active,
  setActive,
  color = "#b7095db3",
}: {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  color?: string
}) {
  return (
    <div
      className="toggle"
      onClick={() => {
        setActive((preVal) => !preVal)
      }}
      style={{
        backgroundColor: active ? color : "#ffffff5f",
      }}
    >
      <div
        className="slider"
        style={{
          transform: active
            ? "translateX(var(--slider-value))"
            : "translateX(0)",
        }}
      />
    </div>
  )
}
