import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch } from "../../../app/hooks"

import { changeHeader } from "../../../features/client/pageHeader"
import {
  useAddCommentaryEventMutation,
  useEditCommentaryEventMutation,
  useGetEventsQuery,
} from "../../../features/firebase/subSlices/matches/cricket/eventSlice"
import { showPopup } from "../../../features/client/popupSlice"

import "./Commentary.css"

import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import EventCard from "../../../components/EventCard/EventCard"
import InputField from "../../../components/InputField/InputField"
import ColorButton from "../../../components/Buttons/ColorButton/ColorButton"

export default function Commentary() {
  const dispatch = useAppDispatch()
  const params = useParams()

  const events =
    params.matchId === undefined ? null : useGetEventsQuery(params.matchId)

  const [addCommentaryEvent] = useAddCommentaryEventMutation()
  const [editCommentaryEvent] = useEditCommentaryEventMutation()

  const [comment, setComment] = useState("")
  const [editComment, setEditComment] = useState("")
  const [ballComment, setBallComment] = useState("")

  const [selectedCommentInEvents, setSelectedCommentInEvents] = useState<{
    action: "edit" | "delete" | "comment" | "add"
    id: string
  } | null>(null)

  const [commentLoading, setCommentLoading] = useState(false)
  const [editCommentLoading, setEditCommentLoading] = useState(false)
  const [ballCommentLoading, setBallCommentLoading] = useState(false)

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Commentary",
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (selectedCommentInEvents !== null) {
      if (selectedCommentInEvents.action === "edit") {
        setEditComment(
          events?.data?.find((event) => event.id === selectedCommentInEvents.id)
            ?.comment || "",
        )
      } else if (selectedCommentInEvents.action === "comment") {
        setBallComment(
          events?.data?.find((event) => event.id === selectedCommentInEvents.id)
            ?.comment || "",
        )
      } else if (selectedCommentInEvents.action === "delete") {
        setEditComment("")
        setSelectedCommentInEvents(null)

        dispatch(
          showPopup({
            title: "Delete Commentary",
            message: "Are you sure you want to delete this commentary?",
            buttons: [
              {
                action: "delete-event",
                title: "Delete Comment",
                data: params.matchId + "---" + selectedCommentInEvents.id,
                loading: false,
              },
              {
                title: "Cancel",
                action: "close",
                loading: false,
              },
            ],
          }),
        )
      }
    }
  }, [selectedCommentInEvents])

  return (
    <div className="commentary-page">
      <EventCard
        selectValue={selectedCommentInEvents}
        setSelectValue={setSelectedCommentInEvents}
      />

      <div className="main-g">
        <div className="add-commentary-card card-bg add">
          <h2>Add Comment</h2>

          <InputField
            value={comment}
            setValue={setComment}
            type="textarea"
            placeholder="Enter Comment"
            name="commentary"
            title="Comment"
            // textareaCharLimit={200}
          />

          <ColorButton
            onClick={async () => {
              if (!commentLoading) {
                setCommentLoading(true)

                await addCommentaryEvent({ matchId: params.matchId, comment })
                setComment("")
                setEditComment("")

                setSelectedCommentInEvents(null)

                setCommentLoading(false)
              }
            }}
          >
            <>
              <div className="material-symbols-rounded">comment</div>
              <div className="text">Comment</div>
            </>
          </ColorButton>
        </div>

        <div className="card-c">
          <div
            className="add-commentary-card card-bg"
            style={{
              filter:
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "edit"
                  ? "grayscale(1)"
                  : "none",
              opacity:
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "edit"
                  ? 0.3
                  : 1,
            }}
          >
            <h2>Edit Comment</h2>

            <InputField
              value={editComment}
              setValue={setEditComment}
              type="textarea"
              placeholder="Enter Comment"
              name="commentary"
              title="Comment"
              // textareaCharLimit={200}
              disabled={
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "edit"
              }
            />

            <ColorButton
              onClick={async () => {
                if (!editCommentLoading && selectedCommentInEvents !== null) {
                  setEditCommentLoading(true)

                  await editCommentaryEvent({
                    matchId: params.matchId,
                    eventId: selectedCommentInEvents.id,
                    comment: editComment,
                  })

                  setComment("")
                  setEditComment("")
                  setBallComment("")
                  setSelectedCommentInEvents(null)

                  setEditCommentLoading(false)
                }
              }}
            >
              {editCommentLoading ? (
                <span className="loader" />
              ) : (
                <>
                  <div className="material-symbols-rounded">edit</div>
                  <div className="text">Edit Comment</div>
                </>
              )}
            </ColorButton>

            <DefaultButton
              onClick={() => {
                if (selectedCommentInEvents !== null) {
                  setEditComment("")
                  setSelectedCommentInEvents(null)
                }
              }}
            >
              <div className="material-symbols-rounded">delete</div>
              <div className="text">Discard</div>
            </DefaultButton>
          </div>

          <div
            className="add-commentary-card card-bg"
            style={{
              filter:
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "comment"
                  ? "grayscale(1)"
                  : "none",
              opacity:
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "comment"
                  ? 0.3
                  : 1,
            }}
          >
            <h2>Add Ball Specific Comment</h2>

            <InputField
              value={ballComment}
              setValue={setBallComment}
              type="textarea"
              placeholder="Enter Ball Specific Comment"
              name="commentary-ball"
              title="Comment"
              // textareaCharLimit={200}
              disabled={
                selectedCommentInEvents === null ||
                selectedCommentInEvents.action !== "comment"
              }
            />

            <ColorButton
              onClick={async () => {
                if (!editCommentLoading && selectedCommentInEvents !== null) {
                  setBallCommentLoading(true)

                  await editCommentaryEvent({
                    matchId: params.matchId,
                    eventId: selectedCommentInEvents.id,
                    comment: ballComment,
                    ballComment: true,
                  })

                  setComment("")
                  setEditComment("")
                  setBallComment("")
                  setSelectedCommentInEvents(null)

                  setBallCommentLoading(false)
                }
              }}
            >
              {ballCommentLoading ? (
                <span className="loader" />
              ) : (
                <>
                  <div className="material-symbols-rounded">edit</div>
                  <div className="text">Edit Comment</div>
                </>
              )}
            </ColorButton>

            <DefaultButton
              onClick={() => {
                if (selectedCommentInEvents !== null) {
                  setEditComment("")
                  setSelectedCommentInEvents(null)
                }
              }}
            >
              <div className="material-symbols-rounded">delete</div>
              <div className="text">Discard</div>
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  )
}
