import React, { Dispatch } from "react"
import { useParams } from "react-router-dom"

import { CricketMatchEvents } from "../../../app/types"
import { getFormattedDate, overSafeBall } from "../../../app/functions"

import {
  useGetEventsQuery,
  useRefetchEventsMutation,
} from "../../../features/firebase/subSlices/matches/cricket/eventSlice"

import "./CricketEventCard.css"

import DefaultButton from "../../Buttons/DefaultButton/DefaultButton"
import { useGetInningsQuery } from "../../../features/firebase/subSlices/matches/cricket/inningsSlice"

export default function CricketEventCard({
  commentaryFocused = true,
  setSelectValue,
}: {
  commentaryFocused?: boolean
  selectValue?: {
    action: "edit" | "delete" | "comment" | "add"
    id: string
  } | null
  setSelectValue?: Dispatch<
    React.SetStateAction<{
      action: "edit" | "delete" | "comment" | "add"
      id: string
    } | null>
  >
}) {
  const params = useParams()

  const innings =
    params.matchId === undefined ? null : useGetInningsQuery(params.matchId)

  const events =
    params.matchId === undefined ? null : useGetEventsQuery(params.matchId)

  const [refetchMatchesMutation] = useRefetchEventsMutation()

  function getBallsInOver(currentEvent: CricketMatchEvents[0]) {
    return events?.data?.filter(
      (event: CricketMatchEvents[0]) =>
        "ball" in event &&
        "ball" in currentEvent &&
        ((Math.floor(currentEvent.ball) - 1 !== event.ball &&
          Math.floor(event.ball) === Math.floor(currentEvent.ball) - 1) ||
          event.ball === Math.floor(currentEvent.ball)) &&
        event.innings === currentEvent.innings &&
        event.homeBatting === currentEvent.homeBatting,
    )
  }

  function getLatestBallEventByIndex(index: number) {
    if (events?.data === undefined) return null

    for (let i = index + 1; i < events.data.length; i++) {
      if (!events.data[i].isCommentary) {
        return [events.data[i]]
      }
    }
  }

  function checkLastBallExists(index: number) {
    if (events?.data === undefined) return false

    const currentEvent = events.data[index]

    if (!("innings" in currentEvent && "homeBatting" in currentEvent)) {
      return false
    }

    // const lastEvent = events.data[index + 1]
    const lastEvent = events.data.filter(
      (eventLocal) =>
        "innings" in eventLocal &&
        "homeBatting" in eventLocal &&
        !eventLocal.isCommentary &&
        eventLocal.innings === currentEvent.innings &&
        eventLocal.homeBatting === currentEvent.homeBatting &&
        eventLocal.ball !== currentEvent.ball &&
        eventLocal.ball.toFixed(1) === overSafeBall(currentEvent.ball - 0.1),
    )[0]

    if ("ball" in currentEvent && currentEvent.ball === 0.1) {
      return true
    }

    if (lastEvent && "ball" in lastEvent && "ball" in currentEvent) {
      return lastEvent.ball.toFixed(1) === overSafeBall(currentEvent.ball - 0.1)
    }

    return false
  }

  return (
    <div className="event-c-c">
      <div className="card-bg cricket-events-card">
        <DefaultButton
          onClick={() => {
            refetchMatchesMutation({
              matchId: params.matchId || "",
            })
          }}
        >
          <span className="material-symbols-rounded b-1">refresh</span>
          <span>Refetch Events</span>
        </DefaultButton>

        {events?.data?.map((event: CricketMatchEvents[0], i: number) => {
          const currentInnings =
            "innings" in event
              ? innings?.data?.find(
                  (inningsData) => inningsData.id === event.innings,
                )
              : null

          return (
            <div key={event.id}>
              {/* If end of over */}

              {"ball" in event && event.ball - Math.floor(event.ball) === 0 && (
                <div className="over-end-card card-bg">
                  <h3>End of Over {Math.floor(event.ball)}</h3>
                  <div className="innings-name">
                    In {currentInnings && currentInnings.name}
                  </div>
                  <div className="innings-name">
                    {currentInnings && currentInnings.homeBatting
                      ? "Home Batting"
                      : "Guest Batting"}
                  </div>

                  <div className="end-ball-c">
                    {getBallsInOver(event)?.map((ball) => {
                      return (
                        "runs" in ball && (
                          <div
                            key={ball.id}
                            className="ball-c runs"
                            style={{
                              backgroundColor: ball.relatedDataChanges.wickets
                                ? "#C93535"
                                : ball.runs === 0
                                ? "#ffffff30"
                                : ball.runs === 4 || ball.runs === 6
                                ? "#B7095Dcc"
                                : "#4361EE",
                            }}
                          >
                            {ball.relatedDataChanges.wickets ? "W" : ball.runs}
                          </div>
                        )
                      )
                    })}
                  </div>

                  {/* {findMissingBallsInOver(Math.floor(event.ball)).map((ball) => (
                  <div key={ball}>{ball.toFixed(1)} is missing</div>
                ))} */}
                </div>
              )}

              <div className="event-commentary">
                <div className="title-c main">
                  <div className="title-c">
                    {event.isCommentary ? (
                      commentaryFocused && (
                        <div className="material-symbols-rounded">comment</div>
                      )
                    ) : (
                      <div
                        className="ball-c"
                        style={{
                          backgroundColor:
                            "relatedDataChanges" in event
                              ? event.relatedDataChanges.wickets
                                ? "#C93535"
                                : event.relatedDataChanges.extras?.noBalls ||
                                  event.relatedDataChanges.extras?.wide
                                ? "#4361EE"
                                : "#ffffff30"
                              : "#ffffff30",
                        }}
                      >
                        {"relatedDataChanges" in event &&
                          (event.relatedDataChanges.extras?.noBalls
                            ? "nb"
                            : event.relatedDataChanges.extras?.wide
                            ? "wd"
                            : event.relatedDataChanges.wickets
                            ? "W " + event.ball.toFixed(1)
                            : "ball" in event && event.ball.toFixed(1))}
                      </div>
                    )}

                    <div className="ball-col">
                      <div className="time">
                        {getFormattedDate(
                          new Date(event.time.seconds * 1000),
                          true,
                          true,
                          false,
                        )}
                      </div>

                      {event.isCommentary ? (
                        <div className="comment">{event.comment}</div>
                      ) : (
                        <div className="ball-d-c">
                          <div
                            className="ball-c runs"
                            style={{
                              backgroundColor:
                                "runs" in event
                                  ? event.runs === 0
                                    ? "#ffffff30"
                                    : event.runs === 4 || event.runs === 6
                                    ? "#B7095Dcc"
                                    : "#4361EE"
                                  : "#4361EE",
                            }}
                          >
                            {"runs" in event && event.runs}
                          </div>

                          <div>
                            {"description" in event && event.description}
                            {"comment" in event &&
                              event.comment !== "" &&
                              ", " + event.comment}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {!event.isCommentary && !commentaryFocused && (
                    <div className="btn-c ball-event">
                      <DefaultButton
                        onClick={() => {
                          if (setSelectValue) {
                            setSelectValue({ action: "delete", id: event.id })
                          }
                        }}
                      >
                        <div className="material-symbols-rounded">delete</div>
                      </DefaultButton>
                    </div>
                  )}
                </div>

                {commentaryFocused && (
                  <div className="btn-c">
                    {commentaryFocused && event.isCommentary && (
                      <DefaultButton
                        onClick={() => {
                          if (setSelectValue) {
                            setSelectValue({ action: "edit", id: event.id })
                          }
                        }}
                      >
                        <div className="material-symbols-rounded">edit</div>
                      </DefaultButton>
                    )}

                    {commentaryFocused && event.isCommentary && (
                      <DefaultButton
                        onClick={() => {
                          if (setSelectValue) {
                            setSelectValue({ action: "delete", id: event.id })
                          }
                        }}
                      >
                        <div className="material-symbols-rounded">delete</div>
                      </DefaultButton>
                    )}

                    {commentaryFocused && !event.isCommentary && (
                      <DefaultButton
                        onClick={() => {
                          if (setSelectValue) {
                            setSelectValue({
                              action: "comment",
                              id: event.id,
                            })
                          }
                        }}
                      >
                        <div className="material-symbols-rounded">
                          {event.comment === "" || event.comment === undefined
                            ? "comment"
                            : "edit"}
                        </div>
                      </DefaultButton>
                    )}
                  </div>
                )}
              </div>

              {/* If beginning of new inning */}

              {"ball" in event &&
                event.ball === 0.1 &&
                getLatestBallEventByIndex(i)?.map((ballEvent) => {
                  const currentInnings =
                    "innings" in ballEvent
                      ? innings?.data?.find(
                          (inningsData) => inningsData.id === ballEvent.innings,
                        )
                      : null
                  return (
                    <div
                      className="over-end-card innings card-bg"
                      key={ballEvent.id}
                    >
                      {"innings" in ballEvent && (
                        <h3>End of {currentInnings?.name}</h3>
                      )}

                      <div className="innings-name">
                        {currentInnings && currentInnings.homeBatting
                          ? "Home Batting"
                          : "Guest Batting"}
                      </div>

                      <div className="scores">
                        {currentInnings &&
                          currentInnings[
                            currentInnings?.homeBatting
                              ? "homeScores"
                              : "guestScores"
                          ].runs +
                            "/" +
                            currentInnings[
                              currentInnings?.homeBatting
                                ? "homeScores"
                                : "guestScores"
                            ].wickets}{" "}
                        (
                        {currentInnings &&
                          currentInnings[
                            currentInnings?.homeBatting
                              ? "homeScores"
                              : "guestScores"
                          ].overs.toFixed(1)}
                        )
                      </div>
                    </div>
                  )
                })}

              {/* If last ball is missing */}

              {!event.isCommentary && !checkLastBallExists(i) && (
                <div className="event-commentary non-exists">
                  <div className="title-c main">
                    <div className="title-c">
                      <div className="ball-c">
                        {overSafeBall(
                          event && "ball" in event ? event.ball - 0.1 : 0,
                        )}
                      </div>

                      <div className="ball-col">
                        <div className="ball-d-c">
                          <div>Doesn't Exists. Add the Ball</div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-c ball-event">
                      {!commentaryFocused && (
                        <DefaultButton
                          onClick={() => {
                            if (setSelectValue) {
                              setSelectValue({
                                action: "add",
                                id: overSafeBall(
                                  event && "ball" in event
                                    ? event.ball - 0.1
                                    : 0,
                                ),
                              })
                            }
                          }}
                        >
                          <div className="material-symbols-rounded">add</div>
                        </DefaultButton>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
