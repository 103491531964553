import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useAppDispatch } from "../../../app/hooks"
import { validateEmail } from "../../../app/functions"

import { toaster } from "../../../app/toaster"

import { changeHeader } from "../../../features/client/pageHeader"
import { useNewUserMutation } from "../../../features/firebase/subSlices/userListSlice"

import InputField from "../../../components/InputField/InputField"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import NewItemPageContainer from "../../../components/NewItemPageContainer/NewItemPageContainer"

export default function NewAdmin() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [newUser] = useNewUserMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "New Admin",
      }),
    )
  }, [dispatch])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [reEnterPassword, setReEnterPassword] = useState("")

  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [reEnterPasswordVisibility, setReEnterPasswordVisibility] =
    useState(false)

  return (
    <NewItemPageContainer title="Add a New Admin User">
      <div className="input-container">
        <InputField
          title="Name"
          name="name"
          placeholder="Enter Name"
          value={name}
          setValue={setName}
        />

        <InputField
          title="Email"
          name="email"
          type="email"
          placeholder="Enter Email"
          value={email}
          setValue={setEmail}
        />

        <InputField
          title="Password"
          name="password"
          placeholder="Enter Password"
          value={password}
          type={passwordVisibility ? "text" : "password"}
          icon={passwordVisibility ? "visibility_off" : "visibility"}
          iconOnClick={() => {
            setPasswordVisibility((preVal) => {
              return !preVal
            })
          }}
          setValue={setPassword}
        />

        <InputField
          title="Re-enter Password"
          name="re-enter-password"
          placeholder="Re-enter Password"
          value={reEnterPassword}
          type={reEnterPasswordVisibility ? "text" : "password"}
          icon={reEnterPasswordVisibility ? "visibility_off" : "visibility"}
          iconOnClick={() => {
            setReEnterPasswordVisibility((preVal) => {
              return !preVal
            })
          }}
          setValue={setReEnterPassword}
        />
      </div>

      <div className="btn-container">
        <DefaultButton
          onClick={() => {
            navigate("/admins")
          }}
        >
          <span className="material-symbols-rounded">delete</span>
          <span className="text">Discard</span>
        </DefaultButton>

        <DefaultButton
          color="#b7095db2"
          hoverColor="#b7095d9f"
          onClick={() => {
            if (!name || !email || !password || !reEnterPassword) {
              toaster.error(
                "All fields are required",
                "Please fill all the fields",
              )
            } else if (!validateEmail(email)) {
              toaster.error(
                "Invalid email",
                "Please enter a valid email address",
              )
            } else if (password !== reEnterPassword) {
              toaster.error(
                "Passwords do not match",
                "Try again with matching passwords",
              )
            } else {
              newUser({ email, password, name })
              navigate("/admins")
            }
          }}
        >
          <span className="material-symbols-rounded">person_add</span>
          <span className="text">Add New Admin</span>
        </DefaultButton>
      </div>
    </NewItemPageContainer>
  )
}
