import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface LoaderType {
  disableAllLoading: boolean
  loadingStateChangeUIDs: string[]
  loadingAdminStateChangeUIDs: string[]
}

const initialState: LoaderType = {
  disableAllLoading: false,
  loadingStateChangeUIDs: [],
  loadingAdminStateChangeUIDs: [],
}

export const userListLoadSlice = createSlice({
  name: "userListLoad",
  initialState,
  reducers: {
    changeDisableAllLoadingState: (state, action: PayloadAction<boolean>) => {
      state.disableAllLoading = action.payload
    },

    addToLoadingStateChangeUIDs: (state, action: PayloadAction<string>) => {
      state.loadingStateChangeUIDs.push(action.payload)
    },

    removeFromLoadingStateChangeUIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.loadingStateChangeUIDs = state.loadingStateChangeUIDs.filter(
        (uid) => uid !== action.payload,
      )
    },

    addToLoadingAdminStateChangeUIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.loadingAdminStateChangeUIDs.push(action.payload)
    },

    removeFromLoadingAdminStateChangeUIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.loadingAdminStateChangeUIDs =
        state.loadingAdminStateChangeUIDs.filter(
          (uid) => uid !== action.payload,
        )
    },
  },
})

export const {
  changeDisableAllLoadingState,
  addToLoadingStateChangeUIDs,
  removeFromLoadingStateChangeUIDs,
  addToLoadingAdminStateChangeUIDs,
  removeFromLoadingAdminStateChangeUIDs,
} = userListLoadSlice.actions

export default userListLoadSlice.reducer
