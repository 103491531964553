import "./DropDown.css"

export default function DropDown({
  values,
  value,
  placeHolder,
  setValue,
  onChange,
  disabled = false,
}: {
  values: { label: string; value: string }[]
  value: string | null
  placeHolder?: string
  setValue: React.Dispatch<React.SetStateAction<any>>
  onChange?: (value: any) => void
  disabled?: boolean
}) {
  return value === null ? (
    <></>
  ) : (
    <div
      className="card-bg dropdown"
      style={{
        filter: disabled ? "grayscale(1)" : "none",
        opacity: disabled ? 0.3 : 1,
      }}
    >
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          if (onChange) onChange(e.target.value)
        }}
        disabled={disabled}
      >
        {placeHolder && (
          <option value="" disabled hidden>
            {placeHolder}
          </option>
        )}
        {values.map((value) => (
          <option key={value.value} value={value.value}>
            {value.label}
          </option>
        ))}
      </select>
    </div>
  )
}
