import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useAppDispatch } from "../../../../app/hooks"
import { Match } from "../../../../app/types"

import { useGetPlayersQuery } from "../../../../features/firebase/subSlices/matches/playersSlice"
import { changeHeader } from "../../../../features/client/pageHeader"
import {
  useAddInningsMutation,
  useGetInningsQuery,
} from "../../../../features/firebase/subSlices/matches/cricket/inningsSlice"
import { useGetMatchesQuery } from "../../../../features/firebase/subSlices/matches/matchesSlice"

import "./Innings.css"

import InputField from "../../../../components/InputField/InputField"
import ColorButton from "../../../../components/Buttons/ColorButton/ColorButton"
import ScoreCard from "../ScoreCard/ScoreCard"

export default function Innings() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()
  const innings =
    params.matchId === undefined ? null : useGetInningsQuery(params.matchId)

  const matchPlayers =
    params.matchId !== undefined ? useGetPlayersQuery(params.matchId) : null

  const [addInnings] = useAddInningsMutation()

  const [match, setMatch] = useState<null | Match>(null)

  const [inningsName, setInningsName] = useState("")
  const [homeBatting, setHomeBatting] = useState<null | boolean>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (params.matchId) {
      setMatch(
        matches.data?.find((match: any) => match.id === params.matchId) || null,
      )
    } else {
      navigate("/matches")
    }
  }, [params.matchId, matches.data])

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Match Innings",
      }),
    )
  }, [dispatch])

  return innings?.isLoading ? (
    <div className="loader-container">
      <span className="loader" />
    </div>
  ) : (
    <div className="innings-pg-c">
      <div className="innings-score-card-c">
        {innings?.data && innings?.data?.length > 0 ? (
          innings?.data?.map((innings) => (
            <ScoreCard
              key={innings.id}
              matchId={params.matchId || ""}
              inningsId={innings.id}
              OnClick={() => {
                navigate(`/matches/${params.matchId}/innings/${innings.id}`)
              }}
            />
          ))
        ) : (
          <div>No Innings added yet.</div>
        )}
      </div>

      <div className="add-innings-card card-bg">
        <h2>Add Innings</h2>

        <InputField
          title="Innings Name"
          name="inningsName"
          placeholder="Enter Innings Name (Ex. Innings 1)"
          value={inningsName}
          setValue={setInningsName}
        />

        <div>
          <h3>Batting Team</h3>
          <div className="schools-btn">
            <button
              className={homeBatting ? "active" : ""}
              onClick={() => setHomeBatting(true)}
            >
              Home
            </button>
            <button
              className={homeBatting === false ? "active" : ""}
              onClick={() => setHomeBatting(false)}
            >
              Guest
            </button>
          </div>
        </div>

        <ColorButton
          onClick={async () => {
            setLoading(true)

            if (match) {
              await addInnings({
                matchId: match.id,
                name: inningsName,
                index: innings?.data?.length ? innings.data.length : 0,
                homeBatting,
                players: matchPlayers?.data || null,
              })
            }

            setLoading(false)
            setHomeBatting(null)
            setInningsName("")
          }}
        >
          {loading ? (
            <span className="loader" />
          ) : (
            <>
              <div className="material-symbols-rounded">sports_cricket</div>
              <div className="text">Add Innings</div>
            </>
          )}
        </ColorButton>
      </div>
    </div>
  )
}
