import { CSSProperties } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { useAppDispatch } from "../../app/hooks"

import { logout, selectUser } from "../../features/userSlice"
import { setOpen } from "../../features/client/sidePanelSlice"

import "./SidePanel.css"
import { useGetHomeImageQuery } from "../../features/firebase/subSlices/settingsSlice"

function NavigatorChip({
  icon,
  text,
  active,
  onClick,
}: {
  icon: string
  text: string
  active: boolean
  onClick: () => void
}) {
  const dispatch = useAppDispatch()

  return (
    <button
      className={`navigator-chip ${active ? "navigator-chip-active" : ""}`}
      onClick={() => {
        onClick()
        dispatch(setOpen(false))
      }}
    >
      <span className="material-symbols-rounded">{icon}</span>
      <span className="text">{text}</span>
    </button>
  )
}

export default function SidePanel({
  extend,
  loading,
}: {
  extend: boolean
  loading: boolean
}) {
  const dispatch = useAppDispatch()

  const user = useSelector(selectUser)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const matchPhoto = useGetHomeImageQuery()

  return (
    <div
      className="side-panel"
      style={
        extend
          ? ({
              "--padding-side-panel": "calc(var(--padding) / 2)",
            } as CSSProperties)
          : {}
      }
    >
      <div className="side-panel-top">
        <div
          className={`background-photo ${extend ? "masked-gradient" : ""}`}
          style={{ backgroundImage: `url(${matchPhoto.data})` }}
        />
        <div
          className={`background-gradient  ${extend ? "masked-gradient" : ""}`}
        />

        <div className="side-panel-content-div">
          <div className="top-slice">
            <div>
              <span>© Royal College Computer Society 2024</span>
              <br />
              <span>v {APP_VERSION}</span>
            </div>

            <button
              className="material-symbols-rounded close-btn"
              style={{ opacity: loading ? 0 : 1 }}
              onClick={() => {
                dispatch(setOpen(false))
              }}
            >
              arrow_back_ios
            </button>

            <div className="loader-div">
              <span style={{ opacity: loading ? 1 : 0 }} className="loader" />
            </div>
          </div>

          <div
            className="app-details"
            style={{
              opacity: extend ? "0" : "1",
            }}
          >
            <h1>Royal College Sports App</h1>
            <h2>Admin Dashboard</h2>
            <p>
              Manage the Sports matches, News and all the features related to
              the Royal College Sports App
            </p>
          </div>
        </div>
      </div>

      <div
        className="side-panel-bottom"
        style={{
          maxHeight: extend ? "100%" : "0%",
          opacity: extend ? "1" : "0",
          padding: extend ? "var(--padding-side-panel)" : "0",
        }}
      >
        <div className="side-panel-bottom-container">
          <div className="app-details-bottom">
            <h1>Royal College Sports</h1>
            <h2>Admin Dashboard</h2>
          </div>

          <NavigatorChip
            icon="home"
            text="Home"
            active={pathname === "/dashboard" ? true : false}
            onClick={() => {
              navigate("/dashboard")
            }}
          />

          <NavigatorChip
            icon="trophy"
            text="Matches"
            active={pathname?.includes("matches") ? true : false}
            onClick={() => {
              navigate("/matches")
            }}
          />

          <NavigatorChip
            icon="newsmode"
            text="News"
            active={pathname === "/news" ? true : false}
            onClick={() => {
              navigate("/news")
            }}
          />

          <NavigatorChip
            icon="notifications"
            text="Notifications"
            active={pathname === "/notifications" ? true : false}
            onClick={() => {
              navigate("/notifications")
            }}
          />

          {user.admin && (
            <NavigatorChip
              icon="person"
              text="Admins"
              active={pathname === "/admins" ? true : false}
              onClick={() => {
                navigate("/admins")
              }}
            />
          )}

          <NavigatorChip
            icon="logout"
            text="Logout"
            active={false}
            onClick={() => {
              dispatch(logout())
              navigate("/")
              navigate(0)
            }}
          />
        </div>
      </div>

      <div
        className="side-panel-line"
        style={{
          height: extend ? "42%" : "100%",
          bottom: extend ? "2%" : "0%",
        }}
      />
    </div>
  )
}
