import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { useAppDispatch } from "../../../app/hooks"
import { createHashtagArray } from "../../../app/functions"
import { MatchTypes } from "../../../app/types"

import {
  useEditNewsMutation,
  useGetNewsQuery,
} from "../../../features/firebase/subSlices/newsSlice"
import { selectUser } from "../../../features/userSlice"
import { changeHeader } from "../../../features/client/pageHeader"
import { showPopup } from "../../../features/client/popupSlice"

import "./NewsPage.css"

import NewItemPageContainer from "../../../components/NewItemPageContainer/NewItemPageContainer"
import InputField from "../../../components/InputField/InputField"
import Toggle from "../../../components/Toggle/Toggle"
import DropArea from "../../../components/DropArea/DropArea"
import DropDown from "../../../components/DropDown/DropDown"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"

export default function NewsPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useSelector(selectUser)

  const params = useParams()
  const news = useGetNewsQuery()

  const [editNews] = useEditNewsMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Edit News",
      }),
    )
  }, [dispatch])

  const [active, setActive] = useState(true)
  const [title, setTitle] = useState("")
  const [featureText, setFeatureText] = useState("")
  const [hashTags, setHashTags] = useState<string>("")
  const [matchType, setMatchType] = useState<"none" | MatchTypes>("none")

  const [file, setFile] = useState<File | null>(null)

  const [articleMarkdown, setArticleMarkdown] = useState("")
  const [loading, setLoading] = useState(false)
  const [fetchingMarkdown, setFetchingMarkdown] = useState(true)

  const [currentImage, setCurrentImage] = useState("")
  const [currentMarkdown, setCurrentMarkdown] = useState("")

  useEffect(() => {
    if (news.data) {
      const newsItem = news.data.find((item) => item.id === params.newsId)

      if (newsItem) {
        setActive(newsItem.active)
        setTitle(newsItem.title)
        setFeatureText(newsItem.featureText)
        setHashTags(newsItem.hashTags.join(" "))
        setMatchType(newsItem.matchType)
        setCurrentImage(newsItem.image)

        fetch(newsItem.articleMarkdown).then(async (data) => {
          const textData = await data.text()

          setArticleMarkdown(textData)
          setCurrentMarkdown(textData)
          setFetchingMarkdown(false)
        })
      } else {
        navigate("/news")
      }
    }
  }, [news.data, params.id])

  return news.isLoading ? (
    <div className="loader-container news-page-loader">
      <span className="loader" />
    </div>
  ) : (
    <NewItemPageContainer className="edit-news-page" title="Edit News">
      <div className="input-container">
        <div className="title-container">
          <InputField
            title="Title"
            name="title"
            placeholder="Enter Title"
            value={title}
            setValue={setTitle}
          />

          <div className="active-c">
            <div>Active</div>
            <Toggle
              active={active}
              setActive={setActive}
              color="rgba(72, 77, 189, 0.7)"
            />
          </div>
        </div>

        <InputField
          title="Feature Text"
          name="featureText"
          placeholder="Enter Feature Text"
          type="textarea"
          value={featureText}
          setValue={setFeatureText}
          textareaCharLimit={200}
        />

        <div className="spacer" />

        <InputField
          title="Hash Tags"
          name="hashTags"
          placeholder="Enter Hash Tags (Start with #, Separate with Space)"
          value={hashTags}
          setValue={setHashTags}
        />

        <div className="hashtag-c">
          {createHashtagArray(hashTags).map((tag, index) => (
            <div key={index} className="hashtag">
              {tag}
            </div>
          ))}
        </div>

        <div>News Image</div>

        <div className="news-img-c">
          <img src={currentImage} alt="" />
          <DropArea value={file} setValue={setFile} />
        </div>

        <div className="spacer" />

        <div className="match-type-label">Related Match Type</div>

        <DropDown
          values={[
            { label: "None", value: "none" },
            { label: "Cricket", value: "cricket" },
            { label: "Rugby", value: "rugby" },
          ]}
          value={matchType}
          setValue={setMatchType}
        />

        <div className="spacer" />

        <div>Article Markdown</div>

        {fetchingMarkdown ? (
          <div className="loader-container">
            <span className="loader" />
          </div>
        ) : (
          <div className="markdown-container">
            <InputField
              name="articleMarkdown"
              placeholder="Enter Article Markdown"
              type="textarea"
              value={articleMarkdown}
              setValue={setArticleMarkdown}
              textareaHeight="30rem"
            />
            <div className="markdown-preview card-bg">
              <Markdown remarkPlugins={[remarkGfm]}>{articleMarkdown}</Markdown>
            </div>
          </div>
        )}

        <div className="btn-container">
          {user.admin && (
            <DefaultButton
              color="rgba(150, 29, 87, 0.7)"
              hoverColor="rgba(150, 29, 87, 0.5)"
              onClick={() => {
                dispatch(
                  showPopup({
                    title: "Delete News",
                    message: `Are You sure to delete news ${title}? This action cannot be undone.`,
                    buttons: [
                      {
                        title: "Delete",
                        action: "delete-news",
                        data: params.newsId,
                        loading: false,
                      },
                      {
                        title: "Cancel",
                        action: "close",
                        loading: false,
                      },
                    ],
                  }),
                )
              }}
            >
              <span className="material-symbols-rounded">delete_forever</span>
              <span className="text">Delete</span>
            </DefaultButton>
          )}

          <div className="btn-container btn-c-1">
            <DefaultButton
              onClick={() => {
                navigate("/news")
              }}
            >
              <span className="material-symbols-rounded">delete</span>
              <span className="text">Discard</span>
            </DefaultButton>
            <DefaultButton
              color="rgba(72, 77, 189, 0.7)"
              hoverColor="rgba(72, 77, 189, 0.5)"
              onClick={async () => {
                if (!loading && news.data) {
                  const originalNews = news.data.find(
                    (newsItem) => newsItem.id === params.newsId,
                  )

                  setLoading(true)

                  await editNews({
                    id: params.newsId,
                    active:
                      active === originalNews?.active ? undefined : active,
                    title: title === originalNews?.title ? undefined : title,
                    featureText:
                      featureText === originalNews?.featureText
                        ? undefined
                        : featureText,
                    image: file,
                    articleMarkdown:
                      articleMarkdown === currentMarkdown
                        ? undefined
                        : articleMarkdown,
                    hashTags:
                      hashTags === originalNews?.hashTags.join("")
                        ? undefined
                        : createHashtagArray(hashTags),
                    matchType:
                      matchType === originalNews?.matchType
                        ? undefined
                        : matchType,
                  })

                  navigate("/news")
                }
              }}
            >
              {loading ? (
                <span className="loader" />
              ) : (
                <>
                  <span className="material-symbols-rounded">edit</span>
                  <span className="text">Edit News</span>
                </>
              )}
            </DefaultButton>
          </div>
        </div>
      </div>
    </NewItemPageContainer>
  )
}
