import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useAppDispatch } from "../../../app/hooks"

import {
  useEditMatchDetailsMutation,
  useGetMatchesQuery,
} from "../../../features/firebase/subSlices/matches/matchesSlice"
import { changeHeader } from "../../../features/client/pageHeader"

import "./EditMatch.css"

import NewItemPageContainer from "../../../components/NewItemPageContainer/NewItemPageContainer"
import InputField from "../../../components/InputField/InputField"
import Toggle from "../../../components/Toggle/Toggle"
import DropArea from "../../../components/DropArea/DropArea"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"

function getFormattedDate(dateObject: {
  seconds: number
  nanoseconds: number
}) {
  const date = new Date(dateObject.seconds * 1000)
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -8)
}

export default function EditMatch() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const matches = useGetMatchesQuery()
  const [editMatchDetails] = useEditMatchDetailsMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Edit Match Details",
      }),
    )
  }, [dispatch])

  const [active, setActive] = useState(true)
  const [title, setTitle] = useState("")
  const [subText, setSubText] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")

  const [locationDisplayName, setLocationDisplayName] = useState("")
  const [locationSearchName, setLocationSearchName] = useState("")
  const [locationLat, setLocationLat] = useState("")
  const [locationLng, setLocationLng] = useState("")

  const [liveStreamActive, setLiveStreamActive] = useState(false)
  const [liveStreamYoutubeID, setLiveStreamYoutubeID] = useState("")
  const [liveStreamDescription, setLiveStreamDescription] = useState("")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (matches.data) {
      const match = matches.data.find((m) => m.id === params.matchId)
      if (match) {
        setActive(match.active)
        setTitle(match.title)
        setSubText(match.subText)
        setStartTime(getFormattedDate(match.startTime))
        setEndTime(getFormattedDate(match.endTime))

        setLocationDisplayName(match.location.displayName)
        setLocationSearchName(match.location.searchName)
        setLocationLat(match.location.latitude.toString())
        setLocationLng(match.location.longitude.toString())

        setLiveStreamActive(match.isLiveStreamEnabled)
        setLiveStreamYoutubeID(match.liveStreamYoutubeID ?? "")
        setLiveStreamDescription(match.liveStreamDescription ?? "")
      } else {
        navigate("/matches")
      }
    }
  }, [matches.data, params.matchId])

  return matches.isLoading ? (
    <div className="loader-container">
      <span className="loader" />
    </div>
  ) : (
    <NewItemPageContainer
      className="edit-match-page"
      title="Edit Match Details"
    >
      <div className="input-container">
        <div className="title-container">
          <InputField
            title="Title"
            name="title"
            placeholder="Enter Match Title"
            value={title}
            setValue={setTitle}
          />

          <div className="active-c">
            <div>Active</div>
            <Toggle
              active={active}
              setActive={setActive}
              color="rgba(14, 67, 131, 1)"
            />
          </div>
        </div>

        <div className="match-sections">
          <div>
            <InputField
              title="Sub Text"
              name="subText"
              placeholder="Enter Sub Text"
              value={subText}
              setValue={setSubText}
            />

            <div>Match Image</div>

            <DropArea value={file} setValue={setFile} />

            <InputField
              title="Start Time"
              name="startTime"
              type="datetime-local"
              value={startTime}
              setValue={setStartTime}
            />

            <InputField
              title="End Time"
              name="endTime"
              type="datetime-local"
              value={endTime}
              setValue={setEndTime}
            />

            <div className="live-stream-c">
              <div>
                <div className="live-stream">Live Stream</div>

                <Toggle
                  active={liveStreamActive}
                  setActive={setLiveStreamActive}
                  color="#FF0000aa"
                />

                <InputField
                  title="Youtube ID"
                  name="youtube-id"
                  placeholder="Enter Youtube ID"
                  disabled={!liveStreamActive}
                  value={liveStreamYoutubeID}
                  setValue={setLiveStreamYoutubeID}
                />

                <InputField
                  title="Description"
                  name="description"
                  placeholder="Enter Description"
                  disabled={!liveStreamActive}
                  value={liveStreamDescription}
                  setValue={setLiveStreamDescription}
                />
              </div>

              {liveStreamActive && liveStreamYoutubeID !== "" ? (
                <div className="iframe-c">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${liveStreamYoutubeID}?amp;controls=0`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div>
            <InputField
              title="Location Display Name"
              name="location-display-name"
              placeholder="Enter Location Display Name"
              value={locationDisplayName}
              setValue={setLocationDisplayName}
            />

            <InputField
              title="Location Search Name"
              name="location-search-name"
              placeholder="Enter Location Search Name"
              value={locationSearchName}
              setValue={setLocationSearchName}
            />

            <InputField
              title="Location Latitude"
              name="location-lat"
              placeholder="Enter Location Latitude"
              type="number"
              value={locationLat}
              setValue={setLocationLat}
            />

            <InputField
              title="Location Longitude"
              name="location-lng"
              placeholder="Enter Location Longitude"
              type="number"
              value={locationLng}
              setValue={setLocationLng}
            />
          </div>
        </div>

        <div className="btn-container">
          <DefaultButton
            onClick={() => {
              navigate(`/matches/${params.matchId}`)
            }}
          >
            <span className="material-symbols-rounded">delete</span>
            <span className="text">Discard</span>
          </DefaultButton>

          <DefaultButton
            color="rgba(14, 67, 131, 0.7)"
            hoverColor="rgba(14, 67, 131, 0.5)"
            onClick={async () => {
              if (!loading && matches.data) {
                const originalMatch = matches.data.find(
                  (m) => m.id === params.matchId,
                )

                setLoading(true)

                await editMatchDetails({
                  id: params.matchId,
                  active: active === originalMatch?.active ? undefined : active,
                  title: title === originalMatch?.title ? undefined : title,
                  subText:
                    subText === originalMatch?.subText ? undefined : subText,
                  image: file,
                  startTime: originalMatch
                    ? new Date(startTime).getTime() ===
                      new Date(
                        originalMatch?.startTime.seconds * 1000,
                      ).getTime()
                      ? undefined
                      : new Date(startTime)
                    : undefined,

                  endTime: originalMatch
                    ? new Date(endTime).getTime() ===
                      new Date(originalMatch?.endTime.seconds * 1000).getTime()
                      ? undefined
                      : new Date(endTime)
                    : undefined,
                  locationDisplayName:
                    locationDisplayName === originalMatch?.location.displayName
                      ? undefined
                      : locationDisplayName,
                  locationSearchName:
                    locationSearchName === originalMatch?.location.searchName
                      ? undefined
                      : locationSearchName,
                  locationLatitude:
                    locationLat ===
                      originalMatch?.location.latitude.toString() &&
                    locationLng === originalMatch?.location.longitude.toString()
                      ? undefined
                      : parseFloat(locationLat),
                  locationLongitude:
                    locationLng ===
                      originalMatch?.location.longitude.toString() &&
                    locationLat === originalMatch?.location.latitude.toString()
                      ? undefined
                      : parseFloat(locationLng),
                  isLiveStreamEnabled:
                    liveStreamActive === originalMatch?.isLiveStreamEnabled
                      ? undefined
                      : liveStreamActive,
                  liveStreamYoutubeID:
                    liveStreamYoutubeID === originalMatch?.liveStreamYoutubeID
                      ? undefined
                      : liveStreamYoutubeID,
                  liveStreamDescription:
                    liveStreamDescription ===
                    originalMatch?.liveStreamDescription
                      ? undefined
                      : liveStreamDescription,
                })

                navigate(`/matches/${params.matchId}`)
              }
            }}
          >
            {loading ? (
              <span className="loader" />
            ) : (
              <>
                <span className="material-symbols-rounded">edit</span>
                <span className="text">Edit Match Details</span>
              </>
            )}
          </DefaultButton>
        </div>
      </div>
    </NewItemPageContainer>
  )
}
