import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getFormattedDate, getMonthNameShort } from "../../app/functions"
import { MatchTypes, Match } from "../../app/types"

import { useGetSchoolsQuery } from "../../features/firebase/subSlices/schoolsSlice"

import "./MatchCard.css"

import InfoChip from "./InfoChip/InfoChip"
import StatusCard from "./StatusCard/StatusCard"
import MatchLogo from "./MatchLogo/MatchLogo"

export default function MatchCard({
  id,
  active,
  title,
  subText,
  image,
  startTime,
  location,
  infoChip,
  matchStatus,
  homeSchool,
  guestSchool,
  matchType,
  openDetails = false,
}: {
  id?: string
  active: boolean
  title: string
  subText: string
  image: string
  startTime: Date | null
  location: {
    displayName: string
    searchName: string
    latitude: number
    longitude: number
  }
  infoChip: {
    active: boolean
    icon?: string
    text?: string
  }
  matchStatus: Match["matchStatus"]
  homeSchool: string
  guestSchool: string
  matchType: MatchTypes
  openDetails?: boolean
}) {
  const navigate = useNavigate()

  const schools = useGetSchoolsQuery()

  const [colors, setColors] = useState<{
    home: string
    guest: string
  }>({
    home: "",
    guest: "",
  })

  useEffect(() => {
    if (schools.data) {
      setColors({
        home:
          schools.data.find((school) => school.id === homeSchool)?.color || "",
        guest:
          schools.data.find((school) => school.id === guestSchool)?.color || "",
      })
    }
  }, [schools.data])

  return (
    <div
      className="match-card"
      style={{
        filter: active ? "" : "grayscale(100%)",
        cursor: openDetails ? "pointer" : "default",
      }}
      onClick={() => {
        if (openDetails) {
          navigate(`/matches/${id}`)
        }
      }}
    >
      <div className="title-c">
        <div className="title-c-c">
          {!active && (
            <span className="material-symbols-rounded">visibility_off</span>
          )}
          <div>
            <h3>{title}</h3>
            <p>{subText}</p>
          </div>
        </div>

        <div className="title-c-c">
          <MatchLogo matchType={matchType} />

          {infoChip.active && (
            <InfoChip
              icon={infoChip.icon}
              text={infoChip.text}
              active={infoChip.active}
            />
          )}

          <StatusCard
            matchStatus={matchStatus}
            homeSchool={homeSchool}
            guestSchool={guestSchool}
            colors={colors}
          />
        </div>
      </div>

      <div className="data-c">
        <div className="match-schools">
          <div className="home-schools">
            {
              schools.data
                ?.filter((school) => school.id === homeSchool)
                .map((schoolLocal) => (
                  <div className="school-row">
                    <img src={schoolLocal.image} alt={schoolLocal.name} />
                    <div className="school-d">
                      <h4>{schoolLocal.name}</h4>
                      <p>{schoolLocal.city}</p>
                    </div>
                  </div>
                ))[0]
            }
          </div>

          <div className="guest-schools">
            {
              schools.data
                ?.filter((school) => school.id === guestSchool)
                .map((schoolLocal) => (
                  <div className="school-row">
                    <img src={schoolLocal.image} alt={schoolLocal.name} />
                    <div className="school-d">
                      <h4>{schoolLocal.name}</h4>
                      <p>{schoolLocal.city}</p>
                    </div>
                  </div>
                ))[0]
            }
          </div>
        </div>

        {startTime && (
          <div className="date-c">
            <div className="month">
              {getMonthNameShort(new Date(startTime))}
            </div>
            <div className="date">
              {(new Date(startTime).getDate() < 10 ? "0" : "") +
                new Date(startTime).getDate()}
            </div>
          </div>
        )}
      </div>

      <hr />

      <div className="btm-c">
        <div className="btm-d-c">
          <div className="material-symbols-rounded">location_on</div>
          <div>
            <div className="head">{location.displayName}</div>
            <div className="sub">
              Lat: {location.latitude.toFixed(3)}... Lng:{" "}
              {location.longitude.toFixed(3)}...
            </div>
          </div>
        </div>

        {startTime && (
          <div className="btm-d-c">
            <div className="material-symbols-rounded">alarm</div>
            <div>
              <div className="head">
                {getFormattedDate(new Date(startTime), true, false)}
              </div>
              <div className="sub">onwards</div>
            </div>
          </div>
        )}
      </div>

      <div
        className="bg"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />

      <div className="gradient" />
    </div>
  )
}
