import { httpsCallable } from "firebase/functions"
import { ref, uploadBytes } from "firebase/storage"
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  increment,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore"

import { convertTimestampToSerializable } from "../../../../app/functions"
import { db, functions, storage } from "../../../../app/firebase"
import { toaster } from "../../../../app/toaster"
import { MatchModel } from "../../../../app/types"

import { firebaseSlice } from "../../firebaseSlice"

export const extendedMatchesSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMatches: builder.query<MatchModel[], void>({
      async queryFn(): Promise<any> {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, "matches-prod"), orderBy("startTime", "desc")),
          )

          const matches: MatchModel[] = []
          let errorFound: { found: boolean; message: string } = {
            found: false,
            message: "",
          }

          querySnapshot.forEach((doc) => {
            const data = doc.data()

            if (data.active === undefined) {
              errorFound = {
                found: true,
                message: `Match active is missing in document ${doc.id}`,
              }
            }

            if (!data.title) {
              errorFound = {
                found: true,
                message: `Match title is missing in document ${doc.id}`,
              }
            }

            if (!data.subText) {
              errorFound = {
                found: true,
                message: `Match subText is missing in document ${doc.id}`,
              }
            }

            if (!data.image && !data.pending) {
              errorFound = {
                found: true,
                message: `Match Image is missing in document ${doc.id}`,
              }
            }

            if (!data.startTime) {
              errorFound = {
                found: true,
                message: `Match startTime is missing in document ${doc.id}`,
              }
            }

            if (!data.endTime) {
              errorFound = {
                found: true,
                message: `Match endTime is missing in document ${doc.id}`,
              }
            }

            if (!data.location) {
              errorFound = {
                found: true,
                message: `Match location is missing in document ${doc.id}`,
              }
            } else {
              if (!data.location.displayName) {
                errorFound = {
                  found: true,
                  message: `Match location displayName is missing in document ${doc.id}`,
                }
              }

              if (!data.location.searchName) {
                errorFound = {
                  found: true,
                  message: `Match location searchName is missing in document ${doc.id}`,
                }
              }

              if (!data.location.latitude) {
                errorFound = {
                  found: true,
                  message: `Match location latitude is missing in document ${doc.id}`,
                }
              }

              if (!data.location.longitude) {
                errorFound = {
                  found: true,
                  message: `Match location longitude is missing in document ${doc.id}`,
                }
              }

              if (!data.location.mapImage && !data.pending) {
                errorFound = {
                  found: true,
                  message: `Match location mapImage is missing in document ${doc.id}`,
                }
              }
            }

            if (!data.infoChip) {
              errorFound = {
                found: true,
                message: `Match infoChip is missing in document ${doc.id}`,
              }
            } else {
              if (data.infoChip.active === undefined) {
                errorFound = {
                  found: true,
                  message: `Match infoChip active is missing in document ${doc.id}`,
                }
              }
            }

            if (!data.matchStatus) {
              errorFound = {
                found: true,
                message: `Match matchStatus is missing in document ${doc.id}`,
              }
            } else {
              if (
                data.matchStatus !== "upcoming" &&
                data.matchStatus !== "live" &&
                data.matchStatus !== "homeWon" &&
                data.matchStatus !== "guestWon" &&
                data.matchStatus !== "stumps" &&
                data.matchStatus !== "draw"
              ) {
                errorFound = {
                  found: true,
                  message: `Match matchStatus is invalid in document ${doc.id}`,
                }
              }
            }

            if (data.isLiveStreamEnabled === undefined) {
              errorFound = {
                found: true,
                message: `Match isLiveStreamEnabled is missing in document ${doc.id}`,
              }
            } else if (data.isLiveStreamEnabled) {
              if (!data.liveStreamYoutubeID) {
                errorFound = {
                  found: true,
                  message: `Match liveStreamYoutubeID is missing in document ${doc.id}`,
                }
              }

              if (!data.liveStreamDescription) {
                errorFound = {
                  found: true,
                  message: `Match liveStreamDescription is missing in document ${doc.id}`,
                }
              }
            }

            if (!data.homeSchool) {
              errorFound = {
                found: true,
                message: `Match homeSchool is missing in document ${doc.id}`,
              }
            }

            if (!data.guestSchool) {
              errorFound = {
                found: true,
                message: `Match guestSchool is missing in document ${doc.id}`,
              }
            }

            if (
              data.matchType !== "cricket" &&
              data.matchType !== "rugby" &&
              data.matchType !== "football" &&
              data.matchType !== "hockey" &&
              data.matchType !== "tennis" &&
              data.matchType !== "rowing" &&
              data.matchType !== "sailing" &&
              data.matchType !== "basketball" &&
              data.matchType !== "badminton" &&
              data.matchType !== "waterpolo"
            ) {
              errorFound = {
                found: true,
                message: `Match matchType is invalid in document ${doc.id}`,
              }
            }

            // DEV: When adding a new match type, make sure to check its corresponding fields
            if (
              data.matchType === "cricket" &&
              data.matchStatus !== "upcoming"
            ) {
              if (!data.currentInnings) {
                errorFound = {
                  found: true,
                  message: `Match currentInnings is missing in document ${doc.id}`,
                }
              }
            }

            matches.push({
              id: doc.id,
              ...data,
              startTime: convertTimestampToSerializable(data.startTime),
              endTime: convertTimestampToSerializable(data.endTime),
              timer: data.timer
                ? {
                    ...data.timer,
                    targetTime: convertTimestampToSerializable(
                      data.timer?.targetTime,
                    ),
                  }
                : undefined,
            } as MatchModel)
          })

          if (errorFound.found) {
            toaster.error("Firestore Error", errorFound.message)
            return { error: errorFound.message }
          } else {
            return { data: matches }
          }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Matches" as const, id: id })),
              "Matches",
            ]
          : ["Matches"],

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        try {
          await cacheDataLoaded

          onSnapshot(
            query(collection(db, "matches-prod"), orderBy("startTime", "desc")),
            (querySnapshot) => {
              const matches: MatchModel[] = []
              let errorFound: { found: boolean; message: string } = {
                found: false,
                message: "",
              }

              querySnapshot.forEach((doc) => {
                const data = doc.data()

                if (data.active === undefined) {
                  errorFound = {
                    found: true,
                    message: `Match active is missing in document ${doc.id}`,
                  }
                }

                if (!data.title) {
                  errorFound = {
                    found: true,
                    message: `Match title is missing in document ${doc.id}`,
                  }
                }

                if (!data.subText) {
                  errorFound = {
                    found: true,
                    message: `Match subText is missing in document ${doc.id}`,
                  }
                }

                if (!data.startTime) {
                  errorFound = {
                    found: true,
                    message: `Match startTime is missing in document ${doc.id}`,
                  }
                }

                if (!data.endTime) {
                  errorFound = {
                    found: true,
                    message: `Match endTime is missing in document ${doc.id}`,
                  }
                }

                if (!data.location) {
                  errorFound = {
                    found: true,
                    message: `Match location is missing in document ${doc.id}`,
                  }
                } else {
                  if (!data.location.displayName) {
                    errorFound = {
                      found: true,
                      message: `Match location displayName is missing in document ${doc.id}`,
                    }
                  }

                  if (!data.location.searchName) {
                    errorFound = {
                      found: true,
                      message: `Match location searchName is missing in document ${doc.id}`,
                    }
                  }

                  if (!data.location.latitude) {
                    errorFound = {
                      found: true,
                      message: `Match location latitude is missing in document ${doc.id}`,
                    }
                  }

                  if (!data.location.longitude) {
                    errorFound = {
                      found: true,
                      message: `Match location longitude is missing in document ${doc.id}`,
                    }
                  }
                }

                if (!data.infoChip) {
                  errorFound = {
                    found: true,
                    message: `Match infoChip is missing in document ${doc.id}`,
                  }
                } else {
                  if (data.infoChip.active === undefined) {
                    errorFound = {
                      found: true,
                      message: `Match infoChip active is missing in document ${doc.id}`,
                    }
                  }
                }

                if (!data.matchStatus) {
                  errorFound = {
                    found: true,
                    message: `Match matchStatus is missing in document ${doc.id}`,
                  }
                } else {
                  if (
                    data.matchStatus !== "upcoming" &&
                    data.matchStatus !== "live" &&
                    data.matchStatus !== "homeWon" &&
                    data.matchStatus !== "guestWon" &&
                    data.matchStatus !== "stumps" &&
                    data.matchStatus !== "draw"
                  ) {
                    errorFound = {
                      found: true,
                      message: `Match matchStatus is invalid in document ${doc.id}`,
                    }
                  }
                }

                if (data.isLiveStreamEnabled === undefined) {
                  errorFound = {
                    found: true,
                    message: `Match isLiveStreamEnabled is missing in document ${doc.id}`,
                  }
                } else if (data.isLiveStreamEnabled) {
                  if (!data.liveStreamYoutubeID) {
                    errorFound = {
                      found: true,
                      message: `Match liveStreamYoutubeID is missing in document ${doc.id}`,
                    }
                  }

                  if (!data.liveStreamDescription) {
                    errorFound = {
                      found: true,
                      message: `Match liveStreamDescription is missing in document ${doc.id}`,
                    }
                  }
                }

                if (!data.homeSchool) {
                  errorFound = {
                    found: true,
                    message: `Match homeSchool is missing in document ${doc.id}`,
                  }
                }

                if (!data.guestSchool) {
                  errorFound = {
                    found: true,
                    message: `Match guestSchool is missing in document ${doc.id}`,
                  }
                }

                if (
                  data.matchType !== "cricket" &&
                  data.matchType !== "rugby" &&
                  data.matchType !== "football" &&
                  data.matchType !== "hockey" &&
                  data.matchType !== "tennis" &&
                  data.matchType !== "rowing" &&
                  data.matchType !== "sailing" &&
                  data.matchType !== "basketball" &&
                  data.matchType !== "badminton" &&
                  data.matchType !== "waterpolo"
                ) {
                  errorFound = {
                    found: true,
                    message: `Match matchType is invalid in document ${doc.id}`,
                  }
                }

                // DEV: When adding a new match type, make sure to check its corresponding fields
                if (
                  data.matchType === "cricket" &&
                  data.matchStatus !== "upcoming"
                ) {
                  if (!data.currentInnings) {
                    errorFound = {
                      found: true,
                      message: `Match currentInnings is missing in document ${doc.id}`,
                    }
                  }
                }

                matches.push({
                  id: doc.id,
                  ...data,
                  startTime: convertTimestampToSerializable(data.startTime),
                  endTime: convertTimestampToSerializable(data.endTime),
                  timer: data.timer
                    ? {
                        ...data.timer,
                        targetTime: convertTimestampToSerializable(
                          data.timer?.targetTime,
                        ),
                      }
                    : undefined,
                } as MatchModel)
              })

              if (errorFound.found) {
                toaster.error("Firestore Error", errorFound.message)
              } else {
                updateCachedData((draft) => {
                  Object.assign(draft, matches)
                })
              }
            },
          )
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to get matches")
        }

        await cacheEntryRemoved
      },
    }),

    addMatch: builder.mutation({
      async queryFn({
        active,
        title,
        subText,
        image,
        startTime,
        endTime,
        location,
        isLiveStreamEnabled,
        liveStreamYoutubeID,
        liveStreamDescription,
        homeSchool,
        guestSchool,
        matchType,
      }) {
        try {
          if (active === undefined) {
            toaster.error("Check all fields", "Match active is missing")
            return { error: "Match active is missing" }
          }

          if (!title) {
            toaster.error("Check all fields", "Match title is missing")
            return { error: "Match title is missing" }
          }

          if (!subText) {
            toaster.error("Check all fields", "Match subText is missing")
            return { error: "Match subText is missing" }
          }

          if (image === null) {
            toaster.error("Check all fields", "Match image is missing")
            return { error: "Match image is missing" }
          }

          if (!startTime) {
            toaster.error("Check all fields", "Match startTime is missing")
            return { error: "Match startTime is missing" }
          } else if (!(startTime instanceof Date)) {
            toaster.error("Check all fields", "Match startTime is invalid")
            return { error: "Match startTime is invalid" }
          }

          if (!endTime) {
            toaster.error("Check all fields", "Match endTime is missing")
            return { error: "Match endTime is missing" }
          } else if (!(endTime instanceof Date)) {
            toaster.error("Check all fields", "Match endTime is invalid")
            return { error: "Match endTime is invalid" }
          }

          if (!location) {
            toaster.error("Check all fields", "Match location is missing")
            return { error: "Match location is missing" }
          } else {
            if (!location.displayName) {
              toaster.error(
                "Check all fields",
                "Match location displayName is missing",
              )
              return { error: "Match location displayName is missing" }
            }

            if (!location.searchName) {
              toaster.error(
                "Check all fields",
                "Match location searchName is missing",
              )
              return { error: "Match location searchName is missing" }
            }

            if (!location.latitude) {
              toaster.error(
                "Check all fields",
                "Match location latitude is missing",
              )
              return { error: "Match location latitude is missing" }
            }

            if (!location.longitude) {
              toaster.error(
                "Check all fields",
                "Match location longitude is missing",
              )
              return { error: "Match location longitude is missing" }
            }
          }

          if (isLiveStreamEnabled === undefined) {
            toaster.error(
              "Check all fields",
              "Match isLiveStreamEnabled is missing",
            )
            return { error: "Match isLiveStreamEnabled is missing" }
          } else if (isLiveStreamEnabled) {
            if (!liveStreamYoutubeID) {
              toaster.error(
                "Check all fields",
                "Match liveStreamYoutubeID is missing",
              )
              return { error: "Match liveStreamYoutubeID is missing" }
            }

            if (!liveStreamDescription) {
              toaster.error(
                "Check all fields",
                "Match liveStreamDescription is missing",
              )
              return { error: "Match liveStreamDescription is missing" }
            }
          }

          if (!homeSchool) {
            toaster.error("Check all fields", "Match homeSchool is missing")
            return { error: "Match homeSchool is missing" }
          }

          if (!guestSchool) {
            toaster.error("Check all fields", "Match guestSchool is missing")
            return { error: "Match guestSchool is missing" }
          }

          if (
            matchType !== "cricket" &&
            matchType !== "rugby" &&
            matchType !== "football" &&
            matchType !== "hockey" &&
            matchType !== "tennis" &&
            matchType !== "rowing" &&
            matchType !== "sailing" &&
            matchType !== "basketball" &&
            matchType !== "badminton" &&
            matchType !== "waterpolo"
          ) {
            toaster.error("Check all fields", "Match matchType is invalid")
            return { error: "Match matchType is invalid" }
          }

          const updateObject: {
            [key: string]: any
          } = {
            active,
            title,
            subText,
            startTime: Timestamp.fromDate(startTime),
            endTime: Timestamp.fromDate(endTime),
            location,
            infoChip: { active: false },
            matchStatus: "upcoming",
            isLiveStreamEnabled,
            liveStreamYoutubeID,
            liveStreamDescription,
            homeSchool,
            guestSchool,
            matchType,
            pending: true,
          }

          if (matchType === "rugby") {
            updateObject.homeScores = {
              conversion: 0,
              try: 0,
              penalty: 0,
              dropGoal: 0,
            }
            updateObject.guestScores = {
              conversion: 0,
              try: 0,
              penalty: 0,
              dropGoal: 0,
            }
          }

          const docRef = await addDoc(
            collection(db, "matches-prod"),
            updateObject,
          )

          const id = docRef.id

          const storageRef = ref(storage, `matches-prod/${id}/${id}.png`)

          await uploadBytes(storageRef, image).catch((error) => {
            toaster.error(
              "Firebase Storage Error",
              "Failed to upload match image",
            )
            throw new Error(error)
          })

          const updateDocWithStorageObjects = httpsCallable(
            functions,
            "updateDocWithStorageObjects",
          )

          await updateDocWithStorageObjects({
            collection: "matches-prod",
            doc: id,
            fields: [
              {
                fieldName: "image",
                storagePath: `matches-prod/${id}/${id}`,
                image: true,
                imageSize: { width: 1200, height: 1200 },
              },
            ],
          }).catch((error) => {
            toaster.error(
              "Firebase Function Error",
              "Failed to call function [updateDocWithStorageObjects]",
            )
            throw new Error(error)
          })

          const updateMatchWithMapURL = httpsCallable(
            functions,
            "updateMatchWithMapURL",
          )

          await updateMatchWithMapURL({
            matchId: id,
            location: {
              latitude: location.latitude,
              longitude: location.longitude,
              imageSize: { width: 1200, height: 800 },
            },
          }).catch((error) => {
            toaster.error(
              "Firebase Function Error",
              "Failed to call function [updateMatchWithMapURL]",
            )
            throw new Error(error)
          })

          toaster.success(
            "Match Added",
            `Match ${id} had been added successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add match")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["Matches"],
    }),

    changeMatchStatus: builder.mutation({
      async queryFn({ id, matchStatus }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (
            matchStatus !== "upcoming" &&
            matchStatus !== "live" &&
            matchStatus !== "homeWon" &&
            matchStatus !== "guestWon" &&
            matchStatus !== "draw" &&
            matchStatus !== "stumps"
          ) {
            toaster.error("Check all fields", "Match matchStatus is invalid")
            return { error: "Match matchStatus is invalid" }
          }

          await updateDoc(doc(db, "matches-prod", id), {
            matchStatus,
          }).catch((error) => {
            toaster.error("Firestore Error", "Failed to update match status")
            throw new Error(error)
          })

          toaster.success(
            `Match Status Updated to ${matchStatus}`,
            `Match ${id} had been updated successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update match status")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),

    changeMatchInfoChip: builder.mutation({
      async queryFn({ id, infoChip }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!infoChip) {
            toaster.error("Check all fields", "Match infoChip is missing")
            return { error: "Match infoChip is missing" }
          }

          if (infoChip.active === undefined) {
            toaster.error(
              "Check all fields",
              "Match infoChip active is missing",
            )
            return { error: "Match infoChip active is missing" }
          }

          if (infoChip.active && !infoChip.icon) {
            toaster.error("Check all fields", "Match infoChip icon is missing")
            return { error: "Match infoChip icon is missing" }
          }

          if (infoChip.active && !infoChip.text) {
            toaster.error("Check all fields", "Match infoChip text is missing")
            return { error: "Match infoChip text is missing" }
          }

          await updateDoc(doc(db, "matches-prod", id), {
            infoChip,
          }).catch((error) => {
            toaster.error("Firestore Error", "Failed to update match infoChip")
            throw new Error(error)
          })

          toaster.success(
            `Match InfoChip Updated`,
            `Match ${id} had been updated successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update match infoChip")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),

    changeMatchTimer: builder.mutation({
      async queryFn({ id, timer }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (timer.active === undefined) {
            toaster.error("Check all fields", "Match timer is missing")
            return { error: "Match timer is missing" }
          }

          if (!timer.endTime) {
            toaster.error("Check all fields", "Match endTime is missing")
            return { error: "Match endTime is missing" }
          }

          await updateDoc(doc(db, "matches-prod", id), {
            timer: {
              active: timer.active,
              targetTime: Timestamp.fromMillis(timer.endTime),
            },
          }).catch((error) => {
            toaster.error("Firestore Error", "Failed to update match timer")
            throw new Error(error)
          })

          toaster.success(
            `Match Timer Updated`,
            `Match ${id} had been updated successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update match timer")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),

    changeMatchCurrentInnings: builder.mutation({
      async queryFn({ id, currentInnings }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!currentInnings) {
            toaster.error("Check all fields", "Match currentInnings is missing")
            return { error: "Match currentInnings is missing" }
          }

          await updateDoc(doc(db, "matches-prod", id), {
            currentInnings,
          }).catch((error) => {
            toaster.error(
              "Firestore Error",
              "Failed to update match currentInnings",
            )
            throw new Error(error)
          })

          toaster.success(
            `Match Current Innings Updated`,
            `Match ${id} had been updated successfully`,
          )

          return { data: null }
        } catch (error: any) {
          toaster.error(
            "Firestore Error",
            "Failed to update match currentInnings",
          )
          console.error(error)
          return { error: error.message }
        }
      },

      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),

    editMatchDetails: builder.mutation({
      async queryFn({
        id,
        active,
        title,
        subText,
        image,
        startTime,
        endTime,
        locationDisplayName,
        locationSearchName,
        locationLatitude,
        locationLongitude,
        isLiveStreamEnabled,
        liveStreamYoutubeID,
        liveStreamDescription,
      }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          const updateObject = {}

          if (active !== undefined) {
            Object.assign(updateObject, { active })
          }

          if (title) {
            Object.assign(updateObject, { title })
          }

          if (subText) {
            Object.assign(updateObject, { subText })
          }

          if (startTime) {
            Object.assign(updateObject, {
              startTime: Timestamp.fromDate(startTime),
            })
          }

          if (endTime) {
            Object.assign(updateObject, {
              endTime: Timestamp.fromDate(endTime),
            })
          }

          if (locationDisplayName) {
            Object.assign(updateObject, {
              "location.displayName": locationDisplayName,
            })
          }

          if (locationSearchName) {
            Object.assign(updateObject, {
              "location.searchName": locationSearchName,
            })
          }

          if (locationLatitude) {
            Object.assign(updateObject, {
              "location.latitude": locationLatitude,
            })
          }

          if (locationLongitude) {
            Object.assign(updateObject, {
              "location.longitude": locationLongitude,
            })
          }

          if (isLiveStreamEnabled !== undefined) {
            Object.assign(updateObject, { isLiveStreamEnabled })
          }

          if (liveStreamYoutubeID) {
            Object.assign(updateObject, { liveStreamYoutubeID })
          }

          if (liveStreamDescription) {
            Object.assign(updateObject, { liveStreamDescription })
          }

          if (Object.keys(updateObject).length > 0) {
            await updateDoc(doc(db, "matches-prod", id), updateObject).catch(
              (error) => {
                toaster.error(
                  "Firestore Error",
                  "Failed to update match details",
                )
                throw new Error(error)
              },
            )
          }

          if (locationLatitude && locationLongitude) {
            const updateMatchWithMapURL = httpsCallable(
              functions,
              "updateMatchWithMapURL",
            )

            await updateMatchWithMapURL({
              matchId: id,
              location: {
                latitude: locationLatitude,
                longitude: locationLongitude,
                imageSize: { width: 1200, height: 800 },
              },
            }).catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [updateMatchWithMapURL]",
              )
              throw new Error(error)
            })
          }

          if (image) {
            const storageRef = ref(storage, `matches-prod/${id}/${id}.png`)

            await uploadBytes(storageRef, image).catch((error) => {
              toaster.error(
                "Firebase Storage Error",
                "Failed to upload match image",
              )
              throw new Error(error)
            })

            const updateDocWithStorageObjects = httpsCallable(
              functions,
              "updateDocWithStorageObjects",
            )

            await updateDocWithStorageObjects({
              collection: "matches-prod",
              doc: id,
              fields: [
                {
                  fieldName: "image",
                  storagePath: `matches-prod/${id}/${id}`,
                  image: true,
                  imageSize: { width: 1200, height: 1200 },
                },
              ],
            }).catch((error) => {
              toaster.error(
                "Firebase Function Error",
                "Failed to call function [updateDocWithStorageObjects]",
              )
              throw new Error(error)
            })

            toaster.success(
              "Match Details Updated",
              `Match ${id} had been updated successfully`,
            )
          } else if (Object.keys(updateObject).length > 0) {
            toaster.success(
              "Match Details Updated",
              `Match ${id} had been updated successfully`,
            )
          }

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update match details")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),

    updateRugbyMatchScores: builder.mutation({
      async queryFn({ id, isHome, scoreType, isIncrement }) {
        try {
          if (!id) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          const fieldName = `${isHome ? "home" : "guest"}Scores.${scoreType}`

          await updateDoc(doc(db, "matches-prod", id), {
            [fieldName]: isIncrement ? increment(1) : increment(-1),
          }).catch((error) => {
            toaster.error("Firestore Error", "Failed to update match scores")
            throw new Error(error)
          })

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update match scores")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Matches", id: arg.id },
      ],
    }),
  }),
})

export const {
  useGetMatchesQuery,
  useAddMatchMutation,
  useChangeMatchStatusMutation,
  useChangeMatchInfoChipMutation,
  useChangeMatchTimerMutation,
  useEditMatchDetailsMutation,
  useChangeMatchCurrentInningsMutation,
  useUpdateRugbyMatchScoresMutation,
} = extendedMatchesSlice

export const selectMatches = extendedMatchesSlice.endpoints.getMatches.select()
