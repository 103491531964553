import he from "he"

import "./InfoChip.css"

export default function InfoChip({
  icon,
  text,
  active,
}: {
  icon?: string
  text?: string
  active: boolean
}) {
  return (
    active && (
      <div className="info-chip">
        <div className="material-symbols-rounded">
          {he.decode(`&#x${icon};` || "")}
        </div>
        <div className="text">{text}</div>
      </div>
    )
  )
}
