import { initializeApp } from "firebase/app"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { connectStorageEmulator, getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyA3WwKeteAEWQsT39Sunv03KWuyuV8RxB4",
  authDomain: "r-c-sports.firebaseapp.com",
  databaseURL: "https://r-c-sports.firebaseio.com",
  projectId: "r-c-sports",
  storageBucket: "r-c-sports.appspot.com",
  messagingSenderId: "996561490060",
  appId: "1:996561490060:web:d4cc6bf4a261df2d78eba9",
  measurementId: "G-798NLE1EFF",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app)
const storage = getStorage()

// if (import.meta.env.DEV) {
//   connectFirestoreEmulator(db, "127.0.0.1", 8080)
//   connectAuthEmulator(auth, "http://127.0.0.1:9099")
//   connectFunctionsEmulator(functions, "127.0.0.1", 5001)
//   connectStorageEmulator(storage, "127.0.0.1", 9199)
// }

export { app, auth, db, functions, storage }
