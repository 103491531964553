import { useEffect } from "react"
import { useSelector } from "react-redux"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getFormattedDate } from "../../app/functions"

import {
  useDisableEditorMutation,
  useGetUserListQuery,
} from "../../features/firebase/subSlices/userListSlice"
import { changeHeader } from "../../features/client/pageHeader"
import { selectUser } from "../../features/userSlice"
import { showPopup } from "../../features/client/popupSlice"

import "./Admins.css"

import DefaultButton from "../../components/Buttons/DefaultButton/DefaultButton"
import DisableEditorsCard from "../../components/DisableEditorsCard/DisableEditorsCard"

export default function Admins() {
  const dispatch = useAppDispatch()
  const user = useSelector(selectUser)

  const userListLoad = useAppSelector((state) => state.userListLoad)

  const userList = useGetUserListQuery()

  const [setDisableUser] = useDisableEditorMutation()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Admins",
        backButton: false,
        buttons: [
          {
            title: "New Admin User",
            icon: "add",
            functionName: "redirect",
            data: "/admins/new",
          },
        ],
      }),
    )
  }, [dispatch])

  return (
    <div className="admin-container">
      <div className="admin-cols">
        <div className="card-bg administrator-card">
          <h3>Administrators</h3>

          <div className="data-admins-container">
            {userList.isLoading ? (
              <div className="loader-container">
                <span className="loader" />
              </div>
            ) : userList.data?.admin.length === 0 ? (
              <div>
                <p>No Administrators found.</p>
              </div>
            ) : (
              userList.data?.admin.map((userLocal: any) => (
                <div
                  key={userLocal.uid}
                  className="admin-row"
                  style={{
                    opacity: userLocal.disabled ? 0.5 : 1,
                    order: userLocal.uid === user.uid ? -1 : 0,
                  }}
                >
                  <span>
                    {userLocal.displayName}
                    {userLocal.uid === user.uid && " (You)"}
                  </span>
                  <span>{userLocal.email}</span>
                  <span className="created-on">
                    Created on{" "}
                    {userLocal.creationTime
                      ? getFormattedDate(
                          new Date(userLocal.creationTime),
                          false,
                        )
                      : ""}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="admin-col-2">
          <DisableEditorsCard />

          <div className="card-bg admin-user-count-card">
            {userList.isLoading ? (
              <div className="loader-container">
                <span className="loader" />
              </div>
            ) : (
              <>
                <div>{userList.data?.admin.length} Administrators</div>
                <div>{userList.data?.editors.length} Editors</div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="card-bg editor-card">
        <h3>Editors</h3>

        <div className="data-admins-container">
          {userList.isLoading ? (
            <div className="loader-container">
              <span className="loader" />
            </div>
          ) : userList.data?.editors.length === 0 ? (
            <div>
              <p>No Editors found.</p>
              <p>
                Click on the "New Admin User" button to create a new
                Administrator with Editor Privileges.
              </p>
            </div>
          ) : (
            userList.data?.editors.map((userLocal) => (
              <div
                key={userLocal.uid}
                className="editor-row"
                style={{
                  opacity: userLocal.disabled ? 0.5 : 1,
                }}
              >
                <span>{userLocal.displayName}</span>
                <span>{userLocal.email}</span>
                <span>
                  Created on{" "}
                  {userLocal.creationTime
                    ? getFormattedDate(new Date(userLocal.creationTime), false)
                    : ""}
                </span>

                <div className="btn-container">
                  <DefaultButton
                    onClick={() => {
                      setDisableUser({
                        uid: userLocal.uid,
                        state: !userLocal.disabled,
                      })
                    }}
                  >
                    {userLocal.uid &&
                    userListLoad.loadingStateChangeUIDs?.includes(
                      userLocal.uid,
                    ) ? (
                      <span className="loader" />
                    ) : userLocal.disabled ? (
                      "Enable"
                    ) : (
                      "Disable"
                    )}
                  </DefaultButton>

                  <DefaultButton
                    onClick={() => {
                      dispatch(
                        showPopup({
                          title: "Change to Administrator",
                          message: `Are You sure to make ${userLocal.displayName} an Administrator? This will make the user able to access the admin panel with admin privileges. Proceed with caution. This action cannot be undone.`,
                          buttons: [
                            {
                              title: "Change to Administrator",
                              action: "change-user-to-admin",
                              data: userLocal.uid,
                              loading: false,
                            },
                            {
                              title: "Cancel",
                              action: "close",
                              loading: false,
                            },
                          ],
                        }),
                      )
                    }}
                  >
                    {userLocal.uid &&
                    userListLoad.loadingAdminStateChangeUIDs?.includes(
                      userLocal.uid,
                    ) ? (
                      <span className="loader" />
                    ) : (
                      <span className="material-symbols-rounded">
                        shield_person
                      </span>
                    )}
                  </DefaultButton>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}
