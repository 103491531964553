import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useAppDispatch } from "../../app/hooks"
import { News as NewsType } from "../../app/types"
import { getFormattedDate } from "../../app/functions"

import { changeHeader } from "../../features/client/pageHeader"
import { useGetNewsQuery } from "../../features/firebase/subSlices/newsSlice"

import "./News.css"

function NewsCard({ newsLocal }: { newsLocal: NewsType[0] }) {
  const navigate = useNavigate()

  return (
    <div
      className="news-card"
      onClick={() => navigate(`/news/${newsLocal.id}`)}
      style={{
        filter: newsLocal.active ? "" : "grayscale(100%)",
      }}
    >
      <div
        className="bg"
        style={{
          backgroundImage: `url(${newsLocal.image})`,
        }}
      />
      <div className="gradient" />

      <div className="top-data">
        <div className="top-r">
          <div className="news-time">
            {getFormattedDate(
              new Date(newsLocal.time.seconds * 1000),
              true,
              true,
              true,
            )}
          </div>

          {newsLocal.active ? null : (
            <div className="indicator">
              <span className="material-symbols-rounded">visibility_off</span>
              Inactive
            </div>
          )}
        </div>

        <h2>{newsLocal.title}</h2>
        <p>
          {newsLocal.featureText.length > 150
            ? newsLocal.featureText.substring(0, 150) + "..."
            : newsLocal.featureText}
        </p>
      </div>

      <div className="btm-r">
        <div className="author">By {newsLocal.author}</div>
        <span className="material-symbols-rounded">arrow_forward_ios</span>
      </div>
    </div>
  )
}

export default function News() {
  const dispatch = useAppDispatch()

  const news = useGetNewsQuery()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "News",
        backButton: false,
        buttons: [
          {
            title: "New News",
            icon: "add",
            functionName: "redirect",
            data: "/news/new",
          },
        ],
      }),
    )
  }, [dispatch])

  return news.isLoading ? (
    <div className="loader-container news-loader">
      <span className="loader" />
    </div>
  ) : (
    <div className="news-container">
      {news.data?.length === 0 ? (
        <div>No Newses have been added yet.</div>
      ) : (
        news.data?.map((news) => <NewsCard key={news.id} newsLocal={news} />)
      )}
    </div>
  )
}
