import { MatchTypes } from "../../../app/types"

export default function MatchLogo({ matchType }: { matchType: MatchTypes }) {
  return (
    <div className="material-symbols-rounded">
      {matchType === "cricket"
        ? "sports_cricket"
        : matchType === "badminton" || matchType === "tennis"
        ? "sports_tennis"
        : matchType === "basketball"
        ? "sports_basketball"
        : matchType === "football"
        ? "sports_soccer"
        : matchType === "hockey"
        ? "sports_hockey"
        : matchType === "rowing"
        ? "surfing"
        : matchType === "rugby"
        ? "sports_rugby"
        : matchType === "sailing"
        ? "sailing"
        : matchType === "waterpolo"
        ? "pool"
        : ""}
    </div>
  )
}
