import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import { MatchTypes } from "../../../app/types"
import { toaster } from "../../../app/toaster"
import { useAppDispatch } from "../../../app/hooks"

import { useAddMatchMutation } from "../../../features/firebase/subSlices/matches/matchesSlice"
import { useGetSchoolsQuery } from "../../../features/firebase/subSlices/schoolsSlice"
import { changeHeader } from "../../../features/client/pageHeader"

import "./NewMatch.css"

import NewItemPageContainer from "../../../components/NewItemPageContainer/NewItemPageContainer"
import InputField from "../../../components/InputField/InputField"
import Toggle from "../../../components/Toggle/Toggle"
import DropArea from "../../../components/DropArea/DropArea"
import DropDown from "../../../components/DropDown/DropDown"
import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import MatchCard from "../../../components/MatchCard/MatchCard"

export default function NewMatch() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [addMatch] = useAddMatchMutation()
  const schools = useGetSchoolsQuery()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Add Match",
      }),
    )
  }, [dispatch])

  const [active, setActive] = useState(true)
  const [title, setTitle] = useState("")
  const [subText, setSubText] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")

  const [locationDisplayName, setLocationDisplayName] = useState("")
  const [locationSearchName, setLocationSearchName] = useState("")
  const [locationLat, setLocationLat] = useState("")
  const [locationLng, setLocationLng] = useState("")

  const [liveStreamActive, setLiveStreamActive] = useState(false)
  const [liveStreamYoutubeID, setLiveStreamYoutubeID] = useState("")
  const [liveStreamDescription, setLiveStreamDescription] = useState("")

  const [homeSchool, setHomeSchool] = useState("rc")
  const [guestSchool, setGuestSchool] = useState("rc")
  const [matchType, setMatchType] = useState<MatchTypes>("cricket")

  const [loading, setLoading] = useState(false)

  return (
    <NewItemPageContainer className="add-match-page" title="Add a New Match">
      <div className="input-container">
        <div className="info">
          <span className="material-symbols-rounded">info</span>
          <div>
            You cannot change the Match Type and Home and Guest Schools after
            adding the match. You cannot also delete a match once it is added.
            Please ensure that all above details are correct before adding the
            match.
          </div>
        </div>
        <div className="title-container">
          <InputField
            title="Title"
            name="title"
            placeholder="Enter Match Title"
            value={title}
            setValue={setTitle}
          />

          <div className="active-c">
            <div>Active</div>
            <Toggle
              active={active}
              setActive={setActive}
              color="rgba(14, 67, 131, 1)"
            />
          </div>
        </div>

        <div className="match-sections">
          <div>
            <InputField
              title="Sub Text"
              name="subText"
              placeholder="Enter Sub Text"
              value={subText}
              setValue={setSubText}
            />

            <div>Match Image</div>

            <DropArea value={file} setValue={setFile} />

            <InputField
              title="Start Time"
              name="startTime"
              type="datetime-local"
              value={startTime}
              setValue={setStartTime}
            />

            <InputField
              title="End Time"
              name="endTime"
              type="datetime-local"
              value={endTime}
              setValue={setEndTime}
            />

            <div className="live-stream-c">
              <div>
                <div className="live-stream">Live Stream</div>

                <Toggle
                  active={liveStreamActive}
                  setActive={setLiveStreamActive}
                  color="#FF0000aa"
                />

                <InputField
                  title="Youtube ID"
                  name="youtube-id"
                  placeholder="Enter Youtube ID"
                  disabled={!liveStreamActive}
                  value={liveStreamYoutubeID}
                  setValue={setLiveStreamYoutubeID}
                />

                <InputField
                  title="Description"
                  name="description"
                  placeholder="Enter Description"
                  disabled={!liveStreamActive}
                  value={liveStreamDescription}
                  setValue={setLiveStreamDescription}
                />
              </div>

              {liveStreamActive && liveStreamYoutubeID !== "" ? (
                <div className="iframe-c">
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${liveStreamYoutubeID}?amp;controls=0`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div>
            <div className="match-type-label">Match Type</div>

            {/* DEV: When adding a new match type, be sure to include it here */}
            <DropDown
              values={[
                { label: "Badminton", value: "badminton" },
                { label: "Basketball", value: "basketball" },
                { label: "Cricket", value: "cricket" },
                { label: "Football", value: "football" },
                { label: "Hockey", value: "hockey" },
                { label: "Rowing", value: "rowing" },
                { label: "Rugby", value: "rugby" },
                { label: "Sailing", value: "sailing" },
                { label: "Tennis", value: "tennis" },
                { label: "Waterpolo", value: "waterpolo" },
              ]}
              value={matchType}
              setValue={setMatchType}
            />

            <InputField
              title="Location Display Name"
              name="location-display-name"
              placeholder="Enter Location Display Name"
              value={locationDisplayName}
              setValue={setLocationDisplayName}
            />

            <InputField
              title="Location Search Name"
              name="location-search-name"
              placeholder="Enter Location Search Name"
              value={locationSearchName}
              setValue={setLocationSearchName}
            />

            <InputField
              title="Location Latitude"
              name="location-lat"
              placeholder="Enter Location Latitude"
              type="number"
              value={locationLat}
              setValue={setLocationLat}
            />

            <InputField
              title="Location Longitude"
              name="location-lng"
              placeholder="Enter Location Longitude"
              type="number"
              value={locationLng}
              setValue={setLocationLng}
            />

            <div className="schools-container">
              <div className="label">Home School</div>

              {schools.isLoading && !schools.data ? (
                <span className="loader" />
              ) : (
                <DropDown
                  values={
                    schools.data?.map((school) => ({
                      label: school.name,
                      value: school.id,
                    })) ?? []
                  }
                  value={homeSchool}
                  setValue={setHomeSchool}
                />
              )}

              <div className="label">Guest School</div>

              {schools.isLoading && !schools.data ? (
                <span className="loader" />
              ) : (
                <DropDown
                  values={
                    schools.data?.map((school) => ({
                      label: school.name,
                      value: school.id,
                    })) ?? []
                  }
                  value={guestSchool}
                  setValue={setGuestSchool}
                />
              )}

              <MatchCard
                active={active}
                title={title ? title : "Example Title"}
                subText={subText ? subText : "Example Sub Text"}
                image={file ? URL.createObjectURL(file) : ""}
                startTime={startTime ? new Date(startTime) : null}
                location={{
                  displayName: locationDisplayName
                    ? locationDisplayName
                    : "Example Location",
                  searchName: locationSearchName,
                  latitude: locationLat ? parseFloat(locationLat) : 0,
                  longitude: locationLng ? parseFloat(locationLng) : 0,
                }}
                infoChip={{
                  active: false,
                }}
                matchStatus="upcoming"
                homeSchool={homeSchool}
                guestSchool={guestSchool}
                matchType={matchType}
              />
            </div>
          </div>
        </div>

        <div className="btn-container">
          <DefaultButton
            onClick={() => {
              navigate("/matches")
            }}
          >
            <span className="material-symbols-rounded">delete</span>
            <span className="text">Discard</span>
          </DefaultButton>

          <DefaultButton
            color="rgba(14, 67, 131, 0.7)"
            hoverColor="rgba(14, 67, 131, 0.5)"
            onClick={async () => {
              if (!loading) {
                if (title === "") {
                  toaster.error(
                    "Title is missing",
                    "Title is missing. Enter a title and retry.",
                  )
                  return { error: "Title is missing" }
                }

                if (subText === "") {
                  toaster.error(
                    "Sub Text is missing",
                    "Sub Text is missing. Enter a sub text and retry.",
                  )
                  return { error: "Sub Text is missing" }
                }

                if (file === null) {
                  toaster.error(
                    "Upload an Image",
                    "Image is missing. Upload an image and retry.",
                  )
                  return { error: "Image is missing" }
                }

                if (startTime === "") {
                  toaster.error(
                    "Start Time is missing",
                    "Start Time is missing. Enter a start time and retry.",
                  )
                  return { error: "Start Time is missing" }
                }

                if (endTime === "") {
                  toaster.error(
                    "End Time is missing",
                    "End Time is missing. Enter an end time and retry.",
                  )
                  return { error: "End Time is missing" }
                }

                if (locationDisplayName === "") {
                  toaster.error(
                    "Location Display Name is missing",
                    "Location Display Name is missing. Enter a location display name and retry.",
                  )
                  return { error: "Location Display Name is missing" }
                }

                if (locationSearchName === "") {
                  toaster.error(
                    "Location Search Name is missing",
                    "Location Search Name is missing. Enter a location search name and retry.",
                  )
                  return { error: "Location Search Name is missing" }
                }

                if (locationLat === "") {
                  toaster.error(
                    "Location Latitude is missing",
                    "Location Latitude is missing. Enter a location latitude and retry.",
                  )
                  return { error: "Location Latitude is missing" }
                }

                if (locationLng === "") {
                  toaster.error(
                    "Location Longitude is missing",
                    "Location Longitude is missing. Enter a location longitude and retry.",
                  )
                  return { error: "Location Longitude is missing" }
                }

                if (liveStreamActive && liveStreamYoutubeID === "") {
                  toaster.error(
                    "Youtube ID is missing",
                    "Youtube ID is missing. Enter a youtube ID and retry.",
                  )
                  return { error: "Youtube ID is missing" }
                }

                if (liveStreamActive && liveStreamDescription === "") {
                  toaster.error(
                    "Live stream Description is missing",
                    "Live stream Description is missing. Enter a description and retry.",
                  )
                  return { error: "Live stream Description is missing" }
                }

                if (homeSchool === guestSchool) {
                  toaster.error(
                    "Home and Guest School are the same",
                    "Home and Guest School are the same. Change one of the schools and retry.",
                  )
                  return { error: "Home and Guest School are the same" }
                }

                if (
                  new Date(startTime).getTime() >= new Date(endTime).getTime()
                ) {
                  toaster.error(
                    "Start Time is after End Time",
                    "Start Time is after End Time. Change the times and retry.",
                  )
                  return { error: "Start Time is after End Time" }
                }

                setLoading(true)
                await addMatch({
                  active,
                  title,
                  subText,
                  image: file,
                  startTime: new Date(startTime),
                  endTime: new Date(endTime),
                  location: {
                    displayName: locationDisplayName,
                    searchName: locationSearchName,
                    latitude: parseFloat(locationLat),
                    longitude: parseFloat(locationLng),
                  },
                  isLiveStreamEnabled: liveStreamActive,
                  liveStreamYoutubeID,
                  liveStreamDescription,
                  homeSchool,
                  guestSchool,
                  matchType,
                })

                navigate("/matches")
              }
            }}
          >
            {loading ? (
              <span className="loader" />
            ) : (
              <>
                <span className="material-symbols-rounded">trophy</span>
                <span className="text">Add New Match</span>
              </>
            )}
          </DefaultButton>
        </div>
      </div>
    </NewItemPageContainer>
  )
}
