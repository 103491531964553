import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { toaster } from "../../../app/toaster"
import { useAppDispatch } from "../../../app/hooks"
import {
  formatTimerTime,
  getFormattedDate,
  toISOStringWithTimezone,
} from "../../../app/functions"
import { CricketMatch, MatchModel } from "../../../app/types"

import { changeHeader } from "../../../features/client/pageHeader"
import { useGetInningsQuery } from "../../../features/firebase/subSlices/matches/cricket/inningsSlice"
import {
  useChangeMatchCurrentInningsMutation,
  useChangeMatchInfoChipMutation,
  useChangeMatchStatusMutation,
  useChangeMatchTimerMutation,
  useGetMatchesQuery,
} from "../../../features/firebase/subSlices/matches/matchesSlice"

import "./MatchDetails.css"

import DefaultButton from "../../../components/Buttons/DefaultButton/DefaultButton"
import DropDown from "../../../components/DropDown/DropDown"
import Toggle from "../../../components/Toggle/Toggle"
import MatchCard from "../../../components/MatchCard/MatchCard"
import InputField from "../../../components/InputField/InputField"
import ColorButton from "../../../components/Buttons/ColorButton/ColorButton"

export default function MatchDetails() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const matches = useGetMatchesQuery()

  const innings =
    params.matchId === undefined ? null : useGetInningsQuery(params.matchId)

  const [changeMatchStatus] = useChangeMatchStatusMutation()
  const [changeMatchInfoChip] = useChangeMatchInfoChipMutation()
  const [changeMatchTimer] = useChangeMatchTimerMutation()
  const [changeMatchCurrentInnings] = useChangeMatchCurrentInningsMutation()

  const [match, setMatch] = useState<MatchModel | null>(null)
  const [matchStatus, setMatchStatus] =
    useState<MatchModel["matchStatus"]>("upcoming")

  const [infoChipActive, setInfoChipActive] = useState(false)
  const [infoChipIcon, setInfoChipIcon] = useState("")
  const [infoChipText, setInfoChipText] = useState("")
  const [infoChipLoading, setInfoChipLoading] = useState(false)

  const [currentInnings, setCurrentInnings] = useState<string>("")

  const [timerActive, setTimerActive] = useState(false)
  const [timerEnd, setTimerEnd] = useState("")
  const [timerLoading, setTimerLoading] = useState(false)

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Match Details",
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (params.matchId) {
      const match = matches.data?.find(
        (match: MatchModel) => match.id === params.matchId,
      )
      if (match) {
        setMatch(match)
        setMatchStatus(match.matchStatus)
        setInfoChipActive(match.infoChip.active)
        setInfoChipIcon(match.infoChip.icon || "")
        setInfoChipText(match.infoChip.text || "")
        setTimerActive(match.timer?.active || false)

        if ("currentInnings" in match) {
          setCurrentInnings(match?.currentInnings || "")
        }

        setTimerEnd(
          match.timer?.targetTime
            ? toISOStringWithTimezone(
                new Date(match.timer.targetTime.seconds * 1000),
              ).slice(0, 19)
            : "",
        )
      }
    }
  }, [matches.data, params.matchId])

  const timerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setInterval(() => {
      const formattedTime = formatTimerTime(timerEnd)

      if (timerRef.current) {
        timerRef.current.innerHTML = `${formattedTime[0]}:${formattedTime[1]}`

        if (formattedTime[0] === "00" && formattedTime[1] === "00") {
          timerRef.current.style.animation = "none"
          timerRef.current.style.color = "#ff6e6e"
        } else if (
          Number(formattedTime[0][0]) === 0 &&
          Number(formattedTime[0][1]) < 5
        ) {
          timerRef.current.style.animation =
            "timer-clock-animation 1s cubic-bezier(0.5, 0, 0, 0.5) infinite"
        } else {
          timerRef.current.style.animation = "none"
          timerRef.current.style.color = "white"
        }
      }

      if (formattedTime[0] === "00" && formattedTime[1] === "00") {
        clearInterval(timer)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [timerEnd])

  return match ? (
    <div className="match-details-page">
      <div>
        <MatchCard
          active={match.active}
          title={match.title}
          subText={match.subText}
          image={match.image}
          startTime={new Date(match.startTime.seconds * 1000)}
          location={match.location}
          infoChip={{
            active: infoChipActive,
            icon: infoChipIcon,
            text: infoChipText,
          }}
          matchStatus={match.matchStatus}
          homeSchool={match.homeSchool}
          guestSchool={match.guestSchool}
          matchType={match.matchType}
          openDetails={false}
        />

        <div className="match-grid">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${match.location.searchName.replaceAll(
              " ",
              "+",
            )}`}
          >
            <div className="map-card">
              <h3>{match.location.displayName}</h3>
              <p>
                {match.location.latitude}, {match.location.longitude}
              </p>

              <p>
                {getFormattedDate(
                  new Date(match.startTime.seconds * 1000),
                  true,
                  false,
                )}
              </p>

              <div
                className="bg"
                style={{
                  backgroundImage: `url(${match.location.mapImage})`,
                }}
              />

              <div className="gradient" />
            </div>
          </a>

          <div className="live-stream-c">
            {match.isLiveStreamEnabled ? (
              <>
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${match.liveStreamYoutubeID}?amp;controls=0`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <div className="gradient">
                  <p>{match.liveStreamDescription}</p>
                </div>
              </>
            ) : (
              <div className="live-stream-disabled card-bg">
                <div className="material-symbols-rounded">
                  stop_screen_share
                </div>
                <div>Live Stream Disabled</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="btn-co">
        <DropDown
          values={[
            { label: "Upcoming", value: "upcoming" },
            { label: "Live", value: "live" },
            { label: "Home Won", value: "homeWon" },
            { label: "Guest Won", value: "guestWon" },
            { label: "Draw", value: "draw" },
            { label: "Stumps", value: "stumps" },
          ]}
          value={matchStatus}
          setValue={setMatchStatus}
          onChange={(value) => {
            // DEV: Make sure to add checks on the match type
            if (value !== "upcoming") {
              if (match.matchType === "cricket" && "currentInnings" in match) {
                if (!match.currentInnings) {
                  toaster.warning(
                    `You can't set the match status to ${value}`,
                    "Make sure to set the current innings and try again.",
                  )
                  setMatchStatus("upcoming")
                } else {
                  changeMatchStatus({
                    id: match.id,
                    matchStatus: value as CricketMatch["matchStatus"],
                    currentInnings: "first",
                  })
                }
              } else {
                changeMatchStatus({
                  id: match.id,
                  matchStatus: value as CricketMatch["matchStatus"],
                })
              }
            } else {
              changeMatchStatus({
                id: match.id,
                matchStatus: value as CricketMatch["matchStatus"],
              })
            }
          }}
        />

        <div className="info-chip-c card-bg">
          <h3>Match Info Chip</h3>

          <div className="toggle-row">
            <div>Active</div>
            <Toggle
              color="#4768dd"
              active={infoChipActive}
              setActive={setInfoChipActive}
            />
          </div>

          <InputField
            title="Info Chip Icon Codepoint"
            name="info-chip-icon"
            placeholder="Enter Material Symbols Icon Codepoint"
            value={infoChipIcon}
            setValue={setInfoChipIcon}
            disabled={!infoChipActive}
          />

          <InputField
            title="Info Chip Text"
            name="info-chip-text"
            placeholder="Enter Text"
            value={infoChipText}
            setValue={setInfoChipText}
            disabled={!infoChipActive}
          />

          <ColorButton
            color="#4768dd"
            hoverColor="#4768ddaa"
            onClick={async () => {
              setInfoChipLoading(true)

              await changeMatchInfoChip({
                id: match.id,
                infoChip: {
                  active: infoChipActive,
                  icon: infoChipIcon,
                  text: infoChipText,
                },
              })

              setInfoChipLoading(false)
            }}
          >
            {infoChipLoading ? <span className="loader" /> : " Update Chip"}
          </ColorButton>
        </div>

        {/* DEV: Make sure to add match specific fields */}

        {match.matchType === "cricket" && (
          <div className="info-chip-c card-bg">
            <h3>Current Match Innings</h3>

            {innings?.isLoading ? (
              <span className="loader" />
            ) : innings?.data && innings.data.length > 0 ? (
              <>
                {" "}
                <DropDown
                  placeHolder="Select Innings"
                  values={
                    innings?.data
                      ? innings.data.map((innings) => ({
                          label: `${innings.name} (${innings.index})`,
                          value: innings.id,
                        }))
                      : []
                  }
                  value={currentInnings}
                  setValue={setCurrentInnings}
                  onChange={(value) => {
                    setCurrentInnings(value)
                  }}
                />
                <ColorButton
                  color="#4768dd"
                  hoverColor="#4768ddaa"
                  onClick={() => {
                    changeMatchCurrentInnings({
                      id: match.id,
                      currentInnings,
                    })
                  }}
                >
                  Update Current Innings
                </ColorButton>
              </>
            ) : (
              <div className="place-h">
                Add a inning to update the Current Innings
              </div>
            )}
          </div>
        )}

        {match.matchType === "rugby" && (
          <div className="info-chip-c card-bg">
            <h3>Match Timer</h3>

            <div className="toggle-row">
              <div>Active</div>
              <Toggle
                color="#4768dd"
                active={timerActive}
                setActive={setTimerActive}
              />
            </div>

            {timerActive && <div className="timer-clock" ref={timerRef} />}

            <InputField
              title="End Time"
              name="end-time"
              placeholder="Enter End Time"
              type="datetime-local"
              step="1"
              value={timerEnd}
              setValue={setTimerEnd}
              disabled={!timerActive}
            />

            <ColorButton
              color="#4768dd"
              hoverColor="#4768ddaa"
              onClick={async () => {
                setTimerLoading(true)

                await changeMatchTimer({
                  id: match.id,
                  timer: {
                    active: timerActive,
                    endTime: new Date(timerEnd).getTime(),
                  },
                })

                setTimerLoading(false)
              }}
            >
              {timerLoading ? <span className="loader" /> : " Update Timer"}
            </ColorButton>
          </div>
        )}

        <DefaultButton
          onClick={() => {
            navigate(`/matches/${match.id}/edit`)
          }}
        >
          <div className="btn-c">
            <span className="material-symbols-rounded">edit</span>
            <span className="text">Edit Match Details</span>
          </div>
        </DefaultButton>

        <DefaultButton
          onClick={() => {
            navigate(`/matches/${match.id}/players`)
          }}
        >
          <div className="btn-c">
            <span className="material-symbols-rounded">sprint</span>
            <span className="text">Match Players</span>
          </div>
        </DefaultButton>

        {match.matchType === "cricket" && (
          <DefaultButton
            onClick={() => {
              navigate(`/matches/${match.id}/innings`)
            }}
          >
            <div className="btn-c">
              <span className="material-symbols-rounded">sports_cricket</span>
              <span className="text">Match Innings</span>
            </div>
          </DefaultButton>
        )}

        {match.matchType === "rugby" && (
          <DefaultButton
            onClick={() => {
              navigate(`/matches/${match.id}/dashboard`)
            }}
          >
            <div className="btn-c">
              <span className="material-symbols-rounded">sports_rugby</span>
              <span className="text">Match Dashboard</span>
            </div>
          </DefaultButton>
        )}

        <DefaultButton
          onClick={() => {
            navigate(`/matches/${match.id}/commentary`)
          }}
        >
          <div className="btn-c">
            <span className="material-symbols-rounded">comment</span>
            <span className="text">Match Commentary</span>
          </div>
        </DefaultButton>
      </div>
    </div>
  ) : (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  )
}
