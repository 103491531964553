import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore"

import { db } from "../../../../../app/firebase"
import { toaster } from "../../../../../app/toaster"
import { CricketMatchEvents } from "../../../../../app/types"
import { convertTimestampToSerializable } from "../../../../../app/functions"

import { firebaseSlice } from "../../../firebaseSlice"

export const extendedCricketMatchEventSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<CricketMatchEvents, string>({
      queryFn: async (args) => {
        try {
          const querySnapshotHome = await getDocs(
            query(
              collection(db, "matches-prod", args, "events"),
              orderBy("time", "desc"),
              limit(100),
            ),
          )

          const events: CricketMatchEvents = []

          querySnapshotHome.forEach((doc) => {
            const data = doc.data()

            if (data.isCommentary === undefined) {
              toaster.error("Firestore Error", "Event isCommentary is missing")
              return {
                error: `Event isCommentary is missing document ${doc.id}`,
              }
            }

            events.push({
              id: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as CricketMatchEvents[0])
          })

          return { data: events }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "CricketMatchEvents" as const,
                id: arg + id,
              })),
              "CricketMatchEvents",
            ]
          : ["CricketMatchEvents"],

      // async onCacheEntryAdded(
      //   arg,
      //   { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      // ) {
      //   try {
      //     await cacheDataLoaded

      //     onSnapshot(
      //       query(
      //         collection(db, "matches-prod", arg, "events"),
      //         orderBy("time", "desc"),
      //       ),
      //       (querySnapshot) => {
      //         const events: CricketMatchEvents = []

      //         querySnapshot.forEach((doc) => {
      //           const data = doc.data()

      //           if (data.isCommentary === undefined) {
      //             toaster.error(
      //               "Firestore Error",
      //               "Event isCommentary is missing",
      //             )
      //             return {
      //               error: `Event isCommentary is missing document ${doc.id}`,
      //             }
      //           }

      //           events.push({
      //             id: doc.id,
      //             ...data,
      //             time: convertTimestampToSerializable(data.time),
      //           } as CricketMatchEvents[0])
      //         })

      //         updateCachedData((draft) => {
      //           Object.assign(draft, events)
      //         })
      //       },
      //     )
      //   } catch (error: any) {
      //     toaster.error(
      //       "Firestore Error",
      //       "Failed to listen to cricket match events",
      //     )
      //   }

      //   await cacheEntryRemoved
      // },
    }),

    addBallEvent: builder.mutation({
      async queryFn({
        matchId,
        innings,
        homeBatting,
        ball,
        runs,
        description,
        relatedDataChanges,
        time = null,
        separateID = null,
      }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!innings) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          if (runs === undefined || runs === null) {
            toaster.error("Check all fields", "Runs is missing")
            return { error: "Runs is missing" }
          }

          if (description === undefined || description === null) {
            toaster.error("Check all fields", "Description is missing")
            return { error: "Description is missing" }
          }

          if (relatedDataChanges === undefined || relatedDataChanges === null) {
            toaster.error("Check all fields", "RelatedDataChanges is missing")
            return { error: "RelatedDataChanges is missing" }
          }

          const docRef = await setDoc(
            doc(
              db,
              "matches-prod",
              matchId,
              "events",
              `${innings}-${homeBatting ? "home" : "guest"}-${
                separateID ? separateID : ball
              }`,
            ),
            {
              isCommentary: false,
              innings,
              homeBatting,
              runs,
              ball: Number(ball),
              description,
              relatedDataChanges,
              time: time
                ? Timestamp.fromDate(new Date(time * 1000))
                : serverTimestamp(),
            },
          )

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add ball event")
          console.error(error)
          return { error: error.message }
        }
      },
      // invalidatesTags: ["CricketMatchEvents"],
    }),

    addRugbyEvent: builder.mutation({
      async queryFn({ matchId, eventType, isHome, playerId }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          const updateObject: any = {
            isCommentary: false,
            type: eventType,
            isHome,
          }

          if (playerId !== undefined && playerId !== null) {
            updateObject["playerId"] = playerId
          }

          await addDoc(collection(db, "matches-prod", matchId, "events"), {
            ...updateObject,
            time: serverTimestamp(),
          })

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add rugby event")
          console.error(error)
          return { error: error.message }
        }
      },
      // invalidatesTags: ["CricketMatchEvents"],
    }),

    addCommentaryEvent: builder.mutation({
      async queryFn({ matchId, comment }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (comment === undefined || comment === null) {
            toaster.error("Check all fields", "Comment is missing")
            return { error: "Comment is missing" }
          } else if (comment === "") {
            toaster.error("Comment is empty", "Enter a comment to add")
            return { error: "Comment is empty" }
          }

          await addDoc(collection(db, "matches-prod", matchId, "events"), {
            isCommentary: true,
            comment,
            time: serverTimestamp(),
          })

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to add commentary event")
          console.error(error)
          return { error: error.message }
        }
      },
      // invalidatesTags: ["CricketMatchEvents"],
    }),

    editCommentaryEvent: builder.mutation({
      async queryFn({ matchId, eventId, comment, ballComment = false }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!eventId) {
            toaster.error("Check all fields", "Event id is missing")
            return { error: "Event id is missing" }
          }

          if (comment === undefined || comment === null) {
            toaster.error("Check all fields", "Comment is missing")
            return { error: "Comment is missing" }
          } else if (comment === "" && !ballComment) {
            toaster.error("Comment is empty", "Enter a comment to add")
            return { error: "Comment is empty" }
          }

          await updateDoc(doc(db, "matches-prod", matchId, "events", eventId), {
            comment,
          })

          toaster.success("Commentary Updated", "Commentary has been updated")

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to edit commentary event")
          console.error(error)
          return { error: error.message }
        }
      },
      // invalidatesTags: (result, error, arg) => [
      //   { type: "CricketMatchEvents", id: arg.matchId + arg.eventId },
      // ],
    }),

    deleteCommentaryEvent: builder.mutation({
      async queryFn({ matchId, eventId }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!eventId) {
            toaster.error("Check all fields", "Event id is missing")
            return { error: "Event id is missing" }
          }

          await deleteDoc(doc(db, "matches-prod", matchId, "events", eventId))

          toaster.success("Event Deleted", "Match event deleted")

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to delete match event")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["CricketMatchEvents"],
    }),

    refetchEvents: builder.mutation({
      async queryFn({ matchId }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to delete match event")
          console.error(error)
          return { error: error.message }
        }
      },
      invalidatesTags: ["CricketMatchEvents"],
    }),
  }),
})

export const {
  useGetEventsQuery,
  useAddBallEventMutation,
  useAddRugbyEventMutation,
  useAddCommentaryEventMutation,
  useEditCommentaryEventMutation,
  useDeleteCommentaryEventMutation,
  useRefetchEventsMutation,
} = extendedCricketMatchEventSlice

export const selectPlayers =
  extendedCricketMatchEventSlice.endpoints.getEvents.select
