import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"

import { firebaseSlice } from "../features/firebase/firebaseSlice"
import userSlice from "../features/userSlice"
import sidePanelSlice from "../features/client/sidePanelSlice"
import popupSlice from "../features/client/popupSlice"
import toastSlice from "../features/client/toastSlice"
import pageHeaderSlice from "../features/client/pageHeader"
import userListLoadSlice from "../features/client/userListLoad"

export const store = configureStore({
  reducer: {
    [firebaseSlice.reducerPath]: firebaseSlice.reducer,
    user: userSlice,
    sidePanel: sidePanelSlice,
    popup: popupSlice,
    toast: toastSlice,
    pageHeader: pageHeaderSlice,
    userListLoad: userListLoadSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(firebaseSlice.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
