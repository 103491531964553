import { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useAppDispatch } from "../../../../../../app/hooks"
import { MatchInnings } from "../../../../../../app/types"

import { changeHeader } from "../../../../../../features/client/pageHeader"
import { useGetScoresQuery } from "../../../../../../features/firebase/subSlices/matches/cricket/scoresSlice"
import { useGetPlayersQuery } from "../../../../../../features/firebase/subSlices/matches/playersSlice"
import { useGetInningsQuery } from "../../../../../../features/firebase/subSlices/matches/cricket/inningsSlice"

import "./InningsScoreTable.css"

function ScoreTableIndividual({
  home,
  batting,
  matchId,
  inningsId,
  children,
}: {
  home: boolean
  batting: boolean
  matchId?: string
  inningsId?: string
  children?: ReactNode
}) {
  const matchPlayers =
    matchId !== undefined ? useGetPlayersQuery(matchId) : null

  const innings = matchId === undefined ? null : useGetInningsQuery(matchId)

  const scores =
    matchId === undefined || inningsId === undefined
      ? null
      : useGetScoresQuery({
          matchId: matchId,
          inningsId: inningsId,
        })

  const [currentInnings, setCurrentInnings] = useState<MatchInnings[0] | null>(
    null,
  )

  useEffect(() => {
    if (innings?.data) {
      setCurrentInnings(
        innings.data.find(({ id }) => id === inningsId) as MatchInnings[0],
      )
    }
  }, [innings?.data, inningsId])

  return (
    <div className="score-card-d">
      <div>
        <h3>
          {home ? "Home" : "Guest"} {batting ? "Batting" : "Bowling"} Scores
        </h3>
        <h4 className="innings-name">{currentInnings?.name}</h4>
      </div>

      {children}

      <div className="table-data">
        {scores?.isLoading || matchPlayers?.isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Player</th>
                {batting ? (
                  <>
                    <th>R</th>
                    <th>B</th>
                  </>
                ) : (
                  <>
                    <th>R</th>
                    <th>W</th>
                    <th>O</th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {scores?.data && batting
                ? scores.data[home ? "home" : "guest"].batting.map((player) => {
                    const playerDetails =
                      matchPlayers?.data &&
                      matchPlayers?.data[home ? "home" : "guest"].find(
                        ({ id }) => id === player.playerId,
                      )

                    return (
                      <tr key={player.playerId}>
                        <td>
                          <div className="td-d-c">
                            <div className="td-d-t">
                              {playerDetails?.firstName[0].toUpperCase()}{" "}
                              {playerDetails?.lastName}
                            </div>

                            <div className="td-d-s">{player.status}</div>
                          </div>
                        </td>

                        <td>{player.runs}</td>

                        <td>{player.balls}</td>
                      </tr>
                    )
                  })
                : scores?.data &&
                  scores.data[home ? "home" : "guest"].bowling.map((player) => {
                    const playerDetails =
                      matchPlayers?.data &&
                      matchPlayers?.data[home ? "home" : "guest"].find(
                        ({ id }) => id === player.playerId,
                      )

                    return (
                      <tr key={player.playerId}>
                        <td>
                          <div className="td-d-c">
                            <div className="td-d-t">
                              {playerDetails?.firstName[0].toUpperCase()}{" "}
                              {playerDetails?.lastName}
                            </div>
                          </div>
                        </td>

                        <td>{player.runs}</td>
                        <td>{player.wickets}</td>
                        <td>{player.overs}</td>
                      </tr>
                    )
                  })}
            </tbody>
          </table>
        )}

        {batting && (
          <div className="extras-row">
            <div>Extras</div>

            <div className="extra-scores">
              <div className="extras-d">
                ( nb{" "}
                {(currentInnings &&
                  currentInnings[
                    home ? "homeScores" : "guestScores"
                  ].extras.noBalls.toFixed()) ||
                  ""}
                , w{" "}
                {(currentInnings &&
                  currentInnings[
                    home ? "homeScores" : "guestScores"
                  ].extras.wide.toFixed()) ||
                  ""}
                , lb{" "}
                {(currentInnings &&
                  currentInnings[
                    home ? "homeScores" : "guestScores"
                  ].extras.legBye.toFixed()) ||
                  ""}
                , b{" "}
                {(currentInnings &&
                  currentInnings[
                    home ? "homeScores" : "guestScores"
                  ].extras.bye.toFixed()) ||
                  ""}
                )
              </div>

              <div>
                {currentInnings &&
                  (
                    currentInnings[home ? "homeScores" : "guestScores"].extras
                      .noBalls +
                    currentInnings[home ? "homeScores" : "guestScores"].extras
                      .wide +
                    currentInnings[home ? "homeScores" : "guestScores"].extras
                      .legBye +
                    currentInnings[home ? "homeScores" : "guestScores"].extras
                      .bye
                  ).toFixed()}
              </div>
            </div>
          </div>
        )}

        {batting && (
          <>
            <hr />

            <div className="total-row">
              <div>Total</div>

              <div className="total-scores">
                {currentInnings &&
                  currentInnings[home ? "homeScores" : "guestScores"].runs}{" "}
                /{" "}
                {currentInnings &&
                  currentInnings[home ? "homeScores" : "guestScores"]
                    .wickets}{" "}
                (
                {currentInnings &&
                  currentInnings[home ? "homeScores" : "guestScores"].overs}
                )
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default function InningsScoreTable() {
  const dispatch = useAppDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(
      changeHeader({
        title: "Score Tables",
      }),
    )
  }, [dispatch])

  return (
    <div className="score-tables-page">
      <h2>Home Scores</h2>

      <div className="score-table-c">
        <div className="card-bg">
          <ScoreTableIndividual
            home={true}
            batting={true}
            matchId={params.matchId}
            inningsId={params.inningsId}
          />
        </div>

        <div className="card-bg">
          <ScoreTableIndividual
            home={true}
            batting={false}
            matchId={params.matchId}
            inningsId={params.inningsId}
          />
        </div>
      </div>

      <h2>Guest Scores</h2>

      <div className="score-table-c">
        <div className="card-bg">
          <ScoreTableIndividual
            home={false}
            batting={true}
            matchId={params.matchId}
            inningsId={params.inningsId}
          />
        </div>

        <div className="card-bg">
          <ScoreTableIndividual
            home={false}
            batting={false}
            matchId={params.matchId}
            inningsId={params.inningsId}
          />
        </div>
      </div>
    </div>
  )
}

export { ScoreTableIndividual }
