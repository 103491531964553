import { useAppDispatch } from "../../../app/hooks"

import { setOpen } from "../../../features/client/sidePanelSlice"

import "./MenuButton.css"

export default function MenuButton() {
  const dispatch = useAppDispatch()

  return (
    <button
      className="material-symbols-rounded menu-btn"
      onClick={() => {
        dispatch(setOpen(true))
      }}
    >
      menu
    </button>
  )
}
