import { CSSProperties, ReactNode } from "react"

import "./DefaultButton.css"

export default function DefaultButton({
  color,
  hoverColor,
  children,
  disabled = false,
  disableButton = true,
  onClick,
}: {
  color?: string
  hoverColor?: string
  children: ReactNode
  disabled?: boolean
  disableButton?: boolean
  onClick: () => void
}) {
  return (
    <button
      style={
        {
          "--color": color,
          "--hover-color": hoverColor,
          filter: disabled ? "grayscale(1)" : "none",
          opacity: disabled ? 0.3 : 1,
        } as CSSProperties
      }
      className="default-btn"
      onClick={() => {
        onClick()
      }}
      disabled={disabled && disableButton}
    >
      {children}
    </button>
  )
}
