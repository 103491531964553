import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useAppDispatch } from "../../app/hooks"
import { Schools, Settings } from "../../app/types"

import { selectUser } from "../../features/userSlice"
import { changeHeader } from "../../features/client/pageHeader"
import { useGetSchoolsQuery } from "../../features/firebase/subSlices/schoolsSlice"
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../features/firebase/subSlices/settingsSlice"

import "./Dashboard.css"

import firebaseLogo from "../../assets/Dashboard/firebase.svg"
import analyticsLogo from "../../assets/Dashboard/analytics.svg"
import githubLogo from "../../assets/Dashboard/github.svg"

import DropDown from "../../components/DropDown/DropDown"
import DisableEditorsCard from "../../components/DisableEditorsCard/DisableEditorsCard"
import ColorButton from "../../components/Buttons/ColorButton/ColorButton"

function MaintenanceCard() {
  const user = useSelector(selectUser)

  const settings = useGetSettingsQuery()
  const [setSettings] = useUpdateSettingsMutation()

  const [maintenanceMode, setMaintenanceMode] = useState<
    null | Settings["maintenanceMode"]
  >(null)

  useEffect(() => {
    if (!settings.isLoading && settings.data !== undefined) {
      setMaintenanceMode(settings.data.maintenanceMode)
    }
  }, [settings])

  return user.admin ? (
    <div className={"card-bg maintenance-card"}>
      <h3>Maintenance Mode</h3>
      <p>
        Show a pop-up on the app saying the app is currently under maintenance.
      </p>

      {settings.isLoading ? (
        <div className="loader-container">
          <span className="loader" />
        </div>
      ) : (
        <DropDown
          values={[
            {
              label: "Disabled",
              value: "none",
            },
            {
              label: "Temporary Popup",
              value: "temp",
            },
            {
              label: "Static Popup",
              value: "static",
            },
          ]}
          value={maintenanceMode}
          setValue={setMaintenanceMode}
          onChange={(value) => {
            if (value !== null)
              setSettings({ field: "maintenanceMode", data: value })
          }}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

function ProjectLinkCards() {
  return (
    <div className="small-card-container">
      <a href="https://console.firebase.google.com/u/0/project/r-c-sports">
        <div className="card-bg small-card-link">
          <img src={firebaseLogo} alt="" />
          <h4>Firebase</h4>
        </div>
      </a>

      {/* TODO: ADD LINK HERE */}
      <a href="https://analytics.google.com">
        <div className="card-bg small-card-link">
          <img src={analyticsLogo} alt="" />
          <h4>Analytics</h4>
        </div>
      </a>
    </div>
  )
}

function EditorCard() {
  const user = useSelector(selectUser)

  return user.admin ? <DisableEditorsCard /> : <></>
}

function CodebaseCard() {
  return (
    <div className="card-bg">
      <h3>Codebase</h3>

      <div className="codebase-container">
        <a href="https://github.com/Developers-RCCS/rcsa-docs">
          <div className="codebase-link">
            <img src={githubLogo} alt="" />
            <h4>Docs</h4>
          </div>
        </a>

        <a href="https://github.com/Developers-RCCS/rcsa-mobile">
          <div className="codebase-link">
            <img src={githubLogo} alt="" />
            <h4>Mobile</h4>
          </div>
        </a>

        <a href="https://github.com/Developers-RCCS/rcsa-web">
          <div className="codebase-link">
            <img src={githubLogo} alt="" />
            <h4>Web</h4>
          </div>
        </a>

        <a href="https://github.com/Developers-RCCS/rcsa-admin">
          <div className="codebase-link">
            <img src={githubLogo} alt="" />
            <h4>Admin</h4>
          </div>
        </a>
      </div>
    </div>
  )
}

function BackupCard() {
  return (
    <div className="card-bg backup-card">
      <h3>Database Backup</h3>
      <p>
        Backups of the database (default) are taken every week on Monday via
        Google Cloud. Make sure to list out the backups before every match using
        the Google Cloud Database Exports and storage exports
      </p>

      {/* <div className="code-block">
        <span className="material-symbols-rounded">attach_money</span>
        <code>
          gcloud alpha firestore backups list \
          <br />
          --format="table(name, database, state)"
        </code>
        <button
          className="copy-btn"
          onClick={() => {
            navigator.clipboard.writeText(
              `gcloud alpha firestore backups list \\\n--format="table(name, database, state)"`,
            )
          }}
        >
          <span className="material-symbols-rounded">content_copy</span>
        </button>
      </div> */}

      <a href="https://console.cloud.google.com/firestore/databases/-default-/import-export?authuser=0&hl=en&project=r-c-sports">
        Go to Google Cloud Console
      </a>

      {/* <a href="https://firebase.google.com/docs/firestore/backups">
        <div className="doc-link">See Backups Documentation</div>
      </a> */}
    </div>
  )
}

function AnalyticsCard() {
  return (
    <div className="card-bg">
      <h3>App Analytics</h3>
    </div>
  )
}

function SchoolRow({ school }: { school: Schools[0] }) {
  const navigate = useNavigate()

  return (
    <div className="card-bg">
      <div className="t-c">
        <div
          className="crest"
          style={{ backgroundImage: `url(${school.image})` }}
        />

        <div className="text-container">
          <h3>{school.name}</h3>
          <p>{school.city}</p>
        </div>
      </div>

      <div className="t-c">
        <div
          className="color-pin"
          style={{
            backgroundColor: "#" + school.color,
          }}
        />

        <button
          className="edit-btn"
          onClick={() => {
            navigate(`/edit-school/${school.id}`)
          }}
        >
          <span className="material-symbols-rounded">edit</span>
        </button>
      </div>
    </div>
  )
}

function SchoolsCard() {
  const navigate = useNavigate()

  const schools = useGetSchoolsQuery()

  return (
    <div className="card-bg schools-card">
      <h3>Schools</h3>

      {schools.isLoading ? (
        <div className="loader-container">
          <span className="loader" />
        </div>
      ) : schools.data?.length === 0 ? (
        <>
          <div className="no-schools">No Schools have been added yet.</div>
          <ColorButton
            onClick={() => {
              navigate("/new-school")
            }}
          >
            New School
          </ColorButton>
        </>
      ) : (
        <>
          <div className="schools-container">
            {schools.data?.map((school: Schools[0]) => (
              <SchoolRow key={school.id} school={school} />
            ))}
          </div>

          <ColorButton
            onClick={() => {
              navigate("/new-school")
            }}
          >
            New School
          </ColorButton>
        </>
      )}
    </div>
  )
}

export default function Dashboard() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(changeHeader({ title: "Home", backButton: false }))
  }, [dispatch])

  return (
    <div className="dashboard">
      <div className="grid-col">
        <MaintenanceCard />
        <ProjectLinkCards />

        <div className="show-item-2">
          <CodebaseCard />
          <EditorCard />
          <AnalyticsCard />
          <BackupCard />
        </div>

        <div className="show-item">
          <SchoolsCard />
        </div>
      </div>

      <div className="grid-col hide-col-2">
        <EditorCard />
        <CodebaseCard />
        <BackupCard />

        <div className="show-item">
          <AnalyticsCard />
        </div>
      </div>

      <div className="grid-col hide-col">
        <AnalyticsCard />
        <SchoolsCard />
      </div>
    </div>
  )
}
