import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useAppDispatch } from "../../app/hooks"
import { validateEmail } from "../../app/functions"

import { login, selectUser } from "../../features/userSlice"
import { showPopup } from "../../features/client/popupSlice"

import "./LoginPage.css"

import InputField from "../../components/InputField/InputField"
import MenuButton from "../../components/Buttons/MenuButton/MenuButton"

import RCCS_Logo from "../../assets/RCCS_Logo.webp"

export default function LoginPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useSelector(selectUser)

  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const [loginLoading, setLoginLoading] = useState(false)

  const loginBtnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    switch (user.status) {
      case "success":
        navigate("/dashboard")
        break

      case "logout":
        setError(null)
        break

      case "auth/invalid-email":
        setError("Entered Email is Invalid")
        break

      case "auth/missing-password":
        setError("Enter a Password")
        break

      case "auth/user-not-found":
        setError("Entered Email is Invalid")
        break

      case "auth/wrong-password":
        setError("Wrong Password, Try Again")
        break

      case "auth/invalid-credential":
        setError("Invalid Credentials, Try Again")
        break

      case "auth/user-disabled":
        setError("Your Account is Disabled, Contact Administrator")
        break

      case "auth/too-many-attempts":
        setError("Too Many Attempts, Try Again Later")
        break

      case "auth/too-many-requests":
        setError("Too Many Attempts, Try Again Later")
        break

      case "auth/network-request-failed":
        setError("Check Your Internet Connection")
        break

      case "error":
        setError("An Error Occurred, Try Again Later")
        break

      default:
        setError(null)
        break
    }

    if (user.status !== "loading") {
      setLoginLoading(false)
    }
  }, [user.status, navigate])

  return (
    <div className="login-page">
      <MenuButton />

      <div className="login-page-content">
        <img src={RCCS_Logo} className="rccs-logo" alt="RCCS Logo" />

        <h2>Administrator Login</h2>
        <p className="info-para">
          Login to the admin dashboard with your admin
          <br />
          account credentials
        </p>

        <form
          className="login-form"
          onSubmit={(e) => {
            e.preventDefault()
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              if (loginBtnRef.current) {
                loginBtnRef.current.click()
              }
            }
          }}
        >
          <InputField
            title="Email"
            name="email-login"
            type="email"
            placeholder="Enter Your Email"
            value={email}
            setValue={setEmail}
          />

          <InputField
            title="Password"
            name="password-login"
            type={passwordVisibility ? "text" : "password"}
            placeholder="Enter Your Password"
            icon={passwordVisibility ? "visibility_off" : "visibility"}
            iconOnClick={() => {
              setPasswordVisibility((preVal) => {
                return !preVal
              })
            }}
            value={password}
            setValue={setPassword}
          />

          <p
            className="error-msg"
            style={{
              opacity: error !== null ? 0.75 : 0,
              margin: error !== null ? "0.5rem 0" : "0",
            }}
          >
            {error !== null ? "* " + error : ""}
          </p>

          <button
            ref={loginBtnRef}
            className="login-btn"
            type="submit"
            onClick={() => {
              if (email === "") {
                setError("Enter a Valid Email")
              } else if (!validateEmail(email)) {
                setError("Entered Email is Invalid")
              } else if (password === "") {
                setError("Enter a Password")
              } else {
                setLoginLoading(true)
                dispatch(login({ email, password }))
              }
            }}
          >
            {loginLoading ? <span className="loader" /> : "Login"}
          </button>

          <button
            className="forgot-password-btn"
            onClick={() => {
              dispatch(
                showPopup({
                  title: "Reset Your Password",
                  message:
                    "Enter Your Email to get a link to reset your password.",
                  input: {
                    title: "Email",
                    name: "popup-reset-pass-email",
                    type: "email",
                    placeholder: "Enter Your Email",
                  },
                  buttons: [
                    {
                      title: "Continue",
                      action: "reset-password",
                      loading: false,
                    },
                  ],
                }),
              )
            }}
          >
            Forgot My Password
          </button>
        </form>

        <div className="balance-div" />
      </div>
    </div>
  )
}
