import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore"

import { db } from "../../../../../app/firebase"
import { toaster } from "../../../../../app/toaster"
import { CricketMatchScores } from "../../../../../app/types"
import {
  convertTimestampToSerializable,
  overSafeBall,
} from "../../../../../app/functions"

import { firebaseSlice } from "../../../firebaseSlice"

interface ScoresArgs {
  matchId: string
  inningsId: string
}

export const extendedCricketMatchScoresSlice = firebaseSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScores: builder.query<CricketMatchScores, ScoresArgs>({
      queryFn: async (args) => {
        try {
          const scores: CricketMatchScores = {
            home: {
              batting: [],
              bowling: [],
            },
            guest: {
              batting: [],
              bowling: [],
            },
          }

          const querySnapshotHomeBatting = await getDocs(
            query(
              collection(
                db,
                "matches-prod",
                args.matchId,
                "innings",
                args.inningsId,
                "homeBattingScores",
              ),
              orderBy("time", "desc"),
            ),
          )

          querySnapshotHomeBatting.forEach((doc) => {
            const data = doc.data()

            scores.home.batting.push({
              playerId: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as CricketMatchScores["home"]["batting"][0])
          })

          const querySnapshotHomeBowling = await getDocs(
            query(
              collection(
                db,
                "matches-prod",
                args.matchId,
                "innings",
                args.inningsId,
                "homeBowlingScores",
              ),
              orderBy("time", "desc"),
            ),
          )

          querySnapshotHomeBowling.forEach((doc) => {
            const data = doc.data()

            scores.home.bowling.push({
              playerId: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as CricketMatchScores["home"]["bowling"][0])
          })

          const querySnapshotGuestBatting = await getDocs(
            query(
              collection(
                db,
                "matches-prod",
                args.matchId,
                "innings",
                args.inningsId,
                "guestBattingScores",
              ),
              orderBy("time", "desc"),
            ),
          )

          querySnapshotGuestBatting.forEach((doc) => {
            const data = doc.data()

            scores.guest.batting.push({
              playerId: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as CricketMatchScores["guest"]["batting"][0])
          })

          const querySnapshotGuestBowling = await getDocs(
            query(
              collection(
                db,
                "matches-prod",
                args.matchId,
                "innings",
                args.inningsId,
                "guestBowlingScores",
              ),
              orderBy("time", "desc"),
            ),
          )

          querySnapshotGuestBowling.forEach((doc) => {
            const data = doc.data()

            scores.guest.bowling.push({
              playerId: doc.id,
              ...data,
              time: convertTimestampToSerializable(data.time),
            } as CricketMatchScores["guest"]["bowling"][0])
          })

          return { data: scores }
        } catch (error: any) {
          console.error(error)
          return { error: error.message }
        }
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.home.batting.map(({ playerId }) => ({
                type: "CricketMatchScores" as const,
                id: arg + playerId + "homeBatting",
              })),

              ...result.home.bowling.map(({ playerId }) => ({
                type: "CricketMatchScores" as const,
                id: arg + playerId + "homeBowling",
              })),

              ...result.guest.batting.map(({ playerId }) => ({
                type: "CricketMatchScores" as const,
                id: arg + playerId + "guestBatting",
              })),

              ...result.guest.bowling.map(({ playerId }) => ({
                type: "CricketMatchScores" as const,
                id: arg + playerId + "guestBowling",
              })),
              "CricketMatchScores",
            ]
          : ["CricketMatchScores"],

      // async onCacheEntryAdded(
      //   args,
      //   { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      // ) {
      //   try {
      //     await cacheDataLoaded

      //     onSnapshot(
      //       query(
      //         collection(
      //           db,
      //           "matches-prod",
      //           args.matchId,
      //           "innings",
      //           args.inningsId,
      //           "homeBattingScores",
      //         ),
      //         orderBy("time", "desc"),
      //       ),
      //       (querySnapshot) => {
      //         const battingScores: CricketMatchScores["home"]["batting"] = []

      //         querySnapshot.forEach((doc) => {
      //           const data = doc.data()

      //           battingScores.push({
      //             playerId: doc.id,
      //             ...data,
      //             time: convertTimestampToSerializable(data.time),
      //           } as CricketMatchScores["home"]["batting"][0])
      //         })

      //         updateCachedData((draft) => {
      //           Object.assign(draft.home.batting, battingScores)
      //         })
      //       },
      //     )

      //     onSnapshot(
      //       query(
      //         collection(
      //           db,
      //           "matches-prod",
      //           args.matchId,
      //           "innings",
      //           args.inningsId,
      //           "homeBowlingScores",
      //         ),
      //         orderBy("time", "desc"),
      //       ),
      //       (querySnapshot) => {
      //         const bowlingScores: CricketMatchScores["home"]["bowling"] = []

      //         querySnapshot.forEach((doc) => {
      //           const data = doc.data()

      //           bowlingScores.push({
      //             playerId: doc.id,
      //             ...data,
      //             time: convertTimestampToSerializable(data.time),
      //           } as CricketMatchScores["home"]["bowling"][0])
      //         })

      //         updateCachedData((draft) => {
      //           Object.assign(draft.home.bowling, bowlingScores)
      //         })
      //       },
      //     )

      //     onSnapshot(
      //       query(
      //         collection(
      //           db,
      //           "matches-prod",
      //           args.matchId,
      //           "innings",
      //           args.inningsId,
      //           "guestBattingScores",
      //         ),
      //         orderBy("time", "desc"),
      //       ),
      //       (querySnapshot) => {
      //         const battingScores: CricketMatchScores["guest"]["batting"] = []

      //         querySnapshot.forEach((doc) => {
      //           const data = doc.data()

      //           battingScores.push({
      //             playerId: doc.id,
      //             ...data,
      //             time: convertTimestampToSerializable(data.time),
      //           } as CricketMatchScores["guest"]["batting"][0])
      //         })

      //         updateCachedData((draft) => {
      //           Object.assign(draft.guest.batting, battingScores)
      //         })
      //       },
      //     )

      //     onSnapshot(
      //       query(
      //         collection(
      //           db,
      //           "matches-prod",
      //           args.matchId,
      //           "innings",
      //           args.inningsId,
      //           "guestBowlingScores",
      //         ),
      //         orderBy("time", "desc"),
      //       ),
      //       (querySnapshot) => {
      //         const bowlingScores: CricketMatchScores["guest"]["bowling"] = []

      //         querySnapshot.forEach((doc) => {
      //           const data = doc.data()

      //           bowlingScores.push({
      //             playerId: doc.id,
      //             ...data,
      //             time: convertTimestampToSerializable(data.time),
      //           } as CricketMatchScores["guest"]["bowling"][0])
      //         })

      //         updateCachedData((draft) => {
      //           Object.assign(draft.guest.bowling, bowlingScores)
      //         })
      //       },
      //     )
      //   } catch (error: any) {
      //     toaster.error(
      //       "Firestore Error",
      //       "Failed to listen to home batting scores",
      //     )
      //   }

      //   await cacheEntryRemoved
      // },
    }),

    // addPlayerData: builder.mutation({
    //   async queryFn({
    //     matchId,
    //     inningsId,
    //     playerId,
    //     playerSchool,
    //     homeBatting,
    //     data,
    //   }) {
    //     try {
    //       if (!matchId) {
    //         toaster.error("Check all fields", "Match id is missing")
    //         return { error: "Match id is missing" }
    //       }

    //       if (!inningsId) {
    //         toaster.error("Check all fields", "Innings id is missing")
    //         return { error: "Innings id is missing" }
    //       }

    //       if (!playerId) {
    //         toaster.error("Check all fields", "Player id is missing")
    //         return { error: "Player id is missing" }
    //       }

    //       if (!playerSchool) {
    //         toaster.error("Check all fields", "Player school is missing")
    //         return { error: "Player school is missing" }
    //       } else if (playerSchool !== "home" && playerSchool !== "guest") {
    //         toaster.error("Check all fields", "Player school is invalid")
    //         return { error: "Player school is invalid" }
    //       }

    //       if (homeBatting === undefined || homeBatting === null) {
    //         toaster.error("Check all fields", "Innings homeBatting is missing")
    //         return { error: "Innings homeBatting is missing" }
    //       }

    //       if (!data) {
    //         toaster.error("Check all fields", "Data is missing")
    //         return { error: "Data is missing" }
    //       } else if (
    //         (playerSchool === "home" && homeBatting) ||
    //         (playerSchool === "guest" && !homeBatting)
    //       ) {
    //         if (data.runs === undefined || data.runs === null) {
    //           toaster.error("Check all fields", "Runs is missing")
    //           return { error: "Runs is missing" }
    //         }

    //         if (data.balls === undefined || data.balls === null) {
    //           toaster.error("Check all fields", "Balls is missing")
    //           return { error: "Balls is missing" }
    //         }

    //         if (data.status === undefined || data.status === null) {
    //           toaster.error("Check all fields", "Status is missing")
    //           return { error: "Status is missing" }
    //         }
    //       } else if (
    //         (playerSchool === "home" && !homeBatting) ||
    //         (playerSchool === "guest" && homeBatting)
    //       ) {
    //         if (data.runs === undefined || data.runs === null) {
    //           toaster.error("Check all fields", "Runs is missing")
    //           return { error: "Runs is missing" }
    //         }

    //         if (data.wickets === undefined || data.wickets === null) {
    //           toaster.error("Check all fields", "Wickets is missing")
    //           return { error: "Wickets is missing" }
    //         }

    //         if (data.overs === undefined || data.overs === null) {
    //           toaster.error("Check all fields", "Overs is missing")
    //           return { error: "Overs is missing" }
    //         }
    //       }

    //       const docRef = await addDoc(
    //         collection(
    //           db,
    //           "matches-prod",
    //           matchId,
    //           "innings",
    //           inningsId,
    //           playerSchool === "home"
    //             ? homeBatting
    //               ? "homeBattingScores"
    //               : "homeBowlingScores"
    //             : homeBatting
    //             ? "guestBowlingScores"
    //             : "guestBattingScores",
    //           playerId,
    //         ),
    //         {
    //           ...data,
    //           time: serverTimestamp(),
    //         },
    //       )

    //       return { data: null }
    //     } catch (error: any) {
    //       toaster.error("Firestore Error", "Failed to add player data")
    //       console.error(error)
    //       return { error: error.message }
    //     }
    //   },
    //   invalidatesTags: ["CricketMatchScores"],
    // }),

    updatePlayerData: builder.mutation({
      async queryFn({
        matchId,
        inningsId,
        playerId,
        playerSchool,
        homeBatting,
        incrementData,
      }) {
        try {
          if (!matchId) {
            toaster.error("Check all fields", "Match id is missing")
            return { error: "Match id is missing" }
          }

          if (!inningsId) {
            toaster.error("Check all fields", "Innings id is missing")
            return { error: "Innings id is missing" }
          }

          if (!playerId) {
            toaster.error("Check all fields", "Player id is missing")
            return { error: "Player id is missing" }
          }

          if (!playerSchool) {
            toaster.error("Check all fields", "Player school is missing")
            return { error: "Player school is missing" }
          } else if (playerSchool !== "home" && playerSchool !== "guest") {
            toaster.error("Check all fields", "Player school is invalid")
            return { error: "Player school is invalid" }
          }

          if (homeBatting === undefined || homeBatting === null) {
            toaster.error("Check all fields", "Innings homeBatting is missing")
            return { error: "Innings homeBatting is missing" }
          }

          if (!incrementData) {
            toaster.error("Check all fields", "Data is missing")
            return { error: "Data is missing" }
          }

          await runTransaction(db, async (transaction) => {
            const docRef = doc(
              db,
              "matches-prod",
              matchId,
              "innings",
              inningsId,
              playerSchool === "home"
                ? homeBatting
                  ? "homeBattingScores"
                  : "homeBowlingScores"
                : homeBatting
                ? "guestBowlingScores"
                : "guestBattingScores",
              playerId,
            )

            const docRes: any = await transaction.get(docRef)
            if (!docRes.exists()) {
              transaction.set(
                docRef,
                (playerSchool === "home" && homeBatting) ||
                  (playerSchool === "guest" && !homeBatting)
                  ? { runs: 0, balls: 0, status: "Yet to Bat" }
                  : { runs: 0, wickets: 0, overs: 0 },
              )
            }

            const docData = docRes.data()

            // console.log(docData)

            const updateData: any = {
              time: serverTimestamp(),
            }

            if (incrementData) {
              for (const key in incrementData) {
                if (key === "runs" && "runs" in incrementData) {
                  updateData.runs =
                    Number(docData?.runs || 0) + Number(incrementData.runs)
                }

                if (key === "balls" && "balls" in incrementData) {
                  updateData.balls =
                    Number(docData?.balls || 0) + Number(incrementData.balls)
                }

                if (key === "wickets" && "wickets" in incrementData) {
                  updateData.wickets =
                    Number(docData?.wickets || 0) +
                    Number(incrementData.wickets)
                }

                if (key === "overs" && "overs" in incrementData) {
                  updateData.overs = Number(
                    overSafeBall(
                      Number(docData?.overs || 0) + Number(incrementData.overs),
                    ),
                  )
                }

                if (key === "status" && "status" in incrementData) {
                  updateData.status = incrementData.status
                }
              }
            }

            transaction.update(docRef, updateData)
          })

          return { data: null }
        } catch (error: any) {
          toaster.error("Firestore Error", "Failed to update player data")
          console.error(error)
          return { error: error.message }
        }
      },
    }),
  }),
})

export const { useGetScoresQuery, useUpdatePlayerDataMutation } =
  extendedCricketMatchScoresSlice

export const selectPlayers =
  extendedCricketMatchScoresSlice.endpoints.getScores.select
