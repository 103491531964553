import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"

export const firebaseSlice = createApi({
  reducerPath: "firebase",
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    "UserList",
    "Settings",
    "Schools",
    "News",
    "Matches",
    "MatchPlayers",
    "MatchInnings",
    "CricketMatchEvents",
    "CricketMatchScores",
  ],
  endpoints: (builder) => ({}),
})
