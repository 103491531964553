import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { PopupButtonActions } from "../../app/types"

interface PopupDataType {
  active: boolean
  title: string
  message: string
  errorMsg?: string
  input?: { title: string; name: string; type: string; placeholder: string }
  inputValue: string
  buttons?: {
    title: string
    action: PopupButtonActions
    data?: string
    loading: boolean
  }[]
}

const initialState: PopupDataType = {
  active: false,
  title: "",
  message: "",
  inputValue: "",
}

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    showPopup: (
      state,
      action: PayloadAction<Omit<PopupDataType, "active" | "inputValue">>,
    ) => {
      Object.assign(state, initialState)
      state.active = true
      state.title = action.payload.title
      state.message = action.payload.message
      state.input = action.payload.input
      state.buttons = action.payload.buttons
    },
    closePopup: (state) => {
      state.active = false
    },
    setInputValue: (state, action: PayloadAction<string>) => {
      state.inputValue = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload
    },
    setButtonLoading: (
      state,
      action: PayloadAction<{ index: number; state: boolean }>,
    ) => {
      state.buttons = state.buttons?.map((button, i) => {
        if (i === action.payload.index) {
          return { ...button, loading: action.payload.state }
        } else return button
      })
    },
  },
})

export const {
  showPopup,
  closePopup,
  setInputValue,
  setErrorMessage,
  setButtonLoading,
} = popupSlice.actions

export default popupSlice.reducer
